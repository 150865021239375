import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import deepmerge from "deepmerge";
import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import { CssBaseline } from "@material-ui/core";

const extendStakeholderFont = createMuiTheme({
  typography: {
    fontFamily: ['"Source Sans 3"', "Ariel", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#212927",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#00708d",
      contrastText: "#FFF",
    },
    tertiary: {
      main: "#8ED3F1",
      contrastText: "#212927",
    },
  },
  spacing: 4,
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Regular-01.otf') format('opentype')`,
  //           fontWeight: 400,
  //           fontStyle: "normal",
  //         },
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Semibold-02.otf') format('opentype')`,
  //           fontWeight: 600,
  //           fontStyle: "normal",
  //         },
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Bold-03.otf') format('opentype')`,
  //           fontWeight: 700,
  //           fontStyle: "normal",
  //         },
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Extrabold-04.otf') format('opentype')`,
  //           fontWeight: 800,
  //           fontStyle: "normal",
  //         },
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Heavy-05.otf') format('opentype')`,
  //           fontWeight: 900,
  //           fontStyle: "normal",
  //         },
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Light-06.otf') format('opentype')`,
  //           fontWeight: 300,
  //           fontStyle: "normal",
  //         },
  //         {
  //           fontFamily: "Adelle Sans",
  //           src: `url('/static/fonts/AdelleSansMultiscript-Thin-07.otf') format('opentype')`,
  //           fontWeight: 100,
  //           fontStyle: "normal",
  //         },
  //       ],
  //     },
  //   },
  // },
});

export const StakeholderThemeProvider = ({ children }) => {
  const baseTheme = useTheme();

  const mergedTheme = deepmerge(baseTheme, extendStakeholderFont);

  return (
    <ThemeProvider theme={mergedTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default StakeholderThemeProvider;
