import React from "react";
import { withTheme } from "styled-components/macro";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import { add } from "date-fns";
import { dateFormatter } from "../../utils";

const Tooltip = styled(MuiTooltip)(spacing);

const ExportDataButton = ({ theme, title, data, filterValues = {} }) => {
  const downloadCsvString = (data) => {
    const header = Object.keys(data[0]).join(",");

    const values = data
      .filter((object) => {
        const collectDate = new Date(object.collect_date);
        if (!filterValues.startDate && !filterValues.endDate) {
          return true;
        }

        const startDate = filterValues.startDate
          ? new Date(filterValues.startDate)
          : new Date(0); // Start from epoch if startDate is null
        const endDate = filterValues.endDate
          ? add(new Date(filterValues.endDate), { months: 1 })
          : new Date();

        return (
          object.collect_date &&
          collectDate >= startDate &&
          collectDate <= endDate
        );
      })
      .map((object) =>
        Object.values(object)
          .map((item) => `"${item}"`)
          .join(",")
      )
      .join("\n");

    const csvString = header + "\n" + values;

    const formattedStartDate = filterValues.startDate
      ? dateFormatter(filterValues.startDate, "MM-dd-yyyy")
      : "beginning";
    const formattedEndDate = filterValues.endDate
      ? dateFormatter(filterValues.endDate, "MM-dd-yyyy")
      : "end";

    const a = document.createElement("a");
    a.href = "data:attachment/csv," + encodeURIComponent(csvString);
    a.target = "_blank";
    a.download = `Time Series Data for ${data[0][title]} between ${formattedStartDate} and ${formattedEndDate}.csv`;
    document.body.appendChild(a);
    a.click();
  };

  return (
    <Tooltip title="Export Data to csv" arrow ml={2}>
      <IconButton
        onClick={() => downloadCsvString(data)}
        style={{
          color:
            theme.palette.type === "dark"
              ? "rgba(255, 255, 255, 0.5)"
              : "rgb(117, 117, 117)",
        }}
        aria-label="download graph"
        component="span"
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default withTheme(ExportDataButton);
