import React, { useCallback, useMemo, useRef, useState } from "react";

const useChartRefs = (numRefs) => {
  const refs = useRef([]);

  // Initialize refs only if they haven't been initialized yet
  if (refs.current.length !== numRefs) {
    refs.current = Array.from(
      { length: numRefs },
      (_, i) => refs.current[i] || React.createRef()
    );
  }

  return refs.current;
};

const useOriginalScaleBounds = (data) => {
  const xAxisGraph = useMemo(() => {
    return data.find((dataset) => dataset.isLastChart);
  }, [data]);

  return useMemo(
    () => ({ x: { min: xAxisGraph.xMin, max: xAxisGraph.xMax } }),
    [xAxisGraph]
  );
};

const useUpdateCharts = (chartRefs) => {
  return useCallback(
    (callback) => {
      chartRefs.forEach((ref) => {
        const chart = ref.current;
        if (chart) callback(chart);
      });
    },
    [chartRefs]
  );
};

const useHoverHandler = (updateCharts) => {
  const previousActiveElements = useRef([]);

  return (event, elements) => {
    if (elements.length) {
      const elementIndex = elements[0].index;
      const newActiveElements = elements.map(({ datasetIndex, index }) => ({
        datasetIndex,
        index,
      }));

      if (
        JSON.stringify(newActiveElements) !==
        JSON.stringify(previousActiveElements.current)
      ) {
        previousActiveElements.current = newActiveElements;

        requestAnimationFrame(() => {
          updateCharts((chart) => {
            const allElements = Array.from(
              { length: chart.data.datasets.length },
              (_, i) => ({
                datasetIndex: i,
                index: elementIndex,
              })
            );
            chart.setActiveElements(allElements);
            chart.tooltip.setActiveElements(allElements, {
              x: chart.data.labels[elementIndex],
            });
            chart.update();
          });
        });
      }
    } else {
      previousActiveElements.current = [];
      updateCharts((chart) => {
        chart.setActiveElements([]);
        chart.tooltip.setActiveElements([], {});
        chart.update();
      });
    }
  };
};

const useZoomHandler = (updateCharts) => {
  return useCallback(
    (start, end) => {
      requestAnimationFrame(() => {
        updateCharts((chart) => {
          chart.options.scales.x.min = start;
          chart.options.scales.x.max = end;
          chart.update();
        });
      });
    },
    [updateCharts]
  );
};

const useResetZoomHandler = (originalScaleBounds, updateCharts) => {
  return useCallback(() => {
    requestAnimationFrame(() => {
      updateCharts((chart) => {
        chart.options.scales.x.min = originalScaleBounds.x.min;
        chart.options.scales.x.max = originalScaleBounds.x.max;
        chart.update();
      });
    });
  }, [originalScaleBounds, updateCharts]);
};

const useChartOptions = (handleZoom, gridSize) => {
  return useCallback(
    (chartData) => ({
      interaction: {
        intersect: false,
        mode: "index",
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          offset: false,
          type: "time",
          display: chartData.isLastChart || gridSize === 6,
          time: {
            unit: "day",
            displayFormats: {
              day: "MMM-D",
            },
            tooltipFormat: "MMM DD YYYY",
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
            maxTicksLimit: 12,
          },
          grid: {
            display: false,
          },
          min: chartData.xMin,
          max: chartData.xMax,
        },
        yL: {
          type: "linear",
          position: "left",
          title: {
            display: true,
            text: chartData?.yLLabel,
          },
          afterFit: function (scale) {
            scale.width = 70;
          },
        },
        yR: {
          type: "linear",
          position: "right",
          title: {
            display: true,
            text: chartData?.yRLabel,
          },
          grid: {
            borderDash: [5, 10],
          },
          afterFit: function (scale) {
            scale.width = 70;
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: chartData?.chartTitle,
          font: {
            size: 24,
          },
          padding: {
            top: 10,
            bottom: 0,
          },
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const value = tooltipItem.raw;
              if (value === null) {
                return `N/A`;
              }
              return `${tooltipItem.dataset.label}: ${value}`;
            },
            title: (tooltipItems) => {
              if (!tooltipItems.length) return "No Data Available";
              const title = tooltipItems[0].label;
              return `Date: ${title}`;
            },
          },
          filter: (tooltipItem) => {
            return tooltipItem.raw !== null;
          },
        },
        zoom: {
          zoom: {
            mode: "x",
            drag: {
              enabled: true,
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
              backgroundColor: "rgba(54, 162, 235, 0.3)",
              drawTime: "afterDatasetsDraw",
            },
            onZoom: ({ chart }) => {
              const xAxis = chart.scales.x;
              handleZoom(xAxis.min, xAxis.max);
            },
          },
        },
      },
    }),
    [handleZoom, gridSize]
  );
};

export const useMultiChart = (data) => {
  const [gridSize, setGridSize] = useState(12);
  const chartRefs = useChartRefs(data?.length || 0);
  const originalScaleBounds = useOriginalScaleBounds(data);
  const updateCharts = useUpdateCharts(chartRefs);
  const handleHover = useHoverHandler(updateCharts);
  const handleZoom = useZoomHandler(updateCharts);
  const resetZoom = useResetZoomHandler(originalScaleBounds, updateCharts);
  const getOptions = useChartOptions(handleZoom, gridSize);

  const toggleGridSize = useCallback(
    () => setGridSize((prevSize) => (prevSize === 12 ? 6 : 12)),
    []
  );

  const calculateHeight = useCallback(
    (isLastChart) => {
      if (isLastChart && gridSize === 12) return "360px";
      return gridSize === 12 ? "300px" : "500px";
    },
    [gridSize]
  );

  return {
    gridSize,
    chartRefs,
    handleHover,
    resetZoom,
    toggleGridSize,
    getOptions,
    calculateHeight,
  };
};
