import React, { useMemo, useRef } from "react";

import { dateFormatter } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Button as MuiButton,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import { useTemperatureProfiles } from "./useTemperatureProfiles";
import {
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  LOCATION_OPTIONS,
  PERIOD_OPTIONS,
  QUICKSET_OPTIONS,
  STATISTIC_OPTIONS,
} from "./constants";
import { ResponsiveHeatMapCanvas } from "@nivo/heatmap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { getContrastYIQ } from "./utils";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  height: calc(100% - 35px);
  width: 100%;
`;

const ChartContainer = styled.div`
  height: calc(80vh);
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const QuickSetButton = ({ option, index, setFilters, filters }) => {
  const isDisabled = option.disableFor.includes(filters.period);
  return (
    <Tooltip title="Quick set dates" disableHoverListener={isDisabled}>
      <span>
        <Button
          onClick={() =>
            setFilters((prevFilters) => ({
              ...prevFilters,
              startDate: option.calculateStartDate(),
              endDate: format(new Date(), "MM/dd/yyyy, h:mm a"),
            }))
          }
          type="submit"
          color="primary"
          variant="contained"
          size="small"
          style={{ marginLeft: index === 0 ? 0 : 8 }}
          disabled={isDisabled}
          aria-label={option.label}
        >
          {option.label}
        </Button>
      </span>
    </Tooltip>
  );
};

const QuickSetButtons = ({ setFilters, filters }) => {
  return QUICKSET_OPTIONS.map((option, index) => (
    <QuickSetButton
      key={index}
      option={option}
      index={index}
      setFilters={setFilters}
      filters={filters}
    />
  ));
};

const CustomTooltip = ({ cell }) => {
  const { x, y, unit, statistic } = cell.data;
  const depth = cell.serieId;
  const backgroundColor = cell.color;
  const textColor = getContrastYIQ(backgroundColor);

  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
        padding: "10px",
        borderRadius: "5px",
        color: textColor,
      }}
    >
      <Typography variant="body2">
        Date: <strong>{x}</strong>
      </Typography>
      <Typography variant="body2">
        Depth: <strong>{depth}</strong>
      </Typography>
      <Typography variant="body2">
        Temperature ({statistic}): <strong>{y}</strong> {unit}
      </Typography>
    </Box>
  );
};

const TemperatureProfiles = () => {
  const saveRef = useRef(null);
  const {
    graphData,
    spacedTickValues,
    data,
    isLoading,
    error,
    refetch,
    handleFilterChange,
    filters,
    setFilters,
  } = useTemperatureProfiles();

  const COLUMNS = useMemo(
    () => [
      { title: "Location", field: "location_display_label" },
      {
        title: "Report Date",
        field: "collect_timestamp",
        render: (rowData) => {
          return dateFormatter(
            rowData.collect_timestamp,
            filters.period === "profile-temperature-hourly"
              ? ISO_DATE_TIME_FORMAT
              : ISO_DATE_FORMAT
          );
        },
      },
      { title: "Depth, Ft", field: "collect_depth" },
      {
        title: "Average",
        field: "avg_value",
        cellStyle: {
          backgroundColor:
            filters.statistic === "avg_value" ? "#FFFFE0" : "inherit",
        },
      },
      ...(filters.period === "profile-temperature-daily"
        ? [
            {
              title: "Min",
              field: "min_value",
              cellStyle: {
                backgroundColor:
                  filters.statistic === "min_value" ? "#FFFFE0" : "inherit",
              },
            },
            {
              title: "Max",
              field: "max_value",
              cellStyle: {
                backgroundColor:
                  filters.statistic === "max_value" ? "#FFFFE0" : "inherit",
              },
            },
          ]
        : []),
      { title: "Units", field: "unit_display_name" },
    ],
    [data] //eslint-disable-line
  );

  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      <Helmet title="Temperature Profiles" />
      <Typography variant="h3" gutterBottom display="inline">
        Temperature Profiles
      </Typography>

      <Divider my={6} />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="options"
          id="options"
        >
          <Typography variant="h4" ml={2}>
            Options
          </Typography>
        </AccordionSummary>
        <Paper>
          <AccordionDetails>
            <Grid container pb={6} mt={2} spacing={3}>
              <Grid item xs={12} sm={6} md={8} xl={4}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="precinct">Location</InputLabel>
                  <Select
                    labelId="location"
                    id="location"
                    label="Location"
                    name="label"
                    value={filters.locationId ?? ""}
                    onChange={(e) => {
                      handleFilterChange("locationId", e.target.value);
                    }}
                  >
                    {LOCATION_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="precinct">Period</InputLabel>
                  <Select
                    labelId="period"
                    id="period"
                    label="Period"
                    name="label"
                    value={filters.period ?? ""}
                    onChange={(e) => {
                      handleFilterChange("period", e.target.value);
                    }}
                  >
                    {PERIOD_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="precinct">Statistic</InputLabel>
                  <Select
                    labelId="statistic"
                    id="statistic"
                    label="Statistic"
                    name="label"
                    value={filters.statistic ?? ""}
                    onChange={(e) => {
                      handleFilterChange("statistic", e.target.value);
                    }}
                  >
                    {STATISTIC_OPTIONS.filter(
                      (option) =>
                        filters.period !== "profile-temperature-hourly" ||
                        option.value === "avg_value"
                    ).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ width: "100%" }}
                    value={filters.startDate}
                    onChange={(date) => handleFilterChange("startDate", date)}
                    id="start-date"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="Start Date"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={4} xl={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ width: "100%" }}
                    value={filters.endDate}
                    onChange={(date) => handleFilterChange("endDate", date)}
                    id="end-date"
                    variant="inline"
                    format="LLLL do, yyyy"
                    autoOk
                    inputVariant="outlined"
                    label="End Date"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <SubmitGrid item xs={12}>
                <Button
                  onClick={() => refetch()}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled={!filters.startDate || !filters.endDate}
                >
                  Submit
                </Button>
                <span
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <QuickSetButtons setFilters={setFilters} filters={filters} />
                </span>
              </SubmitGrid>
            </Grid>
          </AccordionDetails>
        </Paper>
      </Accordion>

      <>
        {!!graphData.length && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="heat-chart"
              id="heat-chart"
            >
              <Typography variant="h4" ml={2}>
                Reservoir Temperature Profile Over Time
              </Typography>
            </AccordionSummary>
            <Paper>
              <AccordionDetails style={{ flexDirection: "column" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <Typography variant="h5" ml={6}>
                    {LOCATION_OPTIONS?.find(
                      (o) => o.value === filters.locationId
                    )?.label ?? ""}
                  </Typography>
                  <span
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <SaveGraphButton
                      ref={saveRef}
                      title="Temperature Profiles Heat Chart"
                      disabled={isLoading || !graphData}
                    />
                  </span>
                </Box>
                <ChartContainer ref={saveRef}>
                  <ResponsiveHeatMapCanvas
                    data={graphData}
                    valueFormat=">-.2f"
                    margin={{ top: 70, right: 60, bottom: 20, left: 80 }}
                    axisTop={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -90,
                      legend: "",
                      legendOffset: 6,
                      tickValues: spacedTickValues,
                      format: (v) => {
                        return dateFormatter(new Date(v), "MM/dd/yyyy");
                      },
                    }}
                    axisRight={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Elevation, Ft abMSL →",
                      legendPosition: "middle",
                      legendOffset: 50,
                    }}
                    axisLeft={null}
                    colors={{
                      type: "sequential",
                      scheme: "yellow_orange_red",
                      steps: 10,
                      minValue: 30,
                      maxValue: 75,
                    }}
                    emptyColor="#555555"
                    borderWidth={0}
                    borderColor="#000000"
                    enableLabels={false}
                    legends={[
                      {
                        anchor: "left",
                        translateX: -50,
                        translateY: 0,
                        length: 200,
                        thickness: 10,
                        direction: "column",
                        tickPosition: "after",
                        tickSize: 3,
                        tickSpacing: 4,
                        tickOverlap: false,
                        tickFormat: ">-.2f",
                        title: "Elevation, Ft abMSL →",
                        titleAlign: "start",
                        titleOffset: 4,
                      },
                    ]}
                    annotations={[]}
                    tooltip={CustomTooltip}
                  />
                </ChartContainer>
              </AccordionDetails>
            </Paper>
          </Accordion>
        )}

        {data && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Table
              </Typography>
            </AccordionSummary>
            <Paper>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    isLoading={isLoading}
                    label="Temperature Profile"
                    columns={COLUMNS}
                    data={data}
                    height="590px"
                    options={{ search: false }}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Paper>
          </Accordion>
        )}
      </>
    </>
  );
};

export default TemperatureProfiles;
