import LiquidChart from "react-liquidchart";
import React from "react";

function PercentFull({ waterElevation }) {
  const percentFull = waterElevation ? waterElevation.percent_full * 100 : 0;

  const chartConfig = {
    gradient: {
      type: 1,
      x1: 0,
      x2: 0,
      y1: 100,
      y2: 0,
    },
    outerStyle:
      waterElevation?.last_report === "Unavailable"
        ? { fill: "rgb(128,128,128)" }
        : { fill: "rgb(23,139,202)" },
  };

  return (
    <LiquidChart
      responsive
      legend="Percent Full"
      value={percentFull}
      showDecimal
      amplitude={4}
      frequency={2}
      animationTime={2000}
      animationWavesTime={2250}
      postfix="%"
      legendFontSize={0.1}
      {...chartConfig}
    />
  );
}

export default PercentFull;
