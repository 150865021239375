import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Line } from "react-chartjs-2";
import ZoomResetButton from "../colorsOfWater/chartJS/ZoomResetButton";
import {
  annotatedVerticalLinePlugin,
  chartBackgroundPlugin,
} from "../colorsOfWater/chartJS/ChartPlugins";
import { Box, Button, Grid } from "@material-ui/core";
import styled from "styled-components/macro";

const ButtonContainer = styled(Box)`
  text-align: center;
  margin-top: 16px;
  width: 100%;
`;

const ToggleButton = styled(Button)`
  width: 150px;
`;

const useChartRefs = (numRefs) => {
  const refs = useRef([]);
  if (refs.current.length !== numRefs) {
    refs.current = Array.from({ length: numRefs }, () => React.createRef());
  }
  return refs.current;
};

const useOriginalScaleBounds = (xMax) => {
  return useMemo(() => ({ x: { min: 1, max: xMax } }), [xMax]);
};

const useUpdateCharts = (chartRefs) => {
  return useCallback(
    (callback) => {
      chartRefs.forEach((ref) => {
        const chart = ref.current;
        if (chart) callback(chart);
      });
    },
    [chartRefs]
  );
};

//TODO debounce or throttle?
const useHoverHandler = (updateCharts) => {
  const previousActiveElements = useRef([]);

  return (event, elements) => {
    if (elements.length) {
      const elementIndex = elements[0].index;
      const newActiveElements = elements.map(({ datasetIndex, index }) => ({
        datasetIndex,
        index,
      }));

      // Compare with previous active elements
      if (
        JSON.stringify(newActiveElements) !==
        JSON.stringify(previousActiveElements.current)
      ) {
        previousActiveElements.current = newActiveElements;

        requestAnimationFrame(() => {
          updateCharts((chart) => {
            const allElements = Array.from(
              { length: chart.data.datasets.length },
              (_, i) => ({
                datasetIndex: i,
                index: elementIndex,
              })
            );
            chart.setActiveElements(allElements);

            chart.tooltip.setActiveElements(allElements, {
              x: chart.data.labels[elementIndex],
            });
            chart.update();
          });
        });
      }
    } else {
      // Clear the hover effects if no active elements
      previousActiveElements.current = [];
      updateCharts((chart) => {
        chart.setActiveElements([]);
        chart.tooltip.setActiveElements([], {});
        chart.update();
      });
    }
  };
};
const useZoomHandler = (updateCharts) => {
  return useCallback(
    (start, end) => {
      requestAnimationFrame(() => {
        updateCharts((chart) => {
          chart.options.scales.x.min = start;
          chart.options.scales.x.max = end;
          chart.update();
        });
      });
    },
    [updateCharts]
  );
};

const useResetZoomHandler = (originalScaleBounds, updateCharts) => {
  return useCallback(() => {
    requestAnimationFrame(() => {
      updateCharts((chart) => {
        chart.options.scales.x.min = originalScaleBounds.x.min;
        chart.options.scales.x.max = originalScaleBounds.x.max;
        chart.update();
      });
    });
  }, [originalScaleBounds, updateCharts]);
};

const useChartOptions = (handleZoom) => {
  return useCallback(
    (chartData) => ({
      interaction: {
        intersect: false,
        mode: "index",
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          offset: false,
          ticks: {
            maxRotation: 45,
            minRotation: 45,
            maxTicksLimit: 12,
          },
          grid: {
            display: false,
          },
          min: chartData.xMin,
          max: chartData.xMax,
        },
        yL: {
          type: "linear",
          position: "left",
          title: {
            display: true,
            text: chartData?.yLLabel,
          },
          afterFit: function (scale) {
            scale.width = 70;
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: chartData?.chartTitle,
          font: {
            size: 24,
          },
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const value = tooltipItem.raw;
              if (value === null) {
                return `N/A`;
              }
              return `${tooltipItem.dataset.label}: ${value}`;
            },
            title: (tooltipItems) => {
              if (!tooltipItems.length) return "No Data Available";
              const title = tooltipItems[0].label;
              return title;
            },
          },
          filter: (tooltipItem) => {
            return tooltipItem.raw !== null;
          },
        },
        zoom: {
          zoom: {
            mode: "x",
            drag: {
              enabled: true,
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 1,
              backgroundColor: "rgba(54, 162, 235, 0.3)",
              drawTime: "afterDatasetsDraw",
            },
            onZoom: ({ chart }) => {
              const xAxis = chart.scales.x;
              handleZoom(xAxis.min, xAxis.max);
            },
          },
        },
      },
    }),
    [handleZoom]
  );
};

const MultiChart = ({ data }) => {
  const [gridSize, setGridSize] = useState(6);
  const toggleGridSize = () =>
    setGridSize((prevSize) => (prevSize === 6 ? 12 : 6));

  const chartRefs = useChartRefs(data?.length || 0);
  const originalScaleBounds = useOriginalScaleBounds(data?.labels?.length - 1);
  const updateCharts = useUpdateCharts(chartRefs);
  const handleHover = useHoverHandler(updateCharts);
  const handleZoom = useZoomHandler(updateCharts);
  const resetZoom = useResetZoomHandler(originalScaleBounds, updateCharts);
  const getOptions = useChartOptions(handleZoom);

  useEffect(() => {
    updateCharts((chart) => {
      chart.options.onHover = (event, elements) => handleHover(event, elements);
      chart.update();
    });
  }, [handleHover, updateCharts]);

  return (
    <>
      <ZoomResetButton resetZoom={resetZoom} />
      <Grid container spacing={gridSize === 6 ? 2 : 0}>
        {chartRefs.map((ref, i) => {
          const dataset = data[i];
          return (
            <Grid
              xs={12}
              md={gridSize}
              item
              key={i}
              style={{ height: "400px" }}
            >
              <Line
                plugins={[chartBackgroundPlugin, annotatedVerticalLinePlugin]}
                ref={ref}
                data={dataset}
                options={getOptions(dataset)}
              />
            </Grid>
          );
        })}
      </Grid>
      <ButtonContainer>
        <ToggleButton
          variant="contained"
          color="primary"
          onClick={toggleGridSize}
        >
          {gridSize === 6 ? "Full Width" : "Split View"}
        </ToggleButton>
      </ButtonContainer>
    </>
  );
};

export default memo(MultiChart);
