import Button from "@material-ui/core/Button";
import { ZoomOutMap } from "@material-ui/icons";
import React from "react";

const ZoomResetButton = ({ resetZoom }) => {
  const handleResetZoom = () => {
    if (resetZoom) {
      resetZoom();
    }
  };

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      startIcon={<ZoomOutMap />}
      onClick={handleResetZoom}
    >
      Reset Zoom
    </Button>
  );
};

export default ZoomResetButton;
