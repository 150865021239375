import React from "react";
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import GroupsToContactsAssoc from "./GroupsToContactsAssoc";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const GroupsToContacts = () => {
  return (
    <React.Fragment>
      <Helmet title="Groups to Contacts Associations" />
      <Typography variant="h3" gutterBottom display="inline">
        Groups to Contacts Associations
      </Typography>

      <Divider my={6} />

      <Panel title="Groups to Contacts">
        <GroupsToContactsAssoc />
      </Panel>
    </React.Fragment>
  );
};

export default GroupsToContacts;
