import React from "react";
import styled from "styled-components/macro";
import AppBar from "./AppBar";
import Hero from "./Hero";
import Introduction from "./Introduction";
import Explore from "./Explore";
// import Stats from "./Stats";
import Footer from "./Footer";
import StakeholderThemeProvider from "../../../theme/stakeholderTheme";
import Funding from "./Funding";

const PageContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function Splash() {
  return (
    <StakeholderThemeProvider>
      <PageContainer>
        <AppBar />
        <Hero />
        <Introduction />
        {/*<Stats />*/}
        <Explore />
        <Funding />
        {/*<Contribute />*/}
        <Footer />
      </PageContainer>
    </StakeholderThemeProvider>
  );
}

export default Splash;
