import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Box, Tooltip, Typography } from "@material-ui/core";
import BatteryGauge from "react-battery-gauge";

const WidgetContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WidgetTitle = styled(Typography)`
  color: #045681;
  font-weight: 700;
  text-align: center;
`;

const BatteryGaugeContainer = styled.div`
  width: 100%;
`;

function BatteryMinimums({ battery }) {
  const minVoltage = 10; // Minimum voltage
  const maxVoltage = 14; // Maximum voltage

  // Calculate the battery level for the gauge based on the voltage
  // Normalize the battery's voltage to fit the gauge's scale (0-100)
  const batteryLevel =
    ((battery?.min_24hr - minVoltage) / (maxVoltage - minVoltage)) * 100;

  // Ensure the battery level is within the 0-100 range to avoid negative values or values over 100
  const validBatteryLevel = Math.min(Math.max(batteryLevel, 0), 100);

  const tooltipContent =
    battery.last_report === "Unavailable" ? (
      "Unavailable"
    ) : (
      <>
        Minimum 24-hour value reached {battery.min_24hr} Volts.
        <br />
        Last reported value was {battery.result_value} Volts @{" "}
        {battery.last_report}.
      </>
    );

  useEffect(() => {
    // This targets only the elements within the container of the current instance
    const container = document.querySelector(
      `[data-battery-id="${battery.location_id}"]`
    );
    if (container) {
      const readingElements = container.querySelectorAll(".readingText");
      readingElements.forEach((element) => {
        if (battery.min_24hr !== undefined && battery.min_24hr !== null) {
          element.textContent = `${battery.min_24hr} Volts`;
        }
      });
    }
  }, [battery.min_24hr, battery.location_id]);

  return (
    <WidgetContainer>
      <WidgetTitle mb={2} variant="h5">
        {battery.location_name}
      </WidgetTitle>
      <Tooltip
        title={<Typography variant="h6">{tooltipContent}</Typography>}
        placement="bottom"
      >
        <BatteryGaugeContainer>
          <BatteryGauge
            data-battery-id={battery.location_id}
            width="100%"
            value={validBatteryLevel}
            aspectRatio={0.28}
            customization={{
              batteryMeter: {
                noOfCells: 10,
              },
              batteryBody: {
                fill: battery.last_report === "Unavailable" ? "gray" : "none",
              },
            }}
          />
        </BatteryGaugeContainer>
      </Tooltip>
    </WidgetContainer>
  );
}

export default BatteryMinimums;
