import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Unauthorized from "./Unauthorized";

const AdminGuard = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();

  // Immediately return Unauthorized if not authenticated to avoid unnecessary checks
  if (!isAuthenticated || !user) return <Unauthorized />;

  // Extract roles directly with a default to an empty array for safe chaining
  const roles = user[`${process.env.REACT_APP_AUDIENCE}/roles`] || [];

  // Check for authorized roles
  const isAuthorizedUser = roles.some((role) =>
    ["Administrator", "Developer"].includes(role)
  );

  // Render Unauthorized component if the user does not have the right roles
  if (!isAuthorizedUser) {
    return <Unauthorized />;
  }

  // User is authenticated and authorized, render the children components
  return children;
};

export default AdminGuard;
