import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import { ChevronRight } from "@material-ui/icons";
import { ROUTES } from "../../../constants";
import { Link } from "react-router-dom";

const Root = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: white;
`;

const SectionContainer = styled(Box)`
  max-width: 1200px;
  display: flex;
  align-items: center;
  min-height: 50vh;
  padding-left: 64px;
  margin: 96px 0;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    padding-left: 0;
  }
`;

const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #f4f1eb;
  margin-left: 224px;
  padding: 96px 160px 96px 192px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 32px;
    margin-left: 0;
  }
`;

const CardIcon = styled(Box)`
  width: 64px;
  height: 64px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const MapContainer = styled(Box)`
  position: absolute;
  height: 500px;
  width: 350px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 250px;
    width: 100%;
    position: relative;
  }
`;

const MapImageStyled = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SectionTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 6px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    letter-spacing: 4px;
`;

const Introduction = () => {
  return (
    <Root>
      <SectionContainer>
        <InfoContainer>
          <CardIcon>
            <img
              src="/static/img/splash/svg/YampaRiverDashboard-DropIcon.svg"
              alt="water icon"
            />
          </CardIcon>
          <SectionTitle color="secondary" variant="h4">
            What is the dashboard?
          </SectionTitle>
          <Typography
            color="primary"
            variant="body1"
            style={{ fontWeight: "bold" }}
          >
            Explore historical, current, and forecasted data specific to the
            locations and uses that impact you the most all in one place.
            <br /> <br />
            The Yampa River Dashboard is your hub for regional water data -
            gathering information from multiple sources with a curated
            collection of easy-to-use data tools based on your specific
            interests.
            <br /> <br />
            Whether you are looking for recreational opportunities, timing flood
            irrigation, managing reservoir releases, or looking for water
            quality standards, you can find the data needed to make more
            informed decisions about your Yampa River right here.
          </Typography>
          <Button
            endIcon={<ChevronRight />}
            variant="contained"
            size="medium"
            color="secondary"
            style={{ width: "165px" }}
            component={Link}
            to={ROUTES.PAGE_DASHBOARD}
          >
            Dashboard
          </Button>
        </InfoContainer>
        <MapContainer>
          <MapImageStyled
            src="/static/img/splash/map.png"
            alt="Yampa River Map"
          />
        </MapContainer>
      </SectionContainer>
    </Root>
  );
};

export default Introduction;
