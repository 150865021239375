export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const REQUIRED_NUMBER_OF_GRAPH_SELECTIONS = 2;

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api/climate-and-flows-explorer`;

export const DEFAULT_INPUT_STATE = {
  regionalGroup: 4, //Steamboat Springs
  year: new Date().getFullYear(),
  yearType: "collect_year",
  graphs: [],
};

export const GRAPHS_OPTIONS_WITH_LEFT_AXIS_ID_EXCEPTIONS = [4, 10, 11];
