import React, { useRef } from "react";

import { Helmet } from "react-helmet-async";
import { useStagecoachTimeSeries } from "./useStagecoachTimeSeries";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  AccordionDetails,
  Paper,
  Divider as MuiDivider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TimeseriesLineChartDashboard from "../../../components/graphs/TimeseriesLineChartDashboard";
import Table from "../../../components/Table";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

function StagecoachTimeSeries() {
  const saveRef = useRef(null);
  const { data, userState } = useStagecoachTimeSeries();

  const { handleFilterChange, filterValues } = userState;

  const tabColumns = [
    {
      title: "Location",
      field: "location_display_label",
    },
    {
      title: "Parameter",
      field: "parameter_display_abbrev",
    },
    {
      title: "Units",
      field: "unit_display_name",
    },
    {
      title: "Timestamp",
      field: "collect_timestamp",
      render: (rowData) => {
        return dateFormatter(rowData.collect_timestamp, "MM/dd/yyyy, h:mm a");
      },
    },
    {
      title: "Value",
      field: "result_value",
    },
  ];

  return (
    <React.Fragment>
      <Helmet title="Stagecoach Reservoir Time Series Dashboard" />
      <Typography variant="h3" gutterBottom display="inline">
        Stagecoach Reservoir Time Series Dashboard
      </Typography>
      <Divider my={6} />

      {filterValues && (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Paper style={{ padding: "10px" }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Timestep</FormLabel>
                  <RadioGroup
                    aria-label="route"
                    name="route"
                    value={filterValues.route}
                    onChange={(event) =>
                      handleFilterChange("route", event.target.value)
                    }
                  >
                    {data.options.data[
                      "resops-ts-stagecoach-timestep-options"
                    ].map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.data_table_name}
                        control={<Radio />}
                        label={option.option_name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Left Axis</FormLabel>
                  <RadioGroup
                    aria-label="leftAxis"
                    name="leftAxis"
                    value={filterValues.leftAxis}
                    onChange={(event) =>
                      handleFilterChange("leftAxis", event.target.value)
                    }
                  >
                    {data.options.data[
                      "resops-ts-stagecoach-left-axis-options"
                    ].map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.axis}
                        control={<Radio />}
                        label={option.parameter_display_abbrev}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Right Axis</FormLabel>
                  <RadioGroup
                    aria-label="rightAxis"
                    name="rightAxis"
                    value={filterValues.rightAxis}
                    onChange={(event) =>
                      handleFilterChange("rightAxis", event.target.value)
                    }
                  >
                    {data.options.data[
                      "resops-ts-stagecoach-right-axis-options"
                    ].map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.axis}
                        control={<Radio />}
                        label={option.parameter_display_abbrev}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Period Ending</FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={filterValues.periodEnding}
                      onChange={(newValue) =>
                        handleFilterChange("periodEnding", newValue)
                      }
                      variant="inline"
                      format="MM/dd/yyyy"
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  <Alert
                    severity="info"
                    style={{ marginTop: "8px", marginBottom: "20px" }}
                  >
                    <AlertTitle>
                      <strong>**Time Series Quick Tips**</strong>
                    </AlertTitle>
                    — Click on a legend item to remove/add back in the
                    corresponding line from the graph
                    <br />— Draw a box on the graph to zoom to that part of the
                    graph
                    <br />— Click the blue “Reset Zoom” button at the bottom of
                    the graph to go back to the default view
                  </Alert>
                  <TimeseriesLineChartDashboard
                    data={data.graph}
                    error={data.timeseries.error}
                    isLoading={data.timeseries.isLoading}
                    filterValues={filterValues}
                    yLLabel={data.graph.yLLabel}
                    yRLabel={data.graph.yRLabel}
                    xLabelUnit={
                      filterValues.route === "resops-ts-stagecoach-daily"
                        ? "month"
                        : filterValues.route === "resops-ts-stagecoach-hourly"
                        ? "week"
                        : "day"
                    }
                    ref={saveRef}
                    tooltipFormat={
                      filterValues.route === "resops-ts-stagecoach-daily"
                        ? "MM-DD-YYYY"
                        : "MM-DD-YYYY, h:mm A"
                    }
                    footerLabel="Hours Pumped"
                    interactionMode="index"
                    reverseLegend={false}
                  />
                </AccordionDetails>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <TableWrapper>
                  {data ? (
                    <Table
                      label="Table"
                      isLoading={data.timeseries.isLoading}
                      columns={tabColumns}
                      data={
                        data?.timeseries?.data?.filter(
                          (item) => item.result_value
                        ) || []
                      }
                      height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
                      pageSize={60}
                      options={{ filtering: true }}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default StagecoachTimeSeries;
