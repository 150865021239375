import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { USE_QUERY_OPTIONS } from "../queryAndDownload/constants";
import axios from "axios";

export const generateAuthHeaders = async (
  getAccessTokenSilently,
  isAuthenticated
) => {
  let headers = {};
  if (isAuthenticated) {
    try {
      const token = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  }
  return headers;
};

const getData = async (url, headers = {}) => {
  try {
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};

export const useCustomQuery = (key, endpoint, options = {}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const fetchConditionallyWithToken = async () => {
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    return getData(
      `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
      headers
    );
  };

  return useQuery(key, fetchConditionallyWithToken, {
    ...USE_QUERY_OPTIONS,
    ...options,
  });
};

export const hexToRgb = (hex) => {
  const cleanedHex = hex.replace("#", "");
  const bigint = parseInt(cleanedHex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

export function hexToRGBA(hex, opacity) {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
