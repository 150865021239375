import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";
import { formatBooleanTrueFalse, dateFormatter } from "../../../utils";
import parse from "html-react-parser";
import Button from "@material-ui/core/Button";
import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  isWidthDown,
  withWidth,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const PopupUl = styled.ul`
  list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

const Popup = ({
  features,
  layers,
  handleGraphModeFromPoint,
  graphModeVisibleRef,
  height = "200px",
  maxWidth = "380px",
  size = "medium",
  setDataVizVisible,
  setDataVizWellNumber,
  setDataVizGraphType,
  width,
}) => {
  function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    //concat two ids to make a unique id
    return array.filter((el) => {
      const key = el[comparatorProperty1] + el[comparatorProperty2];
      if (existingFeatureKeys[key]) {
        return false;
      } else {
        existingFeatureKeys[key] = true;
        return true;
      }
    });
  }

  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0]);
  const [excludeFields, setExcludeFields] = useState([]);
  const [titleField, setTitleField] = useState("");

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    if (feature && layers) {
      const excludedFields = layers?.find(
        (layer) => layer?.id === feature?.layer?.id
      )?.lreProperties?.popup?.excludeFields;
      setExcludeFields(excludedFields || []);
    }
  }, [feature, layers]);

  useEffect(() => {
    if (feature && layers) {
      const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
        ?.lreProperties?.popup?.titleField;
      setTitleField(
        (title && feature?.properties[title] && (
          <>
            <Typography variant="h4" style={{ fontWeight: 600 }}>
              {feature?.layer?.source === "yampa-river-locations"
                ? feature?.properties.location_name
                : titleize(feature?.layer?.source)}
            </Typography>
            <Divider my={1} mx={2} />
            <Typography mb={2} variant="h6">
              {feature?.layer?.source === "yampa-river-locations"
                ? feature?.properties.location_id
                : feature?.properties[title]}
            </Typography>
          </>
        )) || (
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            {titleize(feature?.layer?.source)}
          </Typography>
        )
      );
    }
  }, [feature, layers]);

  let popupData = [];

  if (feature?.layer?.id) {
    if (
      [
        "yampa-river-locations-circle",
        "temperature-and-flow",
        "river-flows",
        "river-temperatures",
      ].includes(feature.layer.id)
    ) {
      popupData = [
        ...(feature?.properties?.is_wq_loc &&
        graphModeVisibleRef &&
        graphModeVisibleRef?.current !== true
          ? [
              [
                "Water Quality",
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleGraphModeFromPoint(feature?.properties?.ndx)
                  }
                >
                  WQ Map
                </Button>,
              ],
            ]
          : []),
        ...(feature?.properties.last_reported_flow
          ? [
              [
                "Last Flow",
                `${Number(
                  feature?.properties.last_reported_flow.toFixed(0)
                ).toLocaleString()} cfs at ${dateFormatter(
                  feature?.properties.last_reported_flow_timestamp,
                  "MM/dd/yyyy h:mm a"
                )}`,
              ],
            ]
          : []),
        ...((feature?.properties.has_flow_graph ||
          feature?.properties.has_flow_trends) &&
        graphModeVisibleRef
          ? [
              [
                "Flow",
                <>
                  {feature?.properties.has_flow_graph && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={graphModeVisibleRef?.current === true}
                        onClick={() => {
                          setDataVizVisible(true);
                          setDataVizWellNumber(feature.properties.location_ndx);
                          setDataVizGraphType("has_flow_graph");
                        }}
                      >
                        Continuous Daily
                      </Button>
                    </span>
                  )}
                  {feature?.properties.has_flow_trends && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "6px",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={graphModeVisibleRef?.current === true}
                        onClick={() => {
                          setDataVizVisible(true);
                          setDataVizWellNumber(feature.properties.location_ndx);
                          setDataVizGraphType("has_flow_trends");
                        }}
                      >
                        Historical Comparison
                      </Button>
                    </span>
                  )}
                </>,
              ],
            ]
          : []),
        ...(feature?.properties.last_reported_water_temperature_formatted
          ? [
              [
                "Last Temp",
                `${
                  feature?.properties.last_reported_water_temperature_formatted
                } at ${dateFormatter(
                  feature?.properties.last_reported_water_temperature_timestamp,
                  "MM/dd/yyyy h:mm a"
                )}`,
              ],
            ]
          : []),
        ...((feature?.properties.has_temperature_graph ||
          feature?.properties.has_temperature_trends) &&
        graphModeVisibleRef
          ? [
              [
                "Temperature",
                <>
                  {feature?.properties.has_temperature_graph && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={graphModeVisibleRef?.current === true}
                        onClick={() => {
                          setDataVizVisible(true);
                          setDataVizWellNumber(feature.properties.location_ndx);
                          setDataVizGraphType("has_temperature_graph");
                        }}
                      >
                        Continuous Daily
                      </Button>
                    </span>
                  )}
                  {feature?.properties.has_temperature_trends && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "6px",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={graphModeVisibleRef?.current === true}
                        onClick={() => {
                          setDataVizVisible(true);
                          setDataVizWellNumber(feature.properties.location_ndx);
                          setDataVizGraphType("has_temperature_trends");
                        }}
                      >
                        Historical Comparison
                      </Button>
                    </span>
                  )}
                </>,
              ],
            ]
          : []),
        ...(feature?.properties.public_temp_data_src_url ||
        feature?.properties.public_flow_data_src_url
          ? [
              [
                "More Info ",
                <>
                  {feature?.properties.public_flow_data_src_url && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        target="_blank"
                        href={feature?.properties.public_flow_data_src_url}
                        rel="noreferrer"
                      >
                        Flow
                      </a>
                    </span>
                  )}
                  {feature?.properties.public_temp_data_src_url && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        target="_blank"
                        href={feature?.properties.public_temp_data_src_url}
                        rel="noreferrer"
                      >
                        Chemistry/Physical
                      </a>
                    </span>
                  )}
                </>,
              ],
            ]
          : []),
        ...(feature?.properties.locations_urls
          ? [["More Info", feature?.properties.locations_urls]]
          : []),
        ...(feature?.properties?.por_start && feature?.properties?.por_end
          ? [
              [
                "Period of Record",
                `${new Date(
                  feature?.properties.por_start
                ).getFullYear()} - ${new Date(
                  feature?.properties.por_end
                ).getFullYear()}`,
              ],
            ]
          : [["Period of Record", "N/A"]]),
        ...(feature?.properties?.resultcount || feature?.properties?.recordcount
          ? [
              [
                "Record Count",
                feature?.properties?.resultcount ||
                  feature?.properties?.recordcount,
              ],
            ]
          : [["Record Count", "N/A"]]),
        ...(feature?.properties?.organizations
          ? [
              [
                "Organizations",
                feature?.properties.organizations
                  .replace(/["{}[\]]/g, "")
                  .replaceAll(",", ", "),
              ],
            ]
          : [["Organizations", "N/A"]]),
        ...(feature?.properties?.huc12
          ? [["HUC 12", feature?.properties?.huc12]]
          : []),
        ...(feature?.properties?.huc12_name
          ? [["HUC 12 Name", feature?.properties?.huc12_name]]
          : []),
        ...(feature?.properties?.parameter_groups
          ? [
              [
                "Parameter Groups",
                feature?.properties.parameter_groups
                  .replace(/["{}[\]]/g, "")
                  .replaceAll(",", ", "),
              ],
            ]
          : []),
        ...(feature?.properties?.locationtype
          ? [["Location Type", feature?.properties?.locationtype]]
          : []),
        ...(feature?.properties?.latitude
          ? [["Latitude", feature?.properties?.latitude]]
          : []),
        ...(feature?.properties?.longitude
          ? [["Longitude", feature?.properties?.longitude]]
          : []),
        ...(feature?.properties?.conservancy_district_dist_name
          ? [
              [
                "Conservancy District",
                feature?.properties?.conservancy_district_dist_name,
              ],
            ]
          : []),
        ...(feature?.properties?.conservation_district_name
          ? [
              [
                "Conservation District",
                feature?.properties?.conservation_district_name,
              ],
            ]
          : []),
      ];
    } else if (feature.layer.id === "rivercalls") {
      popupData = [
        ["Title", `${feature?.properties.structure_name}`],
        ["WDID", `${feature?.properties.wdid}`],
        ["Water Source", `${feature?.properties.water_source}`],
        ["Call type", `${feature?.properties.call_type}`],
        [
          "Date Set",
          `${dateFormatter(feature?.properties.date_set, "MM/dd/yyyy")}`,
        ],
        ["Admin No.", `${feature?.properties.admin_no}`],
        [
          "Priority Date",
          `${dateFormatter(feature?.properties.appro_date, "MM/dd/yyyy")}`,
        ],
        ["Stream Mile", `${feature?.properties.stream_mile}`],
        ["Set Comments", `${feature?.properties.set_comments}`],
        [
          "Source Url",
          `<a target='_blank' href='${feature?.properties.source_url}'>Source</a>`,
        ],
      ];
    } else {
      popupData = excludeFields
        ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
            if (
              !excludeFields.includes(key) &&
              value !== "" &&
              !isNullOrUndef(value)
            ) {
              acc.push([key, value]);
            }
            return acc;
          }, [])
        : Object.entries(feature?.properties);
    }
  }

  if (!popupData) return null;

  return (
    <>
      <PopupWrap
        height={isWidthDown("xs", width) ? "120px" : height}
        width={isWidthDown("sm", width) ? "205px" : maxWidth}
      >
        {titleField}
        <PopupTable>
          <tbody>
            {popupData?.map(([key, value], i) => {
              return (
                <PopupRow key={i}>
                  <PopupCell>
                    <strong>{titleize(key)}</strong>
                  </PopupCell>
                  <PopupCell>
                    {key === "More Info" ? (
                      value.split(",").map((item) => (
                        <div key={item}>
                          <a
                            target="_blank"
                            href={item.split(";")[1]}
                            rel="noreferrer"
                          >
                            {item.split(";")[0]}
                          </a>
                        </div>
                      ))
                    ) : typeof value === "string" && value.startsWith("<a ") ? (
                      <PopupUl>
                        {value.split(",").map((item) => (
                          <li key={item}>{parse(item)}</li>
                        ))}
                      </PopupUl>
                    ) : (
                      formatBooleanTrueFalse(value)
                    )}
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={isWidthDown("sm", width) ? "small" : size}
        siblingCount={isWidthDown("sm", width) ? 0 : 1}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default withWidth()(Popup);
