import React from "react";

import {
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import { Alert, AlertTitle } from "@material-ui/lab";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

const SmsHelp = () => {
  const data = [
    {
      cell_provider: "AT&T",
      sms_address: "number@txt.att.net",
      mms_address: "number@mms.att.net",
    },
    {
      cell_provider: "T-Mobile",
      sms_address: "number@tmomail.net",
      mms_address: "number@tmomail.net",
    },
    {
      cell_provider: "Verizon",
      sms_address: "number@vtext.com",
      mms_address: "number@vzwpix.com",
    },
    {
      cell_provider: "XFinity Mobile",
      sms_address: "number@vtext.com",
      mms_address: "number@mypixmessages.com",
    },
    {
      cell_provider: "Virgin Mobile",
      sms_address: "number@vmobl.com",
      mms_address: "number@vmpix.com",
    },
    {
      cell_provider: "Tracfone",
      sms_address: "number@mmst5.tracfone.com",
      mms_address: "",
    },
    {
      cell_provider: "Metro PCS",
      sms_address: "number@mymetropcs.com",
      mms_address: "number@mymetropcs.com",
    },
    {
      cell_provider: "Boost Mobile",
      sms_address: "number@sms.myboostmobile.com",
      mms_address: "number@myboostmobile.com",
    },
    {
      cell_provider: "Cricket",
      sms_address: "number@sms.cricketwireless.net",
      mms_address: "number@mms.cricketwireless.net",
    },
    {
      cell_provider: "Republic Wireless",
      sms_address: "number@text.republicwireless.com",
      mms_address: "",
    },
    {
      cell_provider: "Google Fi (Project Fi)",
      sms_address: "number@msg.fi.google.com",
      mms_address: "number@msg.fi.google.com",
    },
    {
      cell_provider: "U.S. Cellular",
      sms_address: "number@email.uscc.net",
      mms_address: "number@mms.uscc.net",
    },
    {
      cell_provider: "Ting",
      sms_address: "number@message.ting.com",
      mms_address: "",
    },
    {
      cell_provider: "Consumer Cellular",
      sms_address: "number@mailmymobile.net",
      mms_address: "",
    },
    {
      cell_provider: "C-Spire",
      sms_address: "number@cspire1.com",
      mms_address: "",
    },
    {
      cell_provider: "Page Plus",
      sms_address: "number@vtext.com",
      mms_address: "",
    },
    {
      cell_provider: "Viaero",
      sms_address: "",
      mms_address: "number@mmsviaero.com",
    },
  ];

  const tabColumns = [
    {
      title: "Cell Provider",
      field: "cell_provider",
    },
    {
      title: "SMS address",
      field: "sms_address",
    },
    {
      title: "MMS Address",
      field: "mms_address",
    },
  ];

  return (
    <>
      <Helmet title="SMS Help" />
      <Typography variant="h3" gutterBottom display="inline">
        SMS Help
      </Typography>

      <Divider my={6} />

      <Paper mb={4}>
        <Alert severity="info">
          <AlertTitle>
            To subscribe a user to an alert using a phone number, add a phone #
            based email addresses to your Contacts list using the recipients 7
            digit phone number and cell phone provider information shown below.
          </AlertTitle>

          <AlertTitle>
            <strong>
              Please note that cellular providers may block text messages sent
              to large groups - keep groups smaller than 10 addresses to avoid
              triggering spam filters.
            </strong>
          </AlertTitle>
        </Alert>
      </Paper>

      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="SMS Help"
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 255px)"
              pageSize={60}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SmsHelp;
