import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueries } from "react-query";
import axios from "axios";
import {
  BASE_URL,
  DEFAULT_INPUT_STATE,
  GRAPHS_OPTIONS_WITH_LEFT_AXIS_ID_EXCEPTIONS,
  REQUIRED_NUMBER_OF_GRAPH_SELECTIONS,
  USE_QUERY_OPTIONS,
} from "./constants";
import { useLocation, useParams } from "react-router-dom";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import { useCustomQuery, hexToRGBA } from "./utils";

const validateGraphIds = (graphIds, graphOptions, maxSelections) => {
  return graphIds
    .map((id) =>
      graphOptions.find((graph) => graph.caf_group_ndx === parseInt(id))
    )
    .filter(Boolean)
    .slice(0, maxSelections);
};

const validateOption = (value, options, key) =>
  options.some((option) => option[key] === value);

const validateYearType = (yearType) => {
  const validYearTypes = ["collect_year", "collect_water_year"];
  return validYearTypes.includes(yearType);
};

const useInitializeWithQueryParams = ({
  graphOptionsQuery,
  regionalGroupsQuery,
  yearsQuery,
  currentCategory,
  setInputState,
}) => {
  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (
      isInitialMount.current &&
      graphOptionsQuery.isSuccess &&
      regionalGroupsQuery.isSuccess &&
      yearsQuery.isSuccess &&
      !currentCategory
    ) {
      const params = new URLSearchParams(location.search);
      const newInputState = { ...DEFAULT_INPUT_STATE };

      if (params.has("graphs")) {
        const graphIds = params.get("graphs")?.split(",").filter(Boolean) || [];
        newInputState.graphs = validateGraphIds(
          graphIds,
          graphOptionsQuery.data,
          REQUIRED_NUMBER_OF_GRAPH_SELECTIONS
        );
      } else {
        newInputState.graphs = graphOptionsQuery.data.filter((d) =>
          d.datacategory_ndx_defaults.includes(0)
        );
      }

      if (params.has("regionalGroup")) {
        const regGroup = parseInt(params.get("regionalGroup"));
        if (
          validateOption(regGroup, regionalGroupsQuery.data, "reg_group_ndx")
        ) {
          newInputState.regionalGroup = regGroup;
        }
      }

      if (params.has("year")) {
        const year = parseInt(params.get("year"));
        if (validateOption(year, yearsQuery.data, "explore_year")) {
          newInputState.year = year;
        }
      }

      if (params.has("yearType")) {
        const yearType = params.get("yearType");
        if (validateYearType(yearType)) {
          newInputState.yearType = yearType;
        }
      }

      setInputState(newInputState);
      isInitialMount.current = false;
    }
  }, [
    graphOptionsQuery,
    regionalGroupsQuery,
    yearsQuery,
    currentCategory,
    location.search,
    setInputState,
  ]);

  return { isInitialMount };
};

const useInitializeWithDataCategory = () => {
  const { datacategoryPath } = useParams();

  const currentCategory = useMemo(
    () =>
      Object.values(STAKEHOLDERS_CONFIG).find(
        (config) => datacategoryPath === config.datacategoryPath
      ),
    [datacategoryPath]
  );

  return currentCategory;
};

const useGraphQueries = (inputState, BASE_URL) => {
  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }

    if (inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS) {
      cancelTokenSourceRef.current = axios.CancelToken.source();
    }
  }, [inputState.graphs, inputState.year, inputState.regionalGroup]);

  const graphQueries = useQueries(
    inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS
      ? inputState.graphs.map((graph) => ({
          queryKey: [
            graph.caf_graph_route_name,
            inputState.regionalGroup,
            inputState.year,
          ],
          queryFn: async () => {
            try {
              const response = await axios.get(
                `${BASE_URL}/data/${graph.caf_graph_route_name}`,
                {
                  params: {
                    group: inputState.regionalGroup,
                    year: inputState.year,
                  },
                  cancelToken: cancelTokenSourceRef.current.token,
                }
              );
              return response.data;
            } catch (error) {
              if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
                throw new Error("Query was canceled");
              } else {
                throw error;
              }
            }
          },
          onError: (error) => {
            if (error.message === "Query was canceled") {
              console.log("Canceled query will not cache data.");
            }
          },
          ...USE_QUERY_OPTIONS,
          staleTime: Infinity,
          cacheTime: Infinity,
          enabled: true,
        }))
      : []
  );

  return graphQueries;
};

const generateYearLabels = (year, yearType) => {
  const labels = [];
  let startYear = year;
  let startMonth = 1; // Default to January
  let startDay = 1; // Default to 1st day of the month

  if (yearType === "collect_water_year") {
    startYear = year - 1; // Water year starts in the previous year
    startMonth = 10; // Water year starts in October
  }

  for (let month = startMonth; month <= 12; month++) {
    const daysInMonth = new Date(startYear, month, 0).getDate(); // Get days in the month
    for (let day = startDay; day <= daysInMonth; day++) {
      const monthStr = month.toString().padStart(2, "0");
      const dayStr = day.toString().padStart(2, "0");
      labels.push(`${startYear}-${monthStr}-${dayStr}`);
    }
  }

  // If it's a water year, continue adding months from January to September of the given year
  if (yearType === "collect_water_year") {
    for (let month = 1; month <= 9; month++) {
      const daysInMonth = new Date(year, month, 0).getDate(); // Get days in the month
      for (let day = 1; day <= daysInMonth; day++) {
        const monthStr = month.toString().padStart(2, "0");
        const dayStr = day.toString().padStart(2, "0");
        labels.push(`${year}-${monthStr}-${dayStr}`);
      }
    }
  }

  return labels;
};

const createDataset = (labels, data, axisUnits, chartType, color, axisID) => ({
  label: `${axisUnits} (${axisID.includes("1") ? "yL" : "yR"})`,
  type: ["line", "area"].includes(chartType) ? "line" : "bar",
  data: labels.map((label) => {
    const record = data.find((item) => item.collect_date === label);
    return record ? record[axisID] : null;
  }),
  borderColor: color,
  backgroundColor: hexToRGBA(color, 0.5),
  borderWidth: 2,
  yAxisID: axisID.includes("1") ? "yL" : "yR",
  fill: chartType === "area" ? "start" : false,
  pointRadius: 2,
  pointHoverRadius: 5,
  borderRadius: 5,
  pointStyle:
    chartType === "bar"
      ? "rectRounded"
      : chartType === "area"
      ? "rect"
      : "circle",
});

const determineLeftAxisID = (graph, yearType) => {
  if (
    GRAPHS_OPTIONS_WITH_LEFT_AXIS_ID_EXCEPTIONS.includes(graph.caf_group_ndx)
  ) {
    return yearType === "collect_year" ? "ax1_daily_calyr" : "ax1_daily_wtryr";
  }
  return "ax1_daily";
};

const transformGraphDataForChart = (
  data,
  graph,
  index,
  totalCharts,
  year,
  yearType
) => {
  const isLastChart = index === totalCharts - 1;
  const labels = generateYearLabels(year, yearType);

  const leftAxisID = determineLeftAxisID(graph, yearType);

  return {
    labels,
    datasets: [
      createDataset(
        labels,
        data,
        graph.y1_axis_units,
        graph.ax1_daily_chart_type,
        graph.ax1_daily_color,
        leftAxisID
      ),
      createDataset(
        labels,
        data,
        graph.y2_axis_units,
        graph.ax2_daily_chart_type,
        graph.ax2_daily_color,
        "ax2_daily"
      ),
    ],
    chartTitle: graph.caf_graph_title,
    yLLabel: graph.y1_axis_units,
    yRLabel: graph.y2_axis_units,
    xMin: labels[0],
    xMax: labels[labels.length - 1],
    isLastChart,
  };
};

export const useClimateAndFlowsExplorer = () => {
  const [inputState, setInputState] = useState(DEFAULT_INPUT_STATE);

  const currentCategory = useInitializeWithDataCategory();

  const handleInputState = useCallback((key, value) => {
    setInputState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const regionalGroupsQuery = useCustomQuery(
    ["regional-groups"],
    "climate-and-flows-explorer/inputs/regional-groups"
  );

  const yearsQuery = useCustomQuery(
    ["years"],
    "climate-and-flows-explorer/inputs/list-explore-years"
  );

  const graphOptionsQuery = useCustomQuery(
    ["caf-graph-options"],
    "climate-and-flows-explorer/inputs/caf-graph-options"
  );

  // Filter graphOptions based on selected data category
  useEffect(() => {
    if (graphOptionsQuery.data && currentCategory) {
      const defaultGraphOptions = graphOptionsQuery.data.filter((d) =>
        d.datacategory_ndx_defaults.includes(currentCategory.ndx)
      );
      handleInputState("graphs", defaultGraphOptions);
    }
  }, [graphOptionsQuery.data, currentCategory, handleInputState]);

  // Filter graphOptions based on selected regionalGroup
  const filteredGraphOptions = useMemo(() => {
    if (!graphOptionsQuery.data) return [];
    return graphOptionsQuery.data.filter((option) =>
      option.reg_group_ndx_display.includes(inputState.regionalGroup)
    );
  }, [graphOptionsQuery.data, inputState.regionalGroup]);

  // Clean inputState.graphs when inputState.regionalGroup changes
  useEffect(() => {
    const validGraphs = inputState.graphs.filter((graph) =>
      filteredGraphOptions.some(
        (option) => option.caf_group_ndx === graph.caf_group_ndx
      )
    );

    if (validGraphs.length !== inputState.graphs.length) {
      setInputState((prev) => ({ ...prev, graphs: validGraphs }));
    }
  }, [filteredGraphOptions, inputState.graphs, inputState.regionalGroup]);

  const graphQueries = useGraphQueries(inputState, BASE_URL);

  const { isInitialMount } = useInitializeWithQueryParams({
    graphOptionsQuery,
    regionalGroupsQuery,
    yearsQuery,
    currentCategory,
    setInputState,
  });

  // Update URL when inputState changes, but avoid running on the initial mount
  useEffect(() => {
    if (!isInitialMount.current) {
      const params = new URLSearchParams();

      Object.keys(inputState).forEach((key) => {
        if (key === "graphs" && inputState.graphs.length > 0) {
          const graphIds = inputState.graphs
            .map((graph) => graph.caf_group_ndx)
            .join(",");
          params.set(key, graphIds);
        } else if (inputState[key] !== undefined) {
          params.set(key, inputState[key]);
        }
      });

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      if (window.location.search !== `?${params.toString()}`) {
        window.history.replaceState(
          { ...window.history.state, url: newUrl },
          "",
          newUrl
        );
      }
    }
  }, [inputState, isInitialMount]);

  // Memoize chartData to process it once all queries have resolved
  const chartData = useMemo(() => {
    if (graphQueries.some((query) => query.isLoading || query.isError)) {
      return [];
    }

    const data = graphQueries.map((query, index) => {
      const graph = inputState.graphs[index];
      if (!query.data) {
        return { labels: [], datasets: [] };
      }
      return transformGraphDataForChart(
        query.data,
        graph,
        index,
        graphQueries.length,
        inputState.year,
        inputState.yearType
      );
    });

    return data;
  }, [graphQueries, inputState.graphs, inputState.year, inputState.yearType]);

  return {
    inputState,
    handleInputState,
    chartData,
    inputs: {
      regionalGroups: regionalGroupsQuery,
      years: yearsQuery,
      graphOptions: { ...graphOptionsQuery, data: filteredGraphOptions },
    },
  };
};

export default useClimateAndFlowsExplorer;
