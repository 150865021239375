import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Button, Box } from "@material-ui/core";
import Loader from "../../../components/Loader";
import {
  Eco,
  Opacity,
  LocalFlorist,
  Category,
  Rowing,
} from "@material-ui/icons";

const categoryIcons = {
  1: <Eco />, // Watershed Health
  2: <Opacity />, // Watershed Management
  3: <LocalFlorist />, // Agriculture & Land
  4: <Rowing />, // Recreation
};

const DefaultIcon = <Category />;

export const DataCategoryButtons = ({ options, isLoading }) => {
  const { control, setValue, watch } = useFormContext();
  const selectedDataCategory = watch("dataCategory");

  if (isLoading) {
    return <Loader />;
  }

  const handleSelect = (datacategory_ndx) => {
    const newValue =
      selectedDataCategory === datacategory_ndx ? null : datacategory_ndx;
    setValue("dataCategory", newValue);
  };

  return (
    <Controller
      control={control}
      name="dataCategory"
      render={() => (
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {options.map((category) => (
            <Box key={category.datacategory_ndx} p={1}>
              <Button
                startIcon={
                  categoryIcons[category.datacategory_ndx] || DefaultIcon
                }
                variant="contained"
                color={
                  selectedDataCategory === category.datacategory_ndx
                    ? "primary"
                    : "default"
                }
                onClick={() => handleSelect(category.datacategory_ndx)}
              >
                {category.datacategory_name}
              </Button>
            </Box>
          ))}
        </Box>
      )}
    />
  );
};
