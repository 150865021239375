import React from "react";
import { Box, Grid, Typography as MuiTypography } from "@material-ui/core";
import DemoCard from "./DemoCard";
import useStakeholderLandingPage from "../hooks/useStakeholderLandingPage";
import { useLocation } from "react-router-dom";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import Skeleton from "@material-ui/lab/Skeleton";
import StakeholderThemeProvider from "../../../theme/stakeholderTheme";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";

const Typography = styled(MuiTypography)(spacing);

const Hero = styled.div`
  background: url(${({ $backgroundImage }) => $backgroundImage}) center center /
    cover no-repeat;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  border-radius: 5px;
`;

const Welcome = styled.div`
  gap: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: left;
  padding: 64px;
  background-color: rgba(47, 44, 116, 0.3);
`;

const Icon = styled(Box)`
  width: 80px;
  height: 80px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(100%);
  }
`;

const ToolsCards = () => {
  const { pathname } = useLocation();

  const stakeholder = STAKEHOLDERS_CONFIG[pathname];

  const { data, error, isLoading } = useStakeholderLandingPage(stakeholder);

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  const {
    image:
      heroBackgroundImage = "/static/img/upper-yampa-water-conservancy-headewr-hero-02.jpg",
    splashTitle: heroTitle = "Data Visualization Tools",
    svgIcon:
      heroIcon = "/static/img/splash/svg/YampaRiverDashboard-DropIcon-Black.svg",
    splashTitle = "Water",
  } = stakeholder || {};

  return (
    <StakeholderThemeProvider>
      <Helmet
        title={heroTitle || "Upper Yampa Water Conservancy District Dashboard"}
      />

      <Hero $backgroundImage={heroBackgroundImage}>
        <Welcome>
          <Icon>
            <img src={heroIcon} alt={`${splashTitle} icon`} />
          </Icon>
          <Typography
            variant="h3"
            style={{
              color: "white",
              maxWidth: "250px",
              textTransform: "uppercase",
              letterSpacing: "3px",
            }}
          >
            {heroTitle}
          </Typography>
        </Welcome>
      </Hero>

      <Grid container spacing={6} justify="center">
        {isLoading
          ? Array.from(new Array(16)).map((_, i) => (
              <Grid key={i} item xs={12} md={6} lg={4} xl={3}>
                <Skeleton variant="text" height={64} />
                <Skeleton variant="rect" height="200px" />
              </Grid>
            ))
          : data.map((item, i) => <DemoCard key={item.key || i} {...item} />)}
      </Grid>
    </StakeholderThemeProvider>
  );
};

export default ToolsCards;
