import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import {
  Category,
  Eco,
  LocalFlorist,
  Opacity,
  Rowing,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const fetchListDataCategories = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/list-data-categories`
  );
  return data;
};

const fetchOtherResourcesLinks = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/data-other-resources-links`
  );
  return data.map(
    ({ resource_url, resource_text, agency_name, assoc_datacategory_ndx }) => ({
      url: resource_url,
      label: resource_text,
      agency: agency_name,
      datacategory_ndx: assoc_datacategory_ndx,
    })
  );
};

const useListDataCategories = () => {
  return useQuery("list-data-categories", fetchListDataCategories, {
    refetchOnWindowFocus: false,
  });
};

const useOtherResourcesLinks = () => {
  return useQuery("data-other-resources-links", fetchOtherResourcesLinks, {
    refetchOnWindowFocus: false,
  });
};

const categoryIcons = {
  1: <Eco />, // Watershed Health
  2: <Opacity />, // Watershed Management
  3: <LocalFlorist />, // Agriculture & Land
  4: <Rowing />, // Recreation
};

const DefaultIcon = <Category />;

const AdditionalResources = () => {
  const { datacategoryPath } = useParams();

  const currentCategory = useMemo(
    () =>
      Object.values(STAKEHOLDERS_CONFIG).find(
        (config) => datacategoryPath === config.datacategoryPath
      ),
    [datacategoryPath]
  );

  const defaultSelectedDatacategory = useMemo(
    () => (currentCategory ? [currentCategory.ndx] : []),
    [currentCategory]
  );

  const [selectedDatacategories, setSelectedDatacategories] = useState(
    defaultSelectedDatacategory
  );

  const { data: dataListDataCategories } = useListDataCategories();
  const { data, isLoading, error } = useOtherResourcesLinks();

  const handleSelect = (ndx) => {
    setSelectedDatacategories((prevSelected) =>
      prevSelected.includes(ndx)
        ? prevSelected.filter((category) => category !== ndx)
        : [...prevSelected, ndx]
    );
  };

  useEffect(() => {
    if (dataListDataCategories && !currentCategory) {
      const allCategoryNdx = dataListDataCategories.map(
        (category) => category.datacategory_ndx
      );
      setSelectedDatacategories(allCategoryNdx);
    }
  }, [dataListDataCategories, currentCategory]);

  const filteredData = useMemo(
    () =>
      data?.filter((item) =>
        item.datacategory_ndx.some((ndx) =>
          selectedDatacategories.includes(ndx)
        )
      ) || [],
    [data, selectedDatacategories]
  );

  const agencyFilterOptions = useMemo(
    () =>
      [...new Set(data?.map((item) => item.agency) || [])].reduce(
        (acc, agency) => {
          acc[agency] = agency;
          return acc;
        },
        {}
      ),
    [data]
  );

  return (
    <>
      <Helmet title="Additional Resources" />
      <Typography variant="h3" gutterBottom display="inline">
        Additional Resources
      </Typography>

      <Divider my={6} />
      <Box display="flex" flexWrap="wrap" justifyContent="center" mb={4}>
        {dataListDataCategories?.map(
          ({ datacategory_ndx, datacategory_name }) => (
            <Box key={datacategory_ndx} p={1}>
              <Button
                startIcon={categoryIcons[datacategory_ndx] || DefaultIcon}
                variant="contained"
                color={
                  selectedDatacategories.includes(datacategory_ndx)
                    ? "primary"
                    : "default"
                }
                onClick={() => handleSelect(datacategory_ndx)}
              >
                {datacategory_name}
              </Button>
            </Box>
          )
        )}
      </Box>

      <Grid container justify="center">
        <Grid item xs={12} lg={8} xl={6}>
          <MaterialTable
            columns={[
              {
                title: "Resource",
                field: "label",
                render: ({ url, label }) => (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {label}
                  </a>
                ),
              },
              {
                title: "Agency",
                field: "agency",
                lookup: agencyFilterOptions,
              },
            ]}
            data={filteredData}
            isLoading={isLoading}
            options={{
              filtering: true,
              paging: false,
              search: false,
              toolbar: false,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: error
                  ? "Error loading data"
                  : "No records to display",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalResources;
