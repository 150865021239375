import React from "react";
import {
  Toolbar,
  Button,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@material-ui/core";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Build } from "@material-ui/icons";

const StyledToolbar = styled(Toolbar)`
  background-color: #fff;
  padding: 0 16px 0 0;
  margin-left: auto;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #000;
  margin: 0 16px;
`;

const StakeholderButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) => prop !== "isActive",
})`
  padding: 6px;
  margin: 5px;

  & img {
    width: 36px;
    height: 36px;
  }
`;

export const DesktopMenu = () => (
  <Box display="flex" alignItems="center">
    <StyledButton color="secondary" component={Link} to="/dashboard">
      All Tools
    </StyledButton>
    <Divider />
    {Object.keys(STAKEHOLDERS_CONFIG).map((key) => (
      <StyledButton
        color="secondary"
        key={key}
        component={Link}
        to={STAKEHOLDERS_CONFIG[key].path}
      >
        {STAKEHOLDERS_CONFIG[key].splashTitle}
      </StyledButton>
    ))}
  </Box>
);

const MobileMenu = () => {
  return (
    <>
      <Tooltip title="All Tools">
        <StakeholderButton
          aria-label="All Tools"
          component={Link}
          to="/dashboard"
        >
          <Build style={{ height: "36px", width: "36px" }} />
        </StakeholderButton>
      </Tooltip>
      <Divider />
      {Object.keys(STAKEHOLDERS_CONFIG).map((key) => (
        <Tooltip title={STAKEHOLDERS_CONFIG[key].splashTitle} key={key}>
          <StakeholderButton
            aria-label={STAKEHOLDERS_CONFIG[key].splashTitle}
            component={Link}
            to={STAKEHOLDERS_CONFIG[key].path}
          >
            <img
              src={STAKEHOLDERS_CONFIG[key].svgIcon}
              alt={`${STAKEHOLDERS_CONFIG[key].splashTitle} icon`}
            />
          </StakeholderButton>
        </Tooltip>
      ))}
    </>
  );
};

const StakeholderLinksAppBar = () => {
  const theme = useTheme();
  const isMedScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledToolbar>
      {isMedScreen ? <MobileMenu /> : <DesktopMenu />}
    </StyledToolbar>
  );
};

export default StakeholderLinksAppBar;
