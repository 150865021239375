import styled from "styled-components";
import React, { memo, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Alert } from "@material-ui/lab";
import { AcUnit, Grain, Opacity, Waves, WbSunny } from "@material-ui/icons";
import { Typography } from "@material-ui/core";

const Container = styled("div")`
  display: flex;
  width: 100%;
  gap: 20px;
  height: 350px;
`;

const CardsContainer = styled("div")`
  height: 100%;
  overflow: auto;
  width: 300px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white;
  ${({ $isDraggingOver }) => $isDraggingOver && `background-color: #e6ffe6;`}
`;

const Card = styled("div")`
  width: 100%;
  border: ${({ $borderWidth }) => `${$borderWidth || 1}px solid lightgrey`};
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${({ $isDragging, $disabled }) =>
    $isDragging ? "#cce6ff" : $disabled ? "#f0f0f0" : "white"};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "default")};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectionContainerWrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SelectionContainer = styled("div")`
  height: 100%;
  overflow: auto;
  padding: 10px;
  border: 1px solid
    ${({ $hasRequiredSelections }) =>
      $hasRequiredSelections ? "green" : "red"};
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ $isDraggingOver }) => $isDraggingOver && `background-color: #e6ffe6;`}
`;

const PlaceholderCard = styled("div")`
  border: 1px dashed lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgrey;
`;

const AlertStyled = styled(Alert)`
  margin-top: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled("div")`
  text-align: center;
  margin: 0;
  padding: 5px;
  width: ${({ $width }) => $width || "auto"};
`;

const TitlePart = styled(Typography)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid ${({ $color }) => $color || "transparent"};
  background-color: ${({ $color }) => ($color ? `${$color}33` : "transparent")};
`;

const categoriesConfig = {
  Streamflow: { color: "#1E90FF", icon: <Waves /> },
  Rainfall: { color: "#00BFFF", icon: <Grain /> },
  Snow: { color: "#ADD8E6", icon: <AcUnit /> },
  Moisture: { color: "#8FBC8F", icon: <Opacity /> },
  Temperature: { color: "#FF4500", icon: <WbSunny /> },
};

const getStyleAndIcon = (text) => {
  const word = Object.keys(categoriesConfig).find((key) => text.includes(key));
  return categoriesConfig[word] || {};
};

const Title = ({ title, id, width }) => {
  const text = getStyleAndIcon(title);

  return (
    <TitleContainer $width={width}>
      'Graph: ' {id}
      <TitlePart $color={text.color}>
        {text.icon && (
          <span style={{ marginRight: "8px", display: "flex" }}>
            {text.icon}
          </span>
        )}
        {title}
        <span style={{ width: "32px" }} />
      </TitlePart>
    </TitleContainer>
  );
};

const DragAndDropSelector = ({
  options,
  selections,
  maxSelections,
  onSelectionChange,
  idField = "id",
  titleField = "title",
}) => {
  const [cards, setCards] = useState(options);
  const [isDraggingFromLeft, setIsDraggingFromLeft] = useState(false);
  const [isDraggingFromRight, setIsDraggingFromRight] = useState(false);

  useEffect(() => {
    const filteredCards = options.filter(
      (item) =>
        !selections.some((selected) => selected[idField] === item[idField])
    );
    setCards(filteredCards);
  }, [options, selections, idField]);

  const getSelectionMessage = () => {
    const count = maxSelections - selections.length;
    if (count > 0) {
      return `Select ${count} more graph${count > 1 ? "s" : ""} to proceed.`;
    }
    return "Selection complete!";
  };

  const handleDragStart = ({ source }) => {
    if (source.droppableId === "cards") {
      setIsDraggingFromLeft(true);
    } else if (source.droppableId === "selections") {
      setIsDraggingFromRight(true);
    }
  };

  const handleDragEnd = ({ source, destination }) => {
    setIsDraggingFromLeft(false);
    setIsDraggingFromRight(false);

    if (!destination) return;

    if (
      source.droppableId === "cards" &&
      destination.droppableId === "selections"
    ) {
      if (selections.length < maxSelections) {
        const selectedCard = cards[source.index];
        const newSelections = [...selections];
        newSelections.splice(destination.index, 0, selectedCard);
        const newCards = cards.filter(
          (card) => card[idField] !== selectedCard[idField]
        );
        setCards(newCards);
        onSelectionChange(newSelections);
      }
    } else if (
      source.droppableId === "selections" &&
      destination.droppableId === "selections"
    ) {
      const newSelections = [...selections];
      const [moved] = newSelections.splice(source.index, 1);
      newSelections.splice(destination.index, 0, moved);
      onSelectionChange(newSelections);
    } else if (
      source.droppableId === "selections" &&
      destination.droppableId === "cards"
    ) {
      const selectedCard = selections[source.index];
      const newCards = [...cards, selectedCard].sort(
        (a, b) =>
          options.findIndex((card) => card[idField] === a[idField]) -
          options.findIndex((card) => card[idField] === b[idField])
      );
      const newSelections = selections.filter(
        (card) => card[idField] !== selectedCard[idField]
      );
      setCards(newCards);
      onSelectionChange(newSelections);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Container>
        <Droppable droppableId="cards" isDropDisabled={isDraggingFromLeft}>
          {(provided, snapshot) => (
            <CardsContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
              $isDraggingOver={snapshot.isDraggingOver}
            >
              {cards.map((card, index) => (
                <Draggable
                  key={card[idField]}
                  draggableId={card[idField].toString()}
                  index={index}
                  isDragDisabled={selections.length === maxSelections}
                >
                  {(provided, snapshot) => (
                    <Card
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      $disabled={
                        selections.length === maxSelections &&
                        !isDraggingFromRight
                      }
                      $isDragging={snapshot.isDragging}
                    >
                      <Title
                        title={card[titleField]}
                        id={card[idField]}
                        width="100%"
                      />
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </CardsContainer>
          )}
        </Droppable>

        <SelectionContainerWrapper>
          <Droppable droppableId="selections">
            {(provided, snapshot) => (
              <SelectionContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
                $hasRequiredSelections={selections.length === maxSelections}
                $isDraggingOver={snapshot.isDraggingOver}
              >
                {selections.map((selection, index) => (
                  <Draggable
                    key={selection[idField]}
                    draggableId={selection[idField].toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        $isDragging={snapshot.isDragging}
                        $borderWidth={3}
                      >
                        <Title
                          title={selection[titleField]}
                          id={selection[idField]}
                          width="300px"
                        />
                      </Card>
                    )}
                  </Draggable>
                ))}

                {Array.from({ length: maxSelections - selections.length }).map(
                  (_, index) => (
                    <PlaceholderCard key={index}>Empty</PlaceholderCard>
                  )
                )}
                <AlertStyled
                  severity={
                    selections.length === 0
                      ? "info"
                      : selections.length === maxSelections
                      ? "success"
                      : "warning"
                  }
                >
                  {getSelectionMessage()}
                </AlertStyled>
                {provided.placeholder}
              </SelectionContainer>
            )}
          </Droppable>
        </SelectionContainerWrapper>
      </Container>
    </DragDropContext>
  );
};

export default memo(DragAndDropSelector);
