import React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem,
  Box,
  Toolbar,
  IconButton,
  Typography as MuiTypography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Link as MuiLink } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ROUTES, STAKEHOLDERS_CONFIG } from "../../../constants";
import { spacing } from "@material-ui/system";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@material-ui/core/styles";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled(Toolbar)`
  padding: 16px 32px;
  background-color: #232927;
  position: relative;
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: white;
  }
`;

export const StakeholderButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) => prop !== "isActive",
})`
  padding: 6px;
  margin: 5px;
  background-color: ${(props) =>
    props.isActive ? props.theme.palette.primary.main : "initial"};
  &:hover {
    background-color: ${(props) =>
      props.isActive ? props.theme.palette.primary.dark : "#323232"};
  }

  & img {
    width: 36px;
    height: 36px;
    filter: invert(100%); // This makes the SVG white
  }
`;

const Title = styled(Typography)`
  flex-grow: 1;
  color: white;
  text-transform: uppercase;
  letter-spacing: 4px;
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
`;

export function Footer() {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const pathName = window.location.pathname;

  const handleLogin = () => {
    loginWithRedirect({
      appState: { returnTo: ROUTES.PAGE_DASHBOARD },
    }).then();
  };

  return (
    <Wrapper color="primary">
      <Grid
        container
        display="flex"
        alignItems="center"
        justify="space-between"
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {isLoading ||
            (!isAuthenticated && (
              <StyledButton
                onClick={handleLogin}
                variant="contained"
                size={isSmallScreen ? "medium" : "large"}
                color="secondary"
              >
                Admin Login
              </StyledButton>
            ))}
        </Grid>
        <Hidden smDown>
          <Grid item>
            <List>
              <ListItem>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginRight="auto"
                >
                  <Title variant="body1" mb="5px">
                    Yampa River
                  </Title>
                  <Title variant="body1">
                    <strong> Dashboard</strong>
                  </Title>
                </Box>
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          {Object.keys(STAKEHOLDERS_CONFIG).map((key) => (
            <Tooltip title={STAKEHOLDERS_CONFIG[key].splashTitle} key={key}>
              <StakeholderButton
                isActive={pathName.includes(
                  STAKEHOLDERS_CONFIG[key].datacategoryPath
                )}
                aria-label={STAKEHOLDERS_CONFIG[key].splashTitle}
                component={Link}
                to={STAKEHOLDERS_CONFIG[key].path}
              >
                <img
                  src={STAKEHOLDERS_CONFIG[key].svgIcon}
                  alt={`${STAKEHOLDERS_CONFIG[key].splashTitle} icon`}
                />
              </StakeholderButton>
            </Tooltip>
          ))}
        </Grid>
        <Hidden smDown>
          <Grid item>
            <List>
              <ListItem>
                <MuiLink
                  href="https://lrewater.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ListItemText
                    primary={`© ${new Date().getFullYear()} - LRE Water`}
                  />
                </MuiLink>
              </ListItem>
            </List>
          </Grid>
        </Hidden>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
