import React, { useState } from "react";
import { useQueryAndDownload } from "./useQueryAndDownload";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Loader from "../../../components/Loader";
import { FormProvider } from "react-hook-form";
import { DateTime } from "../../../components/inputs/Dates";
import { SimpleSelect } from "../../../components/inputs/Selects";
import { DataCategoryButtons } from "./DataCategoryButtons";
import { ChipSelect } from "../../../components/inputs/Chips";
import QueryAndDownloadMap from "../../../components/map/QueryAndDownloadMap";
import { Map as MapIcon } from "react-feather";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);

const ActionButton = styled(Button)`
  height: 52px;
`;

const CountValue = styled.span`
  font-weight: bold;
  font-size: 2rem;
`;

const OptionsHeader = ({
  text,
  color = "secondary",
  variant = "subtitle1",
  ...rest
}) => (
  <Typography color={color} variant={variant} {...rest}>
    {text}
  </Typography>
);

const FormSection = ({ children, title }) => (
  <>
    <Grid item xs={12}>
      <OptionsHeader text={title} />
    </Grid>
    {children}
  </>
);

const QueryAndDownload = () => {
  const [isMapVisible, setIsMapVisible] = useState(false);
  const {
    formMethods,
    onSubmit,
    isLoading,
    error,
    inputs,
    currentCount,
    isLoadingCurrentCount,
    mapLocations,
  } = useQueryAndDownload();

  const toggleMapVisibility = () => {
    setIsMapVisible(!isMapVisible);
  };

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Divider my={6} />

      <Paper p={4}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormProvider {...formMethods}>
            <Grid container spacing={3}>
              <FormSection title="Time Period">
                <Grid item xs={12} sm={4}>
                  <DateTime name="startDate" label="Start Date" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateTime name="endDate" label="End Date" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SimpleSelect
                    name="timestep"
                    label="Time Step"
                    valueField="ts_endpoint"
                    displayField="ts_desc"
                    options={inputs.timesteps.data}
                    isLoading={inputs.timesteps.isLoading}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Quick-Filters">
                <Grid item xs={12}>
                  <DataCategoryButtons
                    options={inputs.dataCategories.data}
                    isLoading={inputs.dataCategories.isLoading}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Filter Groups">
                <Grid item xs={12}>
                  <SimpleSelect
                    name="basins"
                    label="Basins"
                    valueField="basin_ndx"
                    displayField="basin_name"
                    options={inputs.basins.data}
                    isLoading={inputs.basins.isLoading}
                    multiple
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SimpleSelect
                    name="dataSources"
                    label="Data Sources"
                    valueField="sourcecategory_ndx"
                    displayField="source_name"
                    options={inputs.dataSources.data}
                    isLoading={inputs.dataSources.isLoading}
                    multiple
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SimpleSelect
                    name="organizations"
                    label="Organizations"
                    valueField="organization_ndx"
                    displayField="org_id"
                    options={inputs.organizations.data}
                    isLoading={inputs.organizations.isLoading}
                    multiple
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SimpleSelect
                    name="locationTypes"
                    label="Location Types"
                    valueField="location_type_ndx"
                    displayField="location_type_desc"
                    options={inputs.locationTypes.data}
                    isLoading={inputs.locationTypes.isLoading}
                    multiple
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SimpleSelect
                    name="parameterGroups"
                    label="Parameter Groups"
                    valueField="parameter_group_ndx"
                    displayField="parameter_group_name"
                    options={inputs.parameterGroups.data}
                    isLoading={inputs.parameterGroups.isLoading}
                    multiple
                  />
                </Grid>
              </FormSection>

              <FormSection title="Locations">
                <Grid item xs={12}>
                  <Button
                    startIcon={<MapIcon />}
                    variant="contained"
                    size="large"
                    color={isMapVisible ? "primary" : "default"}
                    onClick={toggleMapVisibility}
                  >
                    {isMapVisible
                      ? "Hide Locations & Basins Map"
                      : "Show Locations & Basins Map"}
                  </Button>
                  <QueryAndDownloadMap
                    mapLocations={mapLocations}
                    isMapVisible={isMapVisible}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ChipSelect
                    name="locations"
                    label="Locations"
                    valueField="location_ndx"
                    displayField="location_id"
                    tooltipField="location_name"
                    isLoading={inputs.locations.isLoading}
                    options={inputs.locations.data}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Parameters">
                <Grid item xs={12}>
                  <ChipSelect
                    name="parameters"
                    label="Parameters"
                    valueField="parameter_ndx"
                    displayField="display_abbrev"
                    tooltipField="display_name"
                    isLoading={inputs.parameters.isLoading}
                    options={inputs.parameters.data}
                  />
                </Grid>
              </FormSection>

              <FormSection title="Available Records">
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    {isLoadingCurrentCount && <Loader />}
                    <Typography>
                      {currentCount !== null ? (
                        <>
                          <CountValue>
                            {!isLoadingCurrentCount && currentCount}
                          </CountValue>{" "}
                          records available for download
                        </>
                      ) : (
                        "Please fill out the form to see available records"
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={3} mt={2} alignItems="center">
                  <Grid item xs>
                    <ActionButton
                      fullWidth
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={
                        formMethods.formState.isSubmitting ||
                        !formMethods.formState.isDirty ||
                        isLoading
                      }
                      startIcon={
                        formMethods.formState.isSubmitting || isLoading ? (
                          <Loader color="inherit" />
                        ) : null
                      }
                    >
                      {formMethods.formState.isSubmitting || isLoading
                        ? "Preparing..."
                        : "Download"}
                    </ActionButton>
                    {error && <p>Error downloading file.</p>}
                  </Grid>
                  <Grid item>
                    <ActionButton
                      onClick={() => formMethods.reset()}
                      color="secondary"
                      variant="outlined"
                    >
                      Reset
                    </ActionButton>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <p>Error downloading file.</p>
                    </Grid>
                  )}
                </Grid>
              </FormSection>
            </Grid>
          </FormProvider>
        </form>
      </Paper>
    </>
  );
};

export default QueryAndDownload;
