import {
  subMonths,
  subWeeks,
  subYears,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import {
  currentWaterYearEndDate,
  currentWaterYearStartDate,
  endOfWaterYear,
  startOfWaterYear,
} from "./utils";

export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const REQUIRED_NUMBER_OF_GRAPH_SELECTIONS = 2;

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api/time-series-summaries`;

export const DEFAULT_START_DATE = startOfMonth(subMonths(new Date(), 1));

export const DEFAULT_END_DATE = new Date();

export const DEFAULT_INPUT_STATE = {
  regionalGroup: 4, //Steamboat Springs
  groupType: "Month",
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
  graphs: [],
};

export const GROUP_TYPES = ["Year", "Month", "Week"];

export const DATE_RANGE_BUTTONS = {
  Year: [
    {
      header: "Calendar Year",
      buttons: [
        {
          label: "Current Year",
          startDate: () => startOfYear(new Date()),
          endDate: () => endOfYear(new Date()),
        },
        {
          label: "Previous Year",
          startDate: () => startOfYear(subYears(new Date(), 1)),
          endDate: () => endOfYear(subYears(new Date(), 1)),
        },
        {
          label: "Previous 2 Years",
          startDate: () => startOfYear(subYears(new Date(), 2)),
          endDate: () => endOfYear(subYears(new Date(), 1)),
        },
        {
          label: "Previous 3 Years",
          startDate: () => startOfYear(subYears(new Date(), 3)),
          endDate: () => endOfYear(subYears(new Date(), 1)),
        },
        {
          label: "Previous 5 Years",
          startDate: () => startOfYear(subYears(new Date(), 5)),
          endDate: () => endOfYear(subYears(new Date(), 1)),
        },
        {
          label: "Previous 10 Years",
          startDate: () => startOfYear(subYears(new Date(), 10)),
          endDate: () => endOfYear(subYears(new Date(), 1)),
        },
      ],
    },
    {
      header: "Water Year",
      buttons: [
        {
          label: "Current Water Year",
          startDate: () => currentWaterYearStartDate(),
          endDate: () => currentWaterYearEndDate(),
        },
        {
          label: "Previous Water Year",
          startDate: () => startOfWaterYear(new Date().getFullYear() - 1),
          endDate: () => endOfWaterYear(new Date().getFullYear() - 1),
        },
        {
          label: "Previous 2 Water Years",
          startDate: () => startOfWaterYear(new Date().getFullYear() - 2),
          endDate: () => endOfWaterYear(new Date().getFullYear() - 1),
        },
        {
          label: "Previous 3 Water Years",
          startDate: () => startOfWaterYear(new Date().getFullYear() - 3),
          endDate: () => endOfWaterYear(new Date().getFullYear() - 1),
        },
        {
          label: "Previous 5 Water Years",
          startDate: () => startOfWaterYear(new Date().getFullYear() - 5),
          endDate: () => endOfWaterYear(new Date().getFullYear() - 1),
        },
        {
          label: "Previous 10 Water Years",
          startDate: () => startOfWaterYear(new Date().getFullYear() - 10),
          endDate: () => endOfWaterYear(new Date().getFullYear() - 1),
        },
      ],
    },
  ],
  Month: [
    {
      buttons: [
        {
          label: "Current Month",
          startDate: () => startOfMonth(new Date()),
          endDate: () => endOfMonth(new Date()),
        },
        {
          label: "Previous Month",
          startDate: () => startOfMonth(subMonths(new Date(), 1)),
          endDate: () => endOfMonth(subMonths(new Date(), 1)),
        },
        {
          label: "Previous 2 Months",
          startDate: () => startOfMonth(subMonths(new Date(), 2)),
          endDate: () => endOfMonth(subMonths(new Date(), 1)),
        },
        {
          label: "Previous 3 Months",
          startDate: () => startOfMonth(subMonths(new Date(), 3)),
          endDate: () => endOfMonth(subMonths(new Date(), 1)),
        },
        {
          label: "Previous 6 Months",
          startDate: () => startOfMonth(subMonths(new Date(), 6)),
          endDate: () => endOfMonth(subMonths(new Date(), 1)),
        },
        {
          label: "Previous 12 Months",
          startDate: () => startOfMonth(subMonths(new Date(), 12)),
          endDate: () => endOfMonth(subMonths(new Date(), 1)),
        },
      ],
    },
  ],
  Week: [
    {
      buttons: [
        {
          label: "Current Week",
          startDate: () => startOfWeek(new Date()),
          endDate: () => endOfWeek(new Date()),
        },
        {
          label: "Previous Week",
          startDate: () => startOfWeek(subWeeks(new Date(), 1)),
          endDate: () => endOfWeek(subWeeks(new Date(), 1)),
        },
        {
          label: "Previous 2 Weeks",
          startDate: () => startOfWeek(subWeeks(new Date(), 2)),
          endDate: () => endOfWeek(subWeeks(new Date(), 1)),
        },
        {
          label: "Previous 4 Weeks",
          startDate: () => startOfWeek(subWeeks(new Date(), 4)),
          endDate: () => endOfWeek(subWeeks(new Date(), 1)),
        },
        {
          label: "Previous 8 Weeks",
          startDate: () => startOfWeek(subWeeks(new Date(), 8)),
          endDate: () => endOfWeek(subWeeks(new Date(), 1)),
        },
        {
          label: "Previous 12 Weeks",
          startDate: () => startOfWeek(subWeeks(new Date(), 12)),
          endDate: () => endOfWeek(subWeeks(new Date(), 1)),
        },
      ],
    },
  ],
};
