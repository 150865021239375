import { addDays } from "date-fns";

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api`;

export const ENDPOINT = "colors-of-water-elkhead";

export const DEFAULT_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const TIME_INTERVAL_OPTIONS = [
  {
    label: "Daily Data",
    value: "daily",
  },
];

export const DEFAULT_TIME_INTERVAL = TIME_INTERVAL_OPTIONS[0].value;

const START_DAYS_AGO = -90;

export const DEFAULT_START_DATE = addDays(new Date(), START_DAYS_AGO);

export const DEFAULT_END_DATE = new Date();

export const DATE_FORMAT = "MMM dd yyyy, hh:mm a";

export const TIMESERIES_AXIS_DATE_FORMAT = "MM/DD/yyyy";

const FIRST_OF_YEAR = new Date(new Date().getFullYear(), 0, 1);

export const QUICKSET_OPTIONS = [
  {
    label: "Year to Date",
    calculateStartDate: () => FIRST_OF_YEAR,
  },
  {
    label: "Last 7 Days",
    calculateStartDate: () => addDays(new Date(), -7),
  },
  {
    label: "Last 30 Days",
    calculateStartDate: () => addDays(new Date(), -30),
  },
  {
    label: "Last 90 Days",
    calculateStartDate: () => addDays(new Date(), -90),
  },
  {
    label: "Last 180 Days",
    calculateStartDate: () => addDays(new Date(), -180),
  },
  {
    label: "Last 365 Days",
    calculateStartDate: () => addDays(new Date(), -365),
  },
];
