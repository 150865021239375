import React from "react";

import { Helmet } from "react-helmet-async";
import { useYamcoloOperations } from "./useYamcoloOperations";
import { Grid } from "@material-ui/core";
import PercentFull from "./PercentFull";
import TemperatureMinimums from "./TemperatureMinimums";
import BatteryMinimums from "./BatteryMinimums";
import WidgetCard from "./WidgetCard";
import TableCard from "./TableCard";
import TimeseriesLineChartResops from "../../../components/graphs/TimeseriesLineChartResops";
import Button from "@material-ui/core/Button";
import {
  Divider,
  StyledList,
  StyledListItem,
  Typography,
} from "./styledComponents";

function YamcoloOperations() {
  const { data } = useYamcoloOperations();
  const waterElevation = data?.waterElevation?.data?.[0] ?? null;
  const temp = data?.temp?.data?.[0] ?? null;
  const batteries = data?.battery?.data ?? [];
  const stats = data?.stats?.data ?? [];
  const graph = data?.graph ?? null;

  return (
    <React.Fragment>
      <Helmet title="Yamcolo Reservoir Dashboard" />
      <Typography variant="h3" gutterBottom display="inline">
        Yamcolo Reservoir Dashboard
      </Typography>
      <Divider my={6} />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={4}>
          <WidgetCard
            title="Reservoir % Full"
            height="400px"
            lastReport={waterElevation?.last_report}
            isLoading={data?.waterElevation?.isLoading}
          >
            <PercentFull waterElevation={waterElevation} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <WidgetCard
            title="Reservoir Sensor Temperature"
            height="400px"
            lastReport={temp?.last_report ?? null}
            isLoading={data?.temp?.isLoading}
          >
            <TemperatureMinimums temp={temp} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <WidgetCard
            title="Battery 24-hour Minimums"
            height="483px"
            cardContentStyle={{
              flexDirection: "column",
              justifyContent: "space-around",
            }}
            isLoading={data?.battery?.isLoading}
          >
            {batteries.map((battery, i) => (
              <BatteryMinimums battery={battery} key={i} />
            ))}
          </WidgetCard>
        </Grid>

        <Grid item xs={12}>
          <WidgetCard title="Notes" height="100%">
            <StyledList>
              <StyledListItem>
                <Typography variant="body1">
                  24-hour statistics are calculated from 5am to 5am
                </Typography>
              </StyledListItem>
            </StyledList>
          </WidgetCard>
        </Grid>

        {stats?.length > 0 &&
          stats.map((stat, i) => (
            <Grid item xs={12} lg={6} key={i}>
              <WidgetCard
                title={stat.title}
                height="100%"
                isLoading={data?.stat?.isLoading}
              >
                <div>
                  <TableCard tableData={stat} />
                  {data.stats.tableSubtitles[stat?.title] &&
                    data.stats.tableSubtitles[stat?.title]}
                </div>
              </WidgetCard>
            </Grid>
          ))}

        <Grid item xs={12}>
          <WidgetCard
            title="15-Minute Water Temperatures (def F), Last 7 Days"
            height="500px"
            cardContentStyle={{
              justifyContent: "center",
            }}
            isLoading={data?.stat?.isLoading}
          >
            <TimeseriesLineChartResops
              data={graph.data}
              reverseLegend={false}
              error={graph.error}
              yLLabel="Deg F"
              xLabelFormat="MMM DD YYYY"
              ref={graph.ref}
              suggestedMin={30}
              customLegendOrder={[
                "Reservoir",
                "River",
                "Ditch",
                "Heater 1 OFF",
                "Heater 2 OFF",
                "Heater 1 ON",
                "Heater 2 ON",
                "32deg",
              ]}
            />
          </WidgetCard>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            style={{
              display: "block",
              borderWidth: "4px",
              textAlign: "center",
            }}
            component="a"
            href="http://166.193.37.177/usr/#canvas_container"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="h5" style={{ color: "blue" }}>
              <strong>
                LINK - Hydrologik Web Displays, Controls and Piezometers
              </strong>
            </Typography>
            <Typography variant="subtitle1" style={{ color: "blue" }}>
              (additional login required)
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default YamcoloOperations;
