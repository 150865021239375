import React, { memo, useMemo } from "react";
import { FormControl } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { endOfYear } from "date-fns";

const shouldDisableStartDate = (date) => {
  // Disable all days except Sundays (0)
  return date.getDay() !== 0;
};

const shouldDisableEndDate = (date) => {
  // Disable all days except Saturdays (6)
  return date.getDay() !== 6;
};

const DateControl = ({
  id,
  label,
  value,
  onChange,
  groupType,
  minDate = endOfYear(new Date(new Date().getFullYear(), 0)),
  maxDate = endOfYear(new Date()),
  format = "MMM dd yyyy",
}) => {
  const { views, shouldDisableDate } = useMemo(() => {
    let viewsArray = ["year"];
    let disableFunction = undefined;

    if (groupType === "Month") {
      viewsArray = ["year", "month"];
    } else if (groupType === "Week") {
      viewsArray = ["year", "month", "date"];
      disableFunction =
        id === "start-date" ? shouldDisableStartDate : shouldDisableEndDate;
    }

    return { views: viewsArray, shouldDisableDate: disableFunction };
  }, [groupType, id]);

  return (
    <FormControl margin="normal" fullWidth>
      <DatePicker
        id={id}
        inputVariant="outlined"
        openTo={views[0]}
        views={views}
        label={label}
        value={value}
        onChange={onChange}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate}
      />
    </FormControl>
  );
};

export default memo(DateControl);
