import React, { forwardRef } from "react";
import { withTheme } from "styled-components/macro";

import { Chart, Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import { Typography } from "@material-ui/core";
import { lineColors } from "../../../utils";
import Loader from "../../../components/Loader";

Chart.register(zoomPlugin);

const TimeseriesLineChart = forwardRef(
  (
    {
      data,
      title = null,
      subtitle = null,
      error,
      isLoading = true,
      locationsOptions = [true],
      yLLabel = null,
      yLReverse = false,
      reverseLegend = true,
      type = "line",
      theme,
      minL = null,
      maxL = null,
      minR = null,
      maxR = null,
      interactionMode = "index",
    },
    ref
  ) => {
    const plugins = [
      {
        id: "chartFillBackground",
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.fillStyle = lineColors.lightGray;
          ctx.fillRect(0, 0, chart.width, chart.height);
        },
      },
      {
        id: "chartAreaBorder",
        beforeDraw(chart) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          if (chart.options.plugins.zoom.zoom.wheel.enabled) {
            ctx.save();
            ctx.strokeStyle = "#800000";
            ctx.lineWidth = 3;
            ctx.strokeRect(left, top, width, height);
            ctx.restore();
          }
        },
      },
      {
        id: "annotatedVerticalLine",
        afterDraw(chart) {
          if (chart.config.type === "line" && chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0].element.x;
            let yAxis = chart.scales.yL;
            let ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 9;
            ctx.strokeStyle = "rgba(0, 0, 255, 0.2)";
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
            ctx.stroke();
            ctx.restore();
          }
        },
      },
    ];

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: interactionMode,
      },
      plugins: {
        title: {
          display: !!title,
          text: title,
        },
        subtitle: {
          display: !!subtitle,
          text: subtitle,
        },
        filler: {
          propagate: false,
        },
        tooltip: {
          reverse: reverseLegend,
          callbacks: {
            label: function (tooltipItems) {
              return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue} ${tooltipItems.dataset.units}`;
            },
          },
          footerAlign: "center",
        },
        legend: {
          display: true,
          reverse: reverseLegend,
          labels: {
            usePointStyle: false,
            color: lineColors.darkGray,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy",
          },
          zoom: {
            mode: "xy",
            overScaleMode: "y",
            wheel: {
              enabled: false,
            },
            pinch: {
              enabled: true,
            },
          },
        },
      },

      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: lineColors.darkGray,
            maxTicksLimit: 9,
          },
        },

        yL: {
          reverse: yLReverse,
          min: minL,
          max: maxL,
          position: "left",
          display: !!yLLabel,
          title: {
            display: true,
            text: yLLabel,
            color: lineColors.darkGray,
          },
          ticks: {
            color: lineColors.darkGray,
          },
          grid: {
            color: theme.palette.text.gridLines,
            borderDash: [5, 5],
            drawBorder: true,
            drawTicks: true,
          },
        },
      },
      onClick(e) {
        const chart = e.chart;
        chart.options.plugins.zoom.zoom.wheel.enabled =
          !chart.options.plugins.zoom.zoom.wheel.enabled;
        chart.update();
      },
    };

    if (error) return "An error has occurred: " + error.message;
    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {data?.datasets?.length > 0 && locationsOptions?.length > 0 ? (
              type === "line" && (
                <Line
                  plugins={plugins}
                  ref={ref}
                  data={data}
                  options={options}
                  type={type}
                />
              )
            ) : (
              <Typography>No Data Available</Typography>
            )}
          </>
        )}
      </>
    );
  }
);
export default withTheme(TimeseriesLineChart);
