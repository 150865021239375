import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { groupByValueArray } from "../../../utils";
import { Typography } from "@material-ui/core";

export const useStagecoachOperations = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: dataWaterElevation,
    isFetching: isFetchingWaterElevation,
    error: errorWaterElevation,
    refetch: refetchWaterElevation,
  } = useQuery(
    ["dashboard-stagecoach-widgets/water-elevation"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-stagecoach-widgets/water-elevation`,
          {
            location_ndx: [655],
            parameter_ndx: [1539],
          },
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataTemp,
    isFetching: isFetchingTemp,
    error: errorTemp,
    refetch: refetchTemp,
  } = useQuery(
    ["dashboard-stagecoach-widgets/temp"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-stagecoach-widgets/temp`,
          {
            location_ndx: [656],
            parameter_ndx: [169],
          },
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const tempSubtitle = (
    <Typography>
      Outlet Temperature Limits: Jul - Sep Max: 60.8 F, Min: 53.6 F
    </Typography>
  );

  const {
    data: dataDissolvedOxygen,
    isFetching: isFetchingDissolvedOxygen,
    error: errorDissolvedOxygen,
    refetch: refetchDissolvedOxygen,
  } = useQuery(
    ["dashboard-stagecoach-widgets/dissolved-oxygen"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-stagecoach-widgets/dissolved-oxygen`,
          {
            location_ndx: [656],
            parameter_ndx: [60],
          },
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const tableSubtitles = {
    "Flows Above and Below (cfs)": (
      <Typography>
        Min Flow Limits <strong>Below </strong> Res: 40 CFS Dec - Jul; 20 CFS
        Aug - Nov
      </Typography>
    ),
    "Temperature & Dissolved Oxygen": (
      <Typography>
        Outlet Temperature Limits: Jul - Sep Max: 60.8 F, Min: 53.6 F<br />
        Outlet DO Limits: 6 mg/l Year Round
      </Typography>
    ),
  };

  const rowTitles = [
    { title: "Last Reported Value", field: "value_last_report" },
    { title: "5am Today", field: "value_5am_today" },
    { title: "24 Hour Avg", field: "avg_24hour" },
    { title: "24 Hour High", field: "max_24hour" },
    { title: "24 Hour Low", field: "min_24hour" },
    { title: "Last Reports", field: "last_report" },
  ];

  const {
    data: dataStats,
    isFetching: isFetchingStats,
    error: errorStats,
    refetch: refetchStats,
  } = useQuery(
    ["dashboard-stagecoach-stats"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-stagecoach-stats`,
          { headers }
        );

        const groupedData = groupByValueArray(data, "stats_table_num");

        const tableData = groupedData?.map((group) => {
          return {
            title: group[0].stats_table_name,
            headers: group.map((item) => item.location_display_label),
            rows: rowTitles.map((rowTitle) => ({
              title: rowTitle.title,
              values: group.map((item) => item[rowTitle.field]),
            })),
          };
        });

        return tableData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: {
      waterElevation: {
        data: dataWaterElevation,
        isLoading: isFetchingWaterElevation,
        error: errorWaterElevation,
        refetch: refetchWaterElevation,
      },
      temp: {
        data: dataTemp,
        isLoading: isFetchingTemp,
        error: errorTemp,
        refetch: refetchTemp,
        subtitle: tempSubtitle,
      },
      dissolvedOxygen: {
        data: dataDissolvedOxygen,
        isLoading: isFetchingDissolvedOxygen,
        error: errorDissolvedOxygen,
        refetch: refetchDissolvedOxygen,
      },
      stats: {
        data: dataStats,
        isLoading: isFetchingStats,
        error: errorStats,
        refetch: refetchStats,
        tableSubtitles,
      },
    },
  };
};
