import { useCallback, useEffect, useState } from "react";
import {
  BASE_URL,
  DEFAULT_END_DATE,
  DEFAULT_QUERY_OPTIONS,
  DEFAULT_START_DATE,
  DEFAULT_TIME_INTERVAL,
  ENDPOINT,
  RESERVOIR_NDX,
} from "./constants";
import axios from "axios";
import { useQuery } from "react-query";
import { lighten } from "@material-ui/core";

const useFetch = ({ endpoint, queryKey = null, params = "", options = {} }) => {
  const queryFn = async () => {
    const { data } = await axios.get(`${BASE_URL}/${endpoint}/${params}`);
    return data;
  };

  const queryArray = queryKey === null ? [endpoint] : [queryKey];
  return useQuery(queryArray, queryFn, {
    onError: (error) => console.error(error),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
};

const prepareGraphData = (data) => {
  if (!data?.length) return null;

  const dataArrays = {
    labels: [],
    measuredFlow: [],
    muniIndustrial: [],
    augmentation: [],
    generalSupply: [],
    raise: [],
    preferredRemainder: [],
    emergencyRemainder: [],
    naturalFlow: [],
  };

  data.forEach((item) => {
    dataArrays.labels.push(new Date(item.collect_date));
    dataArrays.measuredFlow.push(item.measured_flow);
    dataArrays.muniIndustrial.push(item.r_muni_industrial);
    dataArrays.augmentation.push(item.r_augmentation);
    dataArrays.generalSupply.push(item.r_general_supply);
    dataArrays.raise.push(item.r_raise);
    dataArrays.preferredRemainder.push(item.r_preferred_remainder);
    dataArrays.emergencyRemainder.push(item.r_emergency_remainder);
    dataArrays.naturalFlow.push(item.r_natural_flow);
  });

  return {
    labels: dataArrays.labels,
    datasets: [
      {
        label: "Measured Flow",
        type: "line",
        yAxisID: "yL",
        backgroundColor: lighten("#000000", 0.5),
        borderColor: "#000000",
        data: dataArrays.measuredFlow,
        pointBorderWidth: 2,
        pointBackgroundColor: "#0F9ED5",
        pointBorderColor: "#000000",
        pointRadius: 4,
        pointHoverRadius: 6,
        borderWidth: 5,
        spanGaps: false,
        stack: false,
        legendAndTooltipOrder: 7,
      },
      {
        label: "Natural Flow",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#E0EAF6",
        borderColor: "#E0EAF6",
        data: dataArrays.naturalFlow,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 0,
      },
      {
        label: "Stagecoach Reservoir Emergency Remainder",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#8A441E",
        borderColor: "#8A441E",
        data: dataArrays.emergencyRemainder,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 1,
      },
      {
        label: "Stagecoach Reservoir Preferred Remainder",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#E3AC89",
        borderColor: "#E3AC89",
        data: dataArrays.preferredRemainder,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 2,
      },
      {
        label: "Stagecoach Reservoir Raise",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#A2D67E",
        borderColor: "#A2D67E",
        data: dataArrays.raise,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 3,
      },
      {
        label: "Stagecoach Reservoir General Supply",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#90368F",
        borderColor: "#90368F",
        data: dataArrays.generalSupply,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 4,
      },
      {
        label: "Stagecoach Reservoir Augmentation",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#D8A2DA",
        borderColor: "#D8A2DA",
        data: dataArrays.augmentation,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 5,
      },
      {
        label: "Stagecoach Reservoir Municipal/Industrial",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#559DD0",
        borderColor: "#0F9ED5",
        data: dataArrays.muniIndustrial,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 6,
      },
    ],
  };
};

export const useColorsOfWaterStagecoach = () => {
  const [timeInterval, setTimeInterval] = useState(DEFAULT_TIME_INTERVAL);
  const [streamGage, setStreamGage] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });

  const handleTimeInterval = useCallback(({ target: { value } }) => {
    setTimeInterval(value);
  }, []);

  const handleStreamGage = useCallback(({ target: { value } }) => {
    setStreamGage(value);
  }, []);

  const handleStartDate = useCallback((date) => {
    setDateRange((prev) => ({ ...prev, startDate: date }));
  }, []);

  const handleEndDate = useCallback((date) => {
    setDateRange((prev) => ({ ...prev, endDate: date }));
  }, []);

  const { data: dataListStreamGages } = useFetch({
    endpoint: `${ENDPOINT}/stream-gages`,
    queryKey: ["stream-gages", RESERVOIR_NDX],
    params: RESERVOIR_NDX,
    options: {
      staleTime: 0,
      cacheTime: 300000,
      onError: (error) => console.error(error),
      select: (data) => {
        return data.map((item) => ({
          value: item.stream_gage_ndx,
          label: item.stream_gage,
        }));
      },
      onSuccess: (data) => {
        if (data?.length) {
          setStreamGage(data[data.length - 1].value);
        }
      },
    },
  });

  const {
    data,
    error,
    isFetching: isLoading,
    refetch,
  } = useFetch({
    endpoint: `${ENDPOINT}/graph-${timeInterval}`,
    queryKey: ["line", streamGage, dateRange.startDate, dateRange.endDate],
    params: `${streamGage}/${dateRange.startDate.toISOString()}/${dateRange.endDate.toISOString()}`,
    options: {
      enabled: !!streamGage,
      onError: (error) => console.error(error),
    },
  });

  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    setGraphData(prepareGraphData(data));
  }, [data]);

  return {
    streamGage,
    dataListStreamGages,
    handleStreamGage,
    timeInterval,
    setTimeInterval,
    handleTimeInterval,
    dateRange,
    setDateRange,
    handleStartDate,
    handleEndDate,
    rawData: data,
    data: graphData,
    error,
    isLoading,
    refetch,
  };
};
