import React from "react";
import TemperatureWidget from "./TemperatureWidget";

function TemperatureMaximums({ temp, showWarning = false }) {
  return (
    <>
      <TemperatureWidget
        title="24-Hour Max"
        value={temp?.max_24hr ?? null}
        unit={temp?.last_report === "Unavailable" ? "N/A" : "°F"}
        steps={temp?.last_report === "Unavailable" ? 0 : 7}
        showWarning={showWarning}
      />
      <TemperatureWidget
        title="7-Day Max"
        value={temp?.max_7day ?? null}
        unit={temp?.last_report === "Unavailable" ? "N/A" : "°F"}
        steps={temp?.last_report === "Unavailable" ? 0 : 7}
        showWarning={showWarning}
      />
    </>
  );
}

export default TemperatureMaximums;
