import styled from "styled-components/macro";
import {
  Divider as MuiDivider,
  List,
  ListItem,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

export const StyledList = styled(List)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

export const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};

  &:last-child {
    border-bottom: none;
  }
`;

export const Typography = styled(MuiTypography)(spacing);
export const Divider = styled(MuiDivider)(spacing);
