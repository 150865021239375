import React, { memo } from "react";
import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import { DATE_RANGE_BUTTONS } from "./constants";

const ToggleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: column;
`;

const ToggleButtonGroup = styled(ButtonGroup)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ToggleButton = styled(Button)`
  flex: 1;
`;

const QuickSetDateRangeButtonGroup = ({ onChange, currentGroupType }) => {
  const handleClick = (config) => {
    const startDate = config.startDate();
    const endDate = config.endDate();
    onChange(startDate, endDate, currentGroupType);
  };

  return (
    <ToggleContainer>
      {DATE_RANGE_BUTTONS[currentGroupType]?.map((section, index) => (
        <React.Fragment key={`section-${index}`}>
          {section.header && (
            <Typography
              key={`header-${section.header}`}
              variant="subtitle2"
              color="primary"
              gutterBottom
              style={{ width: "100%", marginTop: "8px" }}
            >
              {section.header}
            </Typography>
          )}
          <ToggleButtonGroup>
            {section.buttons.map((config, buttonIndex) => (
              <ToggleButton
                key={`button-${buttonIndex}`}
                size="small"
                onClick={() => handleClick(config)}
              >
                {config.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </React.Fragment>
      ))}
    </ToggleContainer>
  );
};

export default memo(QuickSetDateRangeButtonGroup);
