import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import axios from "axios";
import { getLookupForDataSortedByField } from "../../../utils";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const fetchStationsList = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/stations-list`
  );
  return data;
};
const MonitoringLocationsAndParametersByBasinRegion = () => {
  const { data, isLoading, error } = useQuery(
    `stations-list`,
    fetchStationsList,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  const { reg_group_ndx } = useParams();
  const initialRegionFilter = useMemo(() => {
    if (!data) return [];
    const regionMatch = data.find(
      (region) => region.reg_group_ndx === parseInt(reg_group_ndx)
    );
    return regionMatch ? [regionMatch.region] : [];
  }, [data, reg_group_ndx]);
  return (
    <>
      <Helmet title="Monitoring Locations and Parameters by Basin Region" />
      <Typography variant="h3" gutterBottom display="inline">
        Monitoring Locations and Parameters by Basin Region
      </Typography>
      <Divider my={6} />
      <Grid container justify="center">
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              {
                title: "Region",
                field: "region",
                filtering: true,
                defaultFilter: initialRegionFilter,
                lookup: getLookupForDataSortedByField(data, "region"),
              },
              {
                title: "Monitoring Location",
                field: "location",
              },
              {
                title: "Parameter",
                field: "parameter",
                lookup: getLookupForDataSortedByField(data, "parameter"),
              },
              {
                title: "Starts",
                field: "por_min",
                filtering: false,
              },
              {
                title: "Ends",
                field: "por_max",
                filtering: false,
              },
              {
                title: "Huc 10",
                field: "huc_10",
                lookup: getLookupForDataSortedByField(data, "huc_10"),
              },
            ]}
            data={data}
            isLoading={isLoading}
            options={{
              filtering: true,
              paging: true,
              search: false,
              toolbar: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: error
                  ? "Error loading data"
                  : "No records to display",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MonitoringLocationsAndParametersByBasinRegion;
