import { useQuery } from "react-query";
import axios from "axios";

// const BASE_IMG_PATH = "/static/img/tools";

const fetchIconResources = async (stakeholder) => {
  const { data } = await axios.get(
    `${
      process.env.REACT_APP_ENDPOINT
    }/api/stakeholder-tools-inventory-with-sizing/${stakeholder?.ndx ?? 0}`
  );
  return data.map(
    ({
      tool_name,
      description,
      path,
      ndx,
      external,
      icon_image_file,
      has_optional_route_parameter,
      card_width_columns,
      card_height_pixels,
      card_color,
    }) => ({
      title: tool_name,
      description,
      link: path,
      imagePath: icon_image_file,
      hasOptionalRouteParameter: has_optional_route_parameter,
      key: ndx,
      external,
      datacategoryPath: stakeholder?.datacategoryPath ?? null,
      cols: card_width_columns,
      height: card_height_pixels,
      backgroundColor: card_color,
    })
  );
};

const useStakeholderLandingPage = (stakeholder) => {
  const { data, error, isLoading } = useQuery(
    ["stakeholder-tools-inventory-with-sizing", stakeholder?.ndx],
    () => fetchIconResources(stakeholder),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { data, error, isLoading };
};

export default useStakeholderLandingPage;
