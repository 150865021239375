import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider, css } from "styled-components/macro";
import ResetZoomControl from "./ResetZoomControl";
import { STARTING_LOCATION } from "../../constants";
import ToggleBasemapControl from "./ToggleBasemapControl";
import debounce from "lodash.debounce";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import createTheme from "../../theme";
import Popup from "../../pages/publicMap/popup";
import { create } from "jss";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import Loader from "../Loader";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-radius: 4px;

  overflow: hidden;
  transition: all 0.3s ease-in-out;
  ${({ isVisible }) =>
    !isVisible &&
    css`
      height: 0;
      opacity: 0;
    `}
`;

const MapContainer = styled.div`
  height: 400px;
  width: 100%;
`;

const QueryAndDownloadMap = ({
  mapLocations: { data: locations, isLoading, error },
  isMapVisible,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const { watch } = useFormContext();
  const selectedLocations = watch("locations");
  const selectedBasins = watch("basins");
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const mapContainerRef = useRef(null);

  const DUMMY_BASEMAP_LAYERS = [
    { url: "streets-v11", icon: "commute" },
    { url: "outdoors-v11", icon: "park" },
    { url: "satellite-streets-v11", icon: "satellite_alt" },
  ];

  const locationsLayer = {
    id: "yampa-river-locations-circle",
    type: "circle",
    source: "yampa-river-locations",
    paint: {
      "circle-stroke-width": [
        "case",
        ["in", ["get", "location_ndx"], ["literal", selectedLocations]],
        3,
        2,
      ],
      "circle-stroke-color": [
        "case",
        ["in", ["get", "location_ndx"], ["literal", selectedLocations]],
        "red",
        "black",
      ],
      "circle-radius": 7,
      "circle-color": [
        "case",
        ["==", ["get", "huc10"], "1018000101"],
        "#e6194B",
        ["==", ["get", "huc10"], "1018000102"],
        "#3cb44b",
        ["==", ["get", "huc10"], "1018000103"],
        "#ffe119",
        ["==", ["get", "huc10"], "1401000110"],
        "#0082c8",
        ["==", ["get", "huc10"], "1404010901"],
        "#f58231",
        ["==", ["get", "huc10"], "1404010903"],
        "#911eb4",
        ["==", ["get", "huc10"], "1405000101"],
        "#46f0f0",
        ["==", ["get", "huc10"], "1405000102"],
        "#f032e6",
        ["==", ["get", "huc10"], "1405000103"],
        "#d2f53c",
        ["==", ["get", "huc10"], "1405000104"],
        "#fabebe",
        ["==", ["get", "huc10"], "1405000105"],
        "#008080",
        ["==", ["get", "huc10"], "1405000106"],
        "#e6beff",
        ["==", ["get", "huc10"], "1405000107"],
        "#aa6e28",
        ["==", ["get", "huc10"], "1405000108"],
        "#fffac8",
        ["==", ["get", "huc10"], "1405000109"],
        "#800000",
        ["==", ["get", "huc10"], "1405000110"],
        "#aaffc3",
        ["==", ["get", "huc10"], "1405000111"],
        "#808000",
        ["==", ["get", "huc10"], "1405000201"],
        "#ffd8b1",
        ["==", ["get", "huc10"], "1405000202"],
        "#000075",
        ["==", ["get", "huc10"], "1405000203"],
        "#808080",
        ["==", ["get", "huc10"], "1405000204"],
        "#ffffff",
        ["==", ["get", "huc10"], "1405000205"],
        "#000000",
        ["==", ["get", "huc10"], "1405000206"],
        "#bcf60c",
        ["==", ["get", "huc10"], "1405000301"],
        "#fad3cf",
        ["==", ["get", "huc10"], "1405000302"],
        "#b3c2f2",
        ["==", ["get", "huc10"], "1405000303"],
        "#c7f5b4",
        ["==", ["get", "huc10"], "1405000304"],
        "#d1b3c4",
        ["==", ["get", "huc10"], "1405000307"],
        "#b2d8d8",
        ["==", ["get", "huc10"], "1405000308"],
        "#f9d5e5",
        ["==", ["get", "huc10"], "1405000309"],
        "#ece2d0",
        ["==", ["get", "huc10"], "1405000311"],
        "#b6d7a8",
        ["==", ["get", "huc10"], "1405000501"],
        "#a4c2f4",
        "#000000",
      ],
    },
    lreProperties: {
      popup: {
        titleField: "location_name",
        excludeFields: [],
      },
    },
  };

  const locationsLabelsLayer = {
    id: "yampa-river-locations-labels",
    type: "symbol",
    source: "yampa-river-locations",
    minzoom: 9,
    layout: {
      "text-field": [
        "format",
        ["get", "location_name"],
        {},
        "\n",
        {},
        ["get", "location_id"],
        {
          "text-font": ["literal", ["DIN Offc Pro Italic"]],
          "font-scale": 0.85,
        },
      ],
      "text-size": 12,
      "text-offset": [0, -3.75],
      "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
    },
    paint: {
      "text-color": "rgb(49,49,49)",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 3,
    },
  };

  const huc10Fill = {
    id: "huc-10-boundaries-fill",
    name: "HUC 10 Boundaries",
    type: "fill",
    source: "huc-10-boundaries",
    "source-layer": "Yampa_HUC10-c6nelo",
    paint: {
      "fill-color": [
        "case",
        ["==", ["get", "HUC10"], "1018000101"],
        "#e6194B",
        ["==", ["get", "HUC10"], "1018000102"],
        "#3cb44b",
        ["==", ["get", "HUC10"], "1018000103"],
        "#ffe119",
        ["==", ["get", "HUC10"], "1401000110"],
        "#0082c8",
        ["==", ["get", "HUC10"], "1404010901"],
        "#f58231",
        ["==", ["get", "HUC10"], "1404010903"],
        "#911eb4",
        ["==", ["get", "HUC10"], "1405000101"],
        "#46f0f0",
        ["==", ["get", "HUC10"], "1405000102"],
        "#f032e6",
        ["==", ["get", "HUC10"], "1405000103"],
        "#d2f53c",
        ["==", ["get", "HUC10"], "1405000104"],
        "#fabebe",
        ["==", ["get", "HUC10"], "1405000105"],
        "#008080",
        ["==", ["get", "HUC10"], "1405000106"],
        "#e6beff",
        ["==", ["get", "HUC10"], "1405000107"],
        "#aa6e28",
        ["==", ["get", "HUC10"], "1405000108"],
        "#fffac8",
        ["==", ["get", "HUC10"], "1405000109"],
        "#800000",
        ["==", ["get", "HUC10"], "1405000110"],
        "#aaffc3",
        ["==", ["get", "HUC10"], "1405000111"],
        "#808000",
        ["==", ["get", "HUC10"], "1405000201"],
        "#ffd8b1",
        ["==", ["get", "HUC10"], "1405000202"],
        "#000075",
        ["==", ["get", "HUC10"], "1405000203"],
        "#808080",
        ["==", ["get", "HUC10"], "1405000204"],
        "#ffffff",
        ["==", ["get", "HUC10"], "1405000205"],
        "#000000",
        ["==", ["get", "HUC10"], "1405000206"],
        "#bcf60c",
        ["==", ["get", "HUC10"], "1405000301"],
        "#fad3cf",
        ["==", ["get", "HUC10"], "1405000302"],
        "#b3c2f2",
        ["==", ["get", "HUC10"], "1405000303"],
        "#c7f5b4",
        ["==", ["get", "HUC10"], "1405000304"],
        "#d1b3c4",
        ["==", ["get", "HUC10"], "1405000307"],
        "#b2d8d8",
        ["==", ["get", "HUC10"], "1405000308"],
        "#f9d5e5",
        ["==", ["get", "HUC10"], "1405000309"],
        "#ece2d0",
        ["==", ["get", "HUC10"], "1405000311"],
        "#b6d7a8",
        ["==", ["get", "HUC10"], "1405000501"],
        "#a4c2f4",
        "#000000",
      ],
      "fill-opacity": 0,
    },
  };

  const huc10Line = {
    id: "huc-10-boundaries-line",
    name: "HUC 10 Boundaries",
    type: "line",
    source: "huc-10-boundaries",
    "source-layer": "Yampa_HUC10-c6nelo",
    paint: {
      "line-color": "#60BAF0",
      "line-width": 2,
    },
  };

  useEffect(() => {
    if (!isLoading && mapContainerRef.current) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
        center: STARTING_LOCATION,
        zoom: 7,
      });

      map.addControl(new mapboxgl.NavigationControl(), "top-left");
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "top-left"
      );
      map.addControl(new mapboxgl.FullscreenControl());
      map.addControl(new ResetZoomControl(), "top-left");

      DUMMY_BASEMAP_LAYERS.forEach((layer) => {
        return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
      });

      map.on("load", () => {
        setMapIsLoaded(true);
        setMap(map);
      });
    }
  }, [isLoading]); // eslint-disable-line

  useEffect(() => {
    if (map && mapContainerRef?.current && isMapVisible) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainerRef.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map, isMapVisible, mapContainerRef]);

  useEffect(() => {
    if (
      mapIsLoaded &&
      locations?.data?.features?.length > 0 &&
      typeof map != "undefined"
    ) {
      if (!map.getSource("yampa-river-locations")) {
        map.addSource("huc-10-boundaries", {
          type: "vector",
          url: "mapbox://yampariver.4r6uwl8q",
        });

        map.addLayer(huc10Fill);
        map.addLayer(huc10Line);

        const { id, ...rest } = locations;

        map.addSource(id, rest);

        map.addLayer(locationsLayer);
        map.addLayer(locationsLabelsLayer);

        map.on("click", "huc-10-boundaries-fill", (e) => {
          const feature = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature?.properties?.Name)[0];

          const description = feature.properties.Name;

          const huc10Popup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(map);

          map.on("closeAllPopups", () => {
            huc10Popup.remove();
          });
        });

        map.on("click", "yampa-river-locations-circle", (e) => {
          map.fire("closeAllPopups");

          const features = map.queryRenderedFeatures(e.point);
          const myFeatures = features.filter(
            (feature) => feature.source === "yampa-river-locations"
          );
          const coordinates = [e.lngLat.lng, e.lngLat.lat];

          const popupNode = document.createElement("div");
          ReactDOM.render(
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[locationsLayer]}
                    features={myFeatures}
                    maxWidth="252px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        map.on("mouseenter", "yampa-river-locations-circle", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        map.on("mouseleave", "yampa-river-locations-circle", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [isLoading, mapIsLoaded, map, locations]); //eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("huc-10-boundaries-fill")) {
      const selectedBasinsAsString = selectedBasins.map(String);

      map.setPaintProperty("huc-10-boundaries-fill", "fill-opacity", [
        "case",
        ["in", ["get", "HUC10"], ["literal", selectedBasinsAsString]],
        0.35,
        0,
      ]);
    }
  }, [selectedBasins, map]);

  useEffect(() => {
    if (map !== undefined && map.getLayer("yampa-river-locations-circle")) {
      map.setPaintProperty(
        "yampa-river-locations-circle",
        "circle-stroke-width",
        [
          "case",
          ["in", ["get", "location_ndx"], ["literal", selectedLocations]],
          3,
          2,
        ]
      );
      map.setPaintProperty(
        "yampa-river-locations-circle",
        "circle-stroke-color",
        [
          "case",
          ["in", ["get", "location_ndx"], ["literal", selectedLocations]],
          "red",
          "black",
        ]
      );
    }
  }, [selectedLocations, map]);

  if (error) return "An error has occurred: " + error.message;

  if (isLoading) return <Loader />;
  return (
    <MapWrapper isVisible={isMapVisible}>
      <MapContainer ref={mapContainerRef} />
    </MapWrapper>
  );
};

export default QueryAndDownloadMap;
