import { useMutation, useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { DEFAULT_VALUES, FILENAME, VALIDATION_SCHEMA } from "./constants";
import axios from "axios";
import { useApp } from "../../../AppProvider";
import {
  filterBySelectedDataCategory,
  filterDataWithCriteria,
  generateAuthHeaders,
  hasEmptyValues,
  triggerDownload,
  updateFilteredSelections,
  useCustomQuery,
} from "./utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export const useQueryAndDownload = () => {
  const { datacategoryPath } = useParams();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();
  const formMethods = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: DEFAULT_VALUES,
  });

  const { watch, getValues, setValue } = formMethods;

  // trigger to enable count query
  const formValues = watch([
    "timestep",
    "startDate",
    "endDate",
    "basins",
    "organizations",
    "locationTypes",
    "parameterGroups",
    "parameters",
    "locations",
  ]);
  const formEligibleForFetching = !hasEmptyValues(getValues());

  const [currentCount, setCurrentCount] = useState(null);

  useEffect(() => {
    if (!formEligibleForFetching) {
      setCurrentCount(null);
    }
  }, [formEligibleForFetching]);

  // if a new count request is made, the previous one is cancelled
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const fetchDataCount = async () => {
    const formData = getValues();
    const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/count-data`;
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    cancelTokenSourceRef.current.cancel("Cancelling previous request");
    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      const { data } = await axios.post(url, formData, {
        headers,
        cancelToken: cancelTokenSourceRef.current.token,
      });
      return data.count;
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Fetch error:", error);
      }
      throw error;
    }
  };

  const { isFetching: isLoadingCurrentCount } = useQuery(
    ["data-count", isAuthenticated, formValues],
    fetchDataCount,
    {
      enabled: formEligibleForFetching,
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCurrentCount(data);
      },
      onError: (error) => {
        setCurrentCount(null);
        console.error("Error fetching count:", error);
      },
    }
  );

  // csv download mutation
  const { mutate, isLoading, error } = useMutation(
    async (formData) => {
      const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/${formData.timestep}`;

      const headers = await generateAuthHeaders(
        getAccessTokenSilently,
        isAuthenticated
      );

      try {
        const response = await axios.post(url, formData, {
          headers,
          responseType: "blob",
        });

        return { csvContent: response.data, filename: FILENAME };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: ({ csvContent, filename }) => {
        triggerDownload(csvContent, filename);
        doToast("success", "CSV file successfully added to download folder.");
      },
      onError: (err) => {
        doToast("error", "Failed to download CSV file.");
        console.error(err);
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  // fetches filter group inputs
  const basinsQuery = useCustomQuery(
    ["q-d-list-basins"],
    "query-and-download/q-d-list-basins"
  );
  const dataCategoriesQuery = useCustomQuery(
    ["q-d-list-data-categories"],
    "query-and-download/q-d-list-data-categories"
  );
  const dataSourcesQuery = useCustomQuery(
    ["q-d-list-data-sources", isAuthenticated],
    "query-and-download/q-d-list-data-sources"
  );
  const locationsQuery = useCustomQuery(
    ["q-d-list-locations", isAuthenticated],
    "query-and-download/q-d-list-locations"
  );
  const locationTypesQuery = useCustomQuery(
    ["q-d-list-location-types"],
    "query-and-download/q-d-list-location-types"
  );
  const organizationsQuery = useCustomQuery(
    ["q-d-list-organizations"],
    "query-and-download/q-d-list-organizations"
  );
  const parameterGroupsQuery = useCustomQuery(
    ["q-d-list-parameter-groups"],
    "query-and-download/q-d-list-parameter-groups"
  );
  const parametersQuery = useCustomQuery(
    ["q-d-list-parameters"],
    "query-and-download/q-d-list-parameters"
  );
  const timestepsQuery = useCustomQuery(
    ["q-d-list-timesteps"],
    "query-and-download/q-d-list-timesteps"
  );
  const mapLocationsQuery = useCustomQuery(
    ["locations-map", isAuthenticated],
    "query-and-download/locations-map"
  );

  // logic to select filter group values based on selected data category
  const filterGroupsHaveLoaded = useMemo(() => {
    return [
      basinsQuery,
      dataSourcesQuery,
      organizationsQuery,
      locationTypesQuery,
      parameterGroupsQuery,
      dataCategoriesQuery,
    ].every((query) => query?.data?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    basinsQuery.data,
    dataSourcesQuery.data,
    organizationsQuery.data,
    locationTypesQuery.data,
    parameterGroupsQuery.data,
    dataCategoriesQuery.data,
  ]);

  useEffect(() => {
    if (filterGroupsHaveLoaded) {
      const fieldMappings = [
        { data: basinsQuery.data, field: "basins", idField: "basin_ndx" },
        {
          data: dataSourcesQuery.data,
          field: "dataSources",
          idField: "sourcecategory_ndx",
        },
        {
          data: organizationsQuery.data,
          field: "organizations",
          idField: "organization_ndx",
        },
        {
          data: locationTypesQuery.data,
          field: "locationTypes",
          idField: "location_type_ndx",
        },
        {
          data: parameterGroupsQuery.data,
          field: "parameterGroups",
          idField: "parameter_group_ndx",
        },
      ];

      fieldMappings.forEach(({ data, field, idField }) =>
        setValue(
          field,
          data.map((item) => item[idField])
        )
      );

      const category = dataCategoriesQuery.data.find(
        (item) => item.datacategory_path === datacategoryPath
      );
      if (category) {
        setValue("dataCategory", category.datacategory_ndx);
      }
    }
  }, [
    basinsQuery.data,
    dataSourcesQuery.data,
    organizationsQuery.data,
    locationTypesQuery.data,
    parameterGroupsQuery.data,
    dataCategoriesQuery.data,
    filterGroupsHaveLoaded,
    datacategoryPath,
    setValue,
  ]);

  // cascading filters
  const basins = watch("basins");
  const locationTypes = watch("locationTypes");
  const parameterGroups = watch("parameterGroups");
  const selectedDataCategory = watch("dataCategory");

  const filteredLocations = useMemo(() => {
    const criteria = {
      basin_ndx: basins,
      location_type_ndx: locationTypes,
    };
    return filterDataWithCriteria(
      locationsQuery.data,
      criteria,
      selectedDataCategory
    );
  }, [locationsQuery.data, basins, locationTypes, selectedDataCategory]);

  const filteredParameters = useMemo(() => {
    const criteria = {
      parameter_group_ndx: parameterGroups,
    };
    return filterDataWithCriteria(
      parametersQuery.data,
      criteria,
      selectedDataCategory
    );
  }, [parametersQuery.data, parameterGroups, selectedDataCategory]);

  const filteredBasins = useMemo(() => {
    return filterBySelectedDataCategory(basinsQuery.data, selectedDataCategory);
  }, [basinsQuery.data, selectedDataCategory]);

  const filteredLocationTypes = useMemo(() => {
    return filterBySelectedDataCategory(
      locationTypesQuery.data,
      selectedDataCategory
    );
  }, [locationTypesQuery.data, selectedDataCategory]);

  const filteredParameterGroups = useMemo(() => {
    return filterBySelectedDataCategory(
      parameterGroupsQuery.data,
      selectedDataCategory
    );
  }, [parameterGroupsQuery.data, selectedDataCategory]);

  useEffect(() => {
    updateFilteredSelections(
      "locations",
      filteredLocations,
      (item) => item.location_ndx,
      getValues,
      setValue
    );
    updateFilteredSelections(
      "parameters",
      filteredParameters,
      (item) => item.parameter_ndx,
      getValues,
      setValue
    );
    updateFilteredSelections(
      "basins",
      filteredBasins,
      (item) => item.basin_ndx,
      getValues,
      setValue
    );
    updateFilteredSelections(
      "locationTypes",
      filteredLocationTypes,
      (item) => item.location_type_ndx,
      getValues,
      setValue
    );
    updateFilteredSelections(
      "parameterGroups",
      filteredParameterGroups,
      (item) => item.parameter_group_ndx,
      getValues,
      setValue
    );
  }, [
    filteredLocations,
    filteredParameters,
    filteredBasins,
    filteredLocationTypes,
    filteredParameterGroups,
    getValues,
    setValue,
  ]);

  const inputs = {
    basins: {
      data: filteredBasins || [],
      isLoading: basinsQuery.isFetching,
      error: basinsQuery.error,
    },
    dataCategories: {
      data: dataCategoriesQuery.data || [],
      isLoading: dataCategoriesQuery.isFetching,
      error: dataCategoriesQuery.error,
    },
    dataSources: {
      data: dataSourcesQuery.data || [],
      isLoading: dataSourcesQuery.isFetching,
      error: dataSourcesQuery.error,
    },
    locations: {
      data: filteredLocations || [],
      isLoading: locationsQuery.isFetching,
      error: locationsQuery.error,
    },
    locationTypes: {
      data: filteredLocationTypes || [],
      isLoading: locationTypesQuery.isFetching,
      error: locationTypesQuery.error,
    },
    organizations: {
      data: organizationsQuery.data || [],
      isLoading: organizationsQuery.isFetching,
      error: organizationsQuery.error,
    },
    parameterGroups: {
      data: filteredParameterGroups || [],
      isLoading: parameterGroupsQuery.isFetching,
      error: parameterGroupsQuery.error,
    },
    parameters: {
      data: filteredParameters || [],
      isLoading: parametersQuery.isFetching,
      error: parametersQuery.error,
    },
    timesteps: {
      data: timestepsQuery.data || [],
      isLoading: timestepsQuery.isFetching,
      error: timestepsQuery.error,
    },
  };

  return {
    formMethods,
    onSubmit,
    isLoading,
    error: error,
    inputs,
    currentCount,
    isLoadingCurrentCount,
    mapLocations: {
      data: mapLocationsQuery.data || [],
      isLoading: mapLocationsQuery.isFetching,
      error: mapLocationsQuery.error,
    },
  };
};

export default useQueryAndDownload;
