import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { INIT_STYLE_VALUES } from "../../constants";
import { useAuth0 } from "@auth0/auth0-react";

const useLayerStyles = ({ onLayerStyleChange, isMapLoaded }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { data } = useQuery(
    ["filterData2"],
    async () => {
      let headers = {};

      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        headers = { Authorization: `Bearer ${token}` };
      }
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        locationTypes: {
          loaded: true,
          ...prevState.locationTypes,
          paint: {
            "circle-color": [
              "match",
              ["get", "locationtype"],
              ...data.locationTypes.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
      }));
    }
  }, [data]); //eslint-disable-line

  const [activeStyle, setActiveStyle] = useState(styleValues.locationTypes);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  useEffect(() => {
    if (isMapLoaded && styleValues.locationTypes.loaded) {
      handleActiveStyle("locationTypes");
    }
  }, [styleValues, isMapLoaded]); //eslint-disable-line

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
