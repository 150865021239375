import React, { useState } from "react";
import {
  Toolbar,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Typography as MuiTypography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";
import { DesktopMenu } from "./StakeholderLinksAppBar";

const Typography = styled(MuiTypography)(spacing);

const StyledToolbar = styled(Toolbar)`
  background-color: #fff;
  padding: 24px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 16px;
  }
`;

const BrandIcon = styled("img")`
  width: 80px;
  margin-right: 16px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 60px;
  }
`;

const Title = styled(Typography)`
  flex-grow: 1;
  text-transform: uppercase;
  letter-spacing: 3px;
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;

const MenuButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  color: black;
`;

const DrawerPaper = styled("div")`
  width: 100%;
`;

const ListItemTextStyled = styled(ListItemText)`
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const MobileMenu = ({ drawerOpen, handleDrawerToggle }) => (
  <>
    <MenuButton edge="end" aria-label="menu" onClick={handleDrawerToggle}>
      <MenuIcon />
    </MenuButton>
    <Drawer
      anchor="top"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{ component: DrawerPaper }}
    >
      <List>
        {Object.keys(STAKEHOLDERS_CONFIG).map((key) => (
          <ListItem
            button
            key={key}
            component={Link}
            to={STAKEHOLDERS_CONFIG[key].path}
            onClick={() => {
              handleDrawerToggle();
            }}
          >
            <ListItemTextStyled
              primary={STAKEHOLDERS_CONFIG[key].splashTitle}
            />
          </ListItem>
        ))}
        <ListItem
          button
          component={Link}
          to="/dashboard"
          onClick={() => {
            handleDrawerToggle();
          }}
        >
          <ListItemTextStyled primary="All Tools" />
        </ListItem>
      </List>
    </Drawer>
  </>
);

const LandingAppBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMedScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <StyledToolbar>
      <BrandIcon
        src="/static/img/splash/logos/YourYampaRiver_Logo_TRANSPARENT_BACKGROUND.png"
        alt="Your Yampa River Logo"
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight="auto"
      >
        <Title color="primary" variant={isMedScreen ? "h6" : "h5"}>
          Yampa River
        </Title>
        <Title color="primary" variant="h6" style={{ textAlign: "center" }}>
          <strong> Dashboard</strong>
        </Title>
      </Box>
      {isSmallScreen ? (
        <MobileMenu
          drawerOpen={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      ) : (
        <DesktopMenu />
      )}
    </StyledToolbar>
  );
};

export default LandingAppBar;
