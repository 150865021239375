import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Grid as MuiGrid,
  Accordion,
  AccordionDetails,
  Typography as MuiTypography,
  Tooltip,
  lighten,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { spacing } from "@material-ui/system";
import { firstOfYear, lastOfYear, lineColors } from "../../../utils";
import DatePicker from "../../../components/pickers/DatePicker";
import Panel from "../../../components/panels/Panel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ExportDataButton from "../../../components/graphs/ExportDataButton";
import SaveRefButton from "../../../components/graphs/SaveRefButton";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";
import TimeseriesLineChartDataViz from "../../../components/graphs/TimeseriesLineChartDataViz";
import { useQuery } from "react-query";
import Loader from "../../../components/Loader";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

const OuterContainer = styled(Box)`
  margin-left: 49px;
  bottom: 53px;
  z-index: 3;
  position: absolute;
  max-height: calc(100% - 63px);
  width: calc(100% - 49px - 49px);
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
  display: flex;
  flex-direction: row;
`;

const Viz = styled("div")`
  max-height: ${({ height }) => height};
  max-width: 100%;
  flex-grow: 1;
`;

const TimeseriesWrapper = styled("div")`
  height: calc(100% - 78px);
  width: 100%;
`;

const TimeseriesContainer = styled("div")`
  height: calc(${({ height }) => height} - 146px);
  width: 100%;
`;

const CircleMarker = styled("div")`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled("div")`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const endpoint = {
  has_temperature_graph: "graph-temperature-data",
  has_temperature_trends: "mapgraph-temps-daily-vs-por-stats",
  has_flow_graph: "graph-flow-data",
  has_flow_trends: "mapgraph-flows-daily-vs-por-stats",
};

const fetchData = async ({ queryKey }) => {
  const [, { dataVizGraphType, dataVizWellNumber, endpoint }] = queryKey;

  const { data: results } = await axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/${endpoint[dataVizGraphType]}/${dataVizWellNumber}`
  );

  if (results.length) {
    // if (dataVizGraphType === "has_temperature_trends") {
    //   return results.map((d) => ({
    //     group: d.month_group,
    //     subGroup: d.location_id_subgroup,
    //     value: d.hourly_value,
    //     units: d.units,
    //     parameter: d.parameter,
    //     location_name: d.location_name,
    //     location_id: d.location_id_subgroup,
    //   }));
    // }
    return results;
  }
  return [];
};

const useCurrentSelectedTimeseriesData = (
  dataVizGraphType,
  dataVizWellNumber,
  endpoint
) => {
  return useQuery(
    [
      "currentSelectedTimeseriesData",
      { dataVizGraphType, dataVizWellNumber, endpoint },
    ],
    fetchData,
    {
      keepPreviousData: false,
      enabled: !!dataVizGraphType && !!dataVizWellNumber,
      refetchOnWindowFocus: false,
    }
  );
};

const mutateGraphData = (dataVizGraphType, currentSelectedTimeseriesData) => {
  if (!currentSelectedTimeseriesData?.length) return null;

  let graphData;

  if (dataVizGraphType === "has_temperature_graph") {
    graphData = {
      labels: currentSelectedTimeseriesData.map(
        (item) => new Date(item.collect_date)
      ),
      datasets: [
        {
          label: "Average Min Temp",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "point",
          backgroundColor: lineColors.blue,
          borderColor: lineColors.blue,
          data: currentSelectedTimeseriesData.map(
            (item) => item.min_daily_temp
          ),
          popupInfo: currentSelectedTimeseriesData.map((item) => item.source),
          pointRadius: 0,
          pointHoverRadius: 4,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
        },
        {
          label: "Average Daily Temp",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "point",
          backgroundColor: lineColors.green,
          borderColor: lineColors.green,
          data: currentSelectedTimeseriesData.map(
            (item) => item.avg_daily_temp
          ),
          pointRadius: 0,
          pointHoverRadius: 4,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
        },
        {
          label: "Average Max Temp",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "point",
          backgroundColor: lineColors.red,
          borderColor: lineColors.red,
          data: currentSelectedTimeseriesData.map(
            (item) => item.max_daily_temp
          ),
          pointRadius: 0,
          pointHoverRadius: 4,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
        },
      ],
    };
  } else if (dataVizGraphType === "has_flow_graph") {
    graphData = {
      labels: currentSelectedTimeseriesData.map(
        (item) => new Date(item.collect_date)
      ),
      datasets: [
        {
          label: "Average Daily Flow",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "point",
          backgroundColor: lineColors.blue,
          borderColor: lineColors.blue,
          data: currentSelectedTimeseriesData.map(
            (item) => item.avg_daily_flow
          ),
          popupInfo: currentSelectedTimeseriesData.map((item) => item.source),
          pointRadius: 0,
          pointHoverRadius: 4,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
        },
      ],
    };
  } else if (dataVizGraphType === "has_temperature_trends") {
    graphData = {
      labels: currentSelectedTimeseriesData.map(
        (item) => new Date(item.collect_date)
      ),
      datasets: [
        {
          label: "Daily Temperatures, °f",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "point",
          backgroundColor: lighten("#000000", 0.5),
          borderColor: "#000000",
          data: currentSelectedTimeseriesData.map(
            (item) => item.daily_avg_degf
          ),
          pointBorderWidth: 1,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#000000",
          pointRadius: 3,
          pointHoverRadius: 5,
          borderWidth: 4,
          spanGaps: false,
          hidden: false,
        },
        {
          label: "Historical Minimum",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "rect",
          backgroundColor: lighten("#32CD32", 0.7),
          borderColor: "#32CD32",
          data: currentSelectedTimeseriesData.map(
            (item) => item.por_min_degf_actual
          ),
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
        },
        {
          label: "Historical Mean",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "rect",
          backgroundColor: lighten("#FFA500", 0.7),
          borderColor: "#FFA500",
          data: currentSelectedTimeseriesData.map(
            (item) => item.por_avg_degf_actual
          ),
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
          fill: "-1",
        },
        {
          label: "Historical Maximum",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "rect",
          backgroundColor: lighten("#FF6347", 0.7),
          borderColor: "#FF6347",
          data: currentSelectedTimeseriesData.map(
            (item) => item.por_max_degf_actual
          ),
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
          fill: "-1",
        },
      ],
    };
    // graphData = {
    //   data: currentSelectedTimeseriesData,
    //   ui: {
    //     legendItems: [
    //       ...new Set(
    //         currentSelectedTimeseriesData?.map((d) => d.subGroup) || []
    //       ),
    //     ],
    //   },
    // };
  } else if (dataVizGraphType === "has_flow_trends") {
    graphData = {
      labels: currentSelectedTimeseriesData.map(
        (item) => new Date(item.collect_date)
      ),
      datasets: [
        {
          label: "Daily Streamflow, CFS",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "point",
          backgroundColor: lighten("#000000", 0.5),
          borderColor: "#000000",
          data: currentSelectedTimeseriesData.map(
            (item) => item.avg_daily_flow
          ),
          pointBorderWidth: 1,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#000000",
          pointRadius: 3,
          pointHoverRadius: 5,
          borderWidth: 4,
          spanGaps: false,
          hidden: false,
        },
        {
          label: "Historical Minimum",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "rect",
          backgroundColor: lighten("#F5C1A6", 0.7),
          borderColor: "#F5C1A6",
          data: currentSelectedTimeseriesData.map(
            (item) => item.por_min_flow_actual
          ),
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
        },
        {
          label: "Historical Mean",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "rect",
          backgroundColor: lighten("#5EA4D3", 0.7),
          borderColor: "#5EA4D3",
          data: currentSelectedTimeseriesData.map(
            (item) => item.por_avg_flow_actual
          ),
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
          fill: "-1",
        },
        {
          label: "Historical Maximum",
          units: currentSelectedTimeseriesData[0].units,
          type: "line",
          yAxisID: "yL",
          pointStyle: "rect",
          backgroundColor: lighten("#231CA6", 0.7),
          borderColor: "#231CA6",
          data: currentSelectedTimeseriesData.map(
            (item) => item.por_max_flow_actual
          ),
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          spanGaps: false,
          hidden: false,
          fill: "-1",
        },
      ],
    };
  }

  return graphData;
};

const DataVizContent = ({
  dataVizWellNumber,
  dataVizGraphType,
  onClose,
  filterValues,
  setFilterValues,
  currentSelectedTimeseriesData,
  isLoading,
  error,
  dataVizHeight,
  handleExpand,
  filteredMutatedGraphData,
}) => {
  const divSaveRef = useRef(null);
  const graphSaveRef = useRef(null);

  const formatTableTitle = (graphType) => {
    if (
      [
        "has_flow_graph",
        "has_temperature_graph",
        "has_temperature_trends",
        "has_flow_trends",
      ].includes(graphType)
    ) {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            <strong>
              {currentSelectedTimeseriesData[0].location_name ?? "NA"}
            </strong>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>{currentSelectedTimeseriesData[0].location_id ?? "NA"}</Box>
          </Typography>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Viz height={dataVizHeight.viz}>
      <CloseContainer>
        <ExpandButton
          handleExpand={handleExpand}
          expanded={dataVizHeight.viz !== "460px"}
        />
        <Tooltip title="Close" arrow>
          <IconButton
            size="small"
            onClick={onClose}
            style={{ marginLeft: "4px" }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </CloseContainer>
      {isLoading ? (
        <div style={{ height: "200px" }}>
          <Loader />
        </div>
      ) : filteredMutatedGraphData && currentSelectedTimeseriesData ? (
        <Panel overflowY="scroll" overflowX="hidden">
          <TimeseriesContainer
            height={dataVizHeight.timeSeries}
            ref={divSaveRef}
          >
            <Box ml={4} pt={2} pb={2} display="flex" alignItems="center">
              <CircleMarker>
                <Room />
              </CircleMarker>
              <Box flexDirection="column" display="flex">
                {formatTableTitle(dataVizGraphType)}
              </Box>

              <Box
                style={{ marginLeft: "auto" }}
                data-html2canvas-ignore={"true"}
                display="flex"
              >
                <ExportDataButton
                  title="location_id"
                  data={currentSelectedTimeseriesData}
                  filterValues={filterValues}
                />
                <SaveRefButton
                  data-html2canvas-ignore
                  ref={divSaveRef}
                  title={dataVizWellNumber}
                />
              </Box>
            </Box>
            <TimeseriesWrapper>
              <TimeseriesLineChartDataViz
                data={filteredMutatedGraphData}
                error={error}
                yLLabel={
                  dataVizGraphType === "has_temperature_graph"
                    ? `Water Temperature, ${currentSelectedTimeseriesData[0].units}`
                    : dataVizGraphType === "has_flow_graph"
                    ? `Average Daily Flow, ${currentSelectedTimeseriesData[0].units}`
                    : dataVizGraphType === "has_flow_trends"
                    ? `Flow Trends, ${currentSelectedTimeseriesData[0].units}`
                    : dataVizGraphType === "has_temperature_trends"
                    ? `Temperature Trends, ${currentSelectedTimeseriesData[0].units}`
                    : null
                }
                xLabelFormat="MMM DD YYYY"
                reverseLegend={[
                  "has_flow_trends",
                  "has_temperature_trends",
                ].includes(dataVizHeight)}
                enableLegendClick={true}
                ref={graphSaveRef}
                filterValues={filterValues}
                type="line"
                displayLegend={true}
                xLabelUnit="day"
                maxTicksX={12}
                maxTicksYL={6}
                maxTicksYR={5}
                align="start"
                usePointStyle={[
                  "has_flow_trends",
                  "has_temperature_trends",
                ].includes(dataVizHeight)}
              />
            </TimeseriesWrapper>
          </TimeseriesContainer>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} sm={6} md={5}>
                  <DatePicker
                    label="Start Date"
                    name="startDate"
                    selectedDate={filterValues.startDate}
                    setSelectedDate={setFilterValues}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <DatePicker
                    label="End Date"
                    name="endDate"
                    selectedDate={filterValues.endDate}
                    setSelectedDate={setFilterValues}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={12}>
                      <Tooltip title="Period of Record">
                        <Button
                          size="small"
                          style={{ width: "100%" }}
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setFilterValues("startDate", null);
                            setFilterValues("endDate", new Date());
                          }}
                        >
                          Record
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={6} md={12}>
                      <Tooltip title="Current Year" placement="bottom">
                        <Button
                          size="small"
                          style={{ width: "100%" }}
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setFilterValues("startDate", firstOfYear);
                            setFilterValues("endDate", lastOfYear);
                          }}
                        >
                          Year
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Panel>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="200px"
        >
          no data available
        </Box>
      )}
    </Viz>
  );
};

const defaultFilterValues = { startDate: null, endDate: new Date() };

const DataViz = ({
  open = false,
  dataVizWellNumber,
  dataVizGraphType,
  onClose,
}) => {
  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) =>
    setFilterValues((prev) => ({ ...prev, [name]: value }));

  useEffect(() => {
    if (dataVizGraphType) {
      if (
        ["has_temperature_trends", "has_flow_trends"].includes(dataVizGraphType)
      ) {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setFullYear(endDate.getFullYear() - 1);
        startDate.setMonth(endDate.getMonth());
        setFilterValues({
          startDate,
          endDate,
        });
      } else {
        setFilterValues(defaultFilterValues);
      }
    }
  }, [dataVizGraphType]);

  const {
    data: currentSelectedTimeseriesData,
    isFetching: isLoading,
    error,
  } = useCurrentSelectedTimeseriesData(
    dataVizGraphType,
    dataVizWellNumber,
    endpoint
  );

  const filteredMutatedGraphData = useMemo(
    () => mutateGraphData(dataVizGraphType, currentSelectedTimeseriesData),
    [dataVizGraphType, currentSelectedTimeseriesData]
  );

  const [dataVizHeight, setDataVizHeight] = useState({
    viz: "460px",
    timeSeries: "500px",
  });

  const handleExpand = () =>
    setDataVizHeight((prev) => ({
      viz: prev.viz === "460px" ? "70vh" : "460px",
      timeSeries: prev.timeSeries === "500px" ? "860px" : "500px",
    }));

  return (
    open && (
      <OuterContainer
        bgcolor="#ffffff"
        boxShadow="0 0 0 2px rgba(0,0,0,.1)"
        borderRadius={4}
        open={open}
      >
        <DataVizContent
          dataVizWellNumber={dataVizWellNumber}
          dataVizGraphType={dataVizGraphType}
          onClose={onClose}
          filterValues={filterValues}
          setFilterValues={changeFilterValues}
          currentSelectedTimeseriesData={currentSelectedTimeseriesData}
          isLoading={isLoading}
          error={error}
          dataVizHeight={dataVizHeight}
          handleExpand={handleExpand}
          filteredMutatedGraphData={filteredMutatedGraphData}
        />
      </OuterContainer>
    )
  );
};

export default DataViz;
