import React, { memo } from "react";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import styled from "styled-components/macro";

const ToggleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px 0;
`;

const ToggleButtonGroup = styled(ButtonGroup)`
  width: 100%;
`;

const ToggleButton = styled(Button)`
  flex: 1;
  background-color: ${({ active }) => (active ? "#3f51b5" : "#e0e0e0")};
  color: ${({ active }) => (active ? "#fff" : "#000")};
  &:hover {
    background-color: ${({ active }) => (active ? "#303f9f" : "#bdbdbd")};
  }
`;

const buttonConfigs = [
  { label: "Year", value: "Year" },
  { label: "Month", value: "Month" },
  { label: "Week", value: "Week" },
];

const GroupTypeToggleSwitch = ({ value, onChange }) => {
  const handleClick = (selectedValue) => {
    if (value !== selectedValue) {
      onChange(selectedValue);
    }
  };

  return (
    <ToggleContainer>
      <ToggleButtonGroup>
        {buttonConfigs.map(({ label, value: buttonValue }) => (
          <ToggleButton
            key={buttonValue}
            size="large"
            active={value === buttonValue ? 1 : 0}
            onClick={() => handleClick(buttonValue)}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ToggleContainer>
  );
};
export default memo(GroupTypeToggleSwitch);
