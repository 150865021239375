import React from "react";
import { Divider as MuiDivider, Grid, Typography } from "@material-ui/core";
import { useColorsOfWaterStagecoach } from "./useColorsOfWaterStagecoach";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import TimeseriesGraph from "../chartJS/TimeseriesGraph";
import Options from "../Options";

const Divider = styled(MuiDivider)(spacing);

const ColorsOfWaterStagecoach = React.memo(() => {
  const {
    streamGage,
    dataListStreamGages,
    handleStreamGage,
    timeInterval,
    handleTimeInterval,
    dateRange: { startDate, endDate },
    setDateRange,
    handleStartDate,
    handleEndDate,
    error,
    isLoading,
    refetch,
    data,
  } = useColorsOfWaterStagecoach();

  if (error) {
    return <p>Error fetching current week data analysis: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Colors of Water - Stagecoach" />
      <Typography variant="h3" gutterBottom display="inline">
        Colors of Water - Stagecoach
      </Typography>

      <Divider my={6} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TimeseriesGraph data={data} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <Options
            streamGages={dataListStreamGages}
            streamGage={streamGage}
            handleStreamGage={handleStreamGage}
            showStreamGageSelect={true}
            timeInterval={timeInterval}
            handleTimeInterval={handleTimeInterval}
            startDate={startDate}
            setDateRange={setDateRange}
            handleStartDate={handleStartDate}
            endDate={endDate}
            handleEndDate={handleEndDate}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default ColorsOfWaterStagecoach;
