import React from "react";
import styled from "styled-components/macro";

import {
  AppBar as MuiAppBar,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserDropdown from "./UserDropdown";
import StakeholderLinksAppBar from "../pages/presentation/Splash/StakeholderLinksAppBar";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.sidebar.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar style={{ minHeight: isSmallScreen ? "64px" : "86px" }}>
          <Grid container alignItems="center" style={{ justifyContent: "end" }}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="primary"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />

            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              item
            >
              <StakeholderLinksAppBar />
              <div style={{ marginLeft: "16px" }}>
                <UserDropdown />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default AppBarComponent;
