import React from "react";
import { AppBar as MuiAppBar } from "@material-ui/core";
import LandingAppBar from "./LandingAppBar";

function AppBar() {
  return (
    <MuiAppBar position="static">
      <LandingAppBar />
    </MuiAppBar>
  );
}

export default AppBar;
