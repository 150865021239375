import { Box } from "@material-ui/core";
import FilterControl from "../filters/filterControl";
import Filter from "../filters/filter";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useLayerStyles from "../hooks/useLayerStyles";

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersBar = ({
  filterValues,
  handleFilterValues,
  handleSelectAll,
  handleSelectNone,
  updateLayerStyles,
  graphModeVisible,
  isMapLoaded,
}) => {
  const { handleActiveStyle } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
  });

  useEffect(() => {
    if (!graphModeVisible) {
      handleActiveStyle("locationTypes");
    }
  }, [graphModeVisible]); //eslint-disable-line

  const filterOptions = ["locationTypes", "organizations"];

  const getMoreFiltersCount = (filterValues) => {
    const keys = [
      "hasContinuousDailyFlow",
      "hasHistoricalComparisonFlow",
      "hasContinuousDailyTemperature",
      "hasHistoricalComparisonTemperature",
      "hasWaterQualityData",
    ];
    return keys.filter((key) => filterValues[key].value).length;
  };

  return (
    <>
      <FiltersSection>
        <FiltersContainer>
          {filterOptions.map((filterKey) => (
            <FilterControlBlock
              key={filterKey}
              filterKey={filterKey}
              filterData={filterValues}
              handleFilterValues={handleFilterValues}
              handleSelectAll={handleSelectAll}
              handleSelectNone={handleSelectNone}
            />
          ))}
        </FiltersContainer>
      </FiltersSection>

      <FiltersSection>
        <FiltersContainer>
          <FilterControl
            width="135px"
            appliedCount={getMoreFiltersCount(filterValues)}
            label="Toggles"
          >
            <Box display="flex" flexDirection="column">
              <Filter
                label="Has Continuous Daily Flow"
                name="hasContinuousDailyFlow"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasContinuousDailyFlow?.value}
              />
              <Filter
                label="Has Historical Comparison Flow"
                name="hasHistoricalComparisonFlow"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasHistoricalComparisonFlow?.value}
              />

              <Filter
                label="Has Continuous Daily Temperature"
                name="hasContinuousDailyTemperature"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasContinuousDailyTemperature?.value}
              />
              <Filter
                label="Has Historical Comparison Temperature"
                name="hasHistoricalComparisonTemperature"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasHistoricalComparisonTemperature?.value}
              />

              <Filter
                label="Has WQ Data"
                name="hasWaterQualityData"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.hasWaterQualityData?.value}
              />
            </Box>
          </FilterControl>
        </FiltersContainer>
      </FiltersSection>
    </>
  );
};

function FilterControlBlock({
  filterKey,
  filterData,
  handleFilterValues,
  handleSelectAll,
  handleSelectNone,
}) {
  const { value, options, label, name, type } = filterData[filterKey];

  return (
    <FilterControl
      width="180px"
      appliedCount={value?.length}
      countText={`${value?.length}/${options?.length}`}
      label={label}
    >
      <Filter
        label={label}
        name={name}
        onChange={handleFilterValues}
        onSelectAll={handleSelectAll}
        onSelectNone={handleSelectNone}
        options={options}
        type={type}
        value={value}
      />
    </FilterControl>
  );
}

export default FiltersBar;
