import React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Card,
  CardMedia,
  CardHeader,
  Grid,
  Link,
} from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import styled from "styled-components/macro";
import useTheme from "@material-ui/core/styles/useTheme";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35) !important;
  position: relative;

  &:hover {
    outline: ${({ theme }) => `4px solid ${theme.palette.secondary.main}`};
    & .title-highlight {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

const StyledCardComponent = ({
  title,
  description,
  imagePath,
  height,
  children,
  objectFit = "cover",
  backgroundColor,
  theme,
  external = false,
}) => (
  <StyledCard $backgroundColor={backgroundColor} theme={theme}>
    <CardHeader
      titleTypographyProps={{ variant: "h5" }}
      subheaderTypographyProps={{ variant: "subtitle1" }}
      className="title-highlight"
      title={title}
      subheader={description}
    />
    <Box p={3} height={height} flexGrow={1}>
      {children || (
        <CardMedia
          style={{ objectFit: objectFit }}
          height="100%"
          component="img"
          image={imagePath}
          title={title}
          alt={`Image for ${title}`}
        />
      )}
    </Box>
    {external && (
      <Box position="absolute" top={5} right={5} zIndex={1}>
        <Launch style={{ fill: theme.palette.secondary.main }} />
      </Box>
    )}
  </StyledCard>
);

const CustomCard = ({
  title = "",
  description = "",
  imagePath,
  link = "#",
  xs = 12,
  md = 6,
  datacategoryPath,
  external = false,
  hasOptionalRouteParameter,
  objectFit = "contain",
  cols = 4,
  height = "200px",
  backgroundColor = "#fff",
}) => {
  const theme = useTheme();

  return (
    <Grid item xs={xs} md={md} lg={cols} xl={cols}>
      <Link
        component={external ? "a" : NavLink}
        {...(external
          ? { href: link, target: "_blank", rel: "noopener noreferrer" }
          : {
              to:
                hasOptionalRouteParameter && datacategoryPath
                  ? `${link}/${datacategoryPath}`
                  : link,
            })}
        underline="none"
      >
        <StyledCardComponent
          title={title}
          description={description}
          imagePath={imagePath}
          height={height}
          objectFit={objectFit}
          datacategoryPath={datacategoryPath}
          theme={theme}
          backgroundColor={backgroundColor}
          external={external}
        />
      </Link>
    </Grid>
  );
};

export default CustomCard;
