/**
 * Utility that can be used to log out map events
 * Useful for debugging and getting visibility into different
 * parts of the map lifecycle
 */
class MapLogger {
  constructor({ enabled = false, prefix }) {
    this.prefix = prefix;
    this.enabled = enabled;
  }

  log(event) {
    if (this.enabled) {
      console.log(`${this.prefix} - ${event}`);
    }
  }
}

export const coordinatesGeocoder = function (query) {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
    return null;
  }

  function coordinateFeature(lng, lat) {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place_name: "Lat: " + lat + " Lng: " + lng,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature",
    };
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  if (coord1 >= -90 && coord1 <= 90 && coord2 >= -180 && coord2 <= 180) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (coord2 >= -90 && coord2 <= 90 && coord1 >= -180 && coord1 <= 180) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  return geocodes;
};

/**
 * Checks if a string represents a valid latitude.
 * Latitude values should be between -90 and 90 degrees.
 * @param {string} latitude - The latitude value as a string.
 * @returns {boolean} True if the latitude is valid, false otherwise.
 */
const isValidLatitude = (latitude) => {
  const lat = parseFloat(latitude);
  return !isNaN(lat) && lat >= -90 && lat <= 90;
};

/**
 * Checks if a string represents a valid longitude.
 * Longitude values should be between -180 and 180 degrees.
 * @param {string} longitude - The longitude value as a string.
 * @returns {boolean} True if the longitude is valid, false otherwise.
 */
const isValidLongitude = (longitude) => {
  const lon = parseFloat(longitude);
  return !isNaN(lon) && lon >= -180 && lon <= 180;
};

/**
 * Checks if a string represents a valid zoom level.
 * Zoom level values should be integers between 0 and 23.
 * @param {string} zoomLevel - The zoom level as a string.
 * @returns {boolean} True if the zoom level is valid, false otherwise.
 */
const isValidZoomLevel = (zoomLevel) => {
  const zoom = parseInt(zoomLevel);
  return !isNaN(zoom) && zoom >= 0 && zoom <= 23;
};

/**
 * Validates the latitude, longitude, and zoom level for a map fly-to location.
 * @param {string} longitude - The longitude value as a string.
 * @param {string} latitude - The latitude value as a string.
 * @param {string} zoomLevel - The zoom level as a string.
 * @returns {boolean} True if all parameters represent valid values, false otherwise.
 */
export const isValidMapFlyToLocation = (longitude, latitude, zoomLevel) => {
  return (
    isValidLongitude(longitude) &&
    isValidLatitude(latitude) &&
    isValidZoomLevel(zoomLevel)
  );
};

export { MapLogger };
