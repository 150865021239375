import React, { useState, useMemo, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Chip,
  TextField,
  Button,
  ButtonGroup,
  Tooltip,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Search, Cancel } from "@material-ui/icons";
import useDebounce from "../../hooks/useDebounce";
import Loader from "../Loader";
import { Pagination } from "@material-ui/lab";
import {
  DEFAULT_PAGINATION,
  PAGINATION_OPTIONS,
} from "../../pages/dataAccess/queryAndDownload/constants";

const SearchTextField = styled(TextField)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const SearchButtonGroup = styled(ButtonGroup)`
  height: 53px;
  .MuiButton-root {
    width: 80px;
  }
`;

const SearchButton = styled(Button)`
  &:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const ChipsContainer = styled.div`
  background-color: #fafafa;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.palette.error.main : "#ddd")};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 16px;
  margin-top: 5px;
`;

const filterOptions = (
  options,
  searchTerm,
  displayField,
  showOnlySelected,
  watchedValues,
  valueField
) => {
  return options.filter(
    (option) =>
      option[displayField].toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!showOnlySelected || watchedValues.includes(option[valueField]))
  );
};

export const ChipSelect = ({
  name,
  label,
  valueField,
  displayField,
  tooltipField,
  options,
  isLoading,
  debounceDelay = 100,
}) => {
  const {
    watch,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGINATION);
  const [dropdownValue, setDropdownValue] = useState("" + DEFAULT_PAGINATION);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
  const watchedValues = watch(name);

  const filteredOptions = useMemo(() => {
    return filterOptions(
      options,
      debouncedSearchTerm,
      displayField,
      showOnlySelected,
      watchedValues,
      valueField
    );
  }, [
    options,
    debouncedSearchTerm,
    displayField,
    showOnlySelected,
    watchedValues,
    valueField,
  ]);

  useEffect(() => {
    const totalPages =
      itemsPerPage === "All"
        ? 1
        : Math.ceil(filteredOptions.length / itemsPerPage);
    if (currentPage > totalPages) setCurrentPage(totalPages || 1);
  }, [filteredOptions.length, currentPage, itemsPerPage]);

  const paginatedOptions = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex =
      itemsPerPage === "All"
        ? filteredOptions.length
        : startIndex + itemsPerPage;
    return filteredOptions.slice(
      startIndex,
      Math.min(endIndex, filteredOptions.length)
    );
  }, [filteredOptions, currentPage, itemsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toggleSelectedView = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const handleSelectAll = async () => {
    setValue(
      name,
      filteredOptions.map((option) => option[valueField]),
      { shouldDirty: true }
    );

    if (!!Object.keys(errors).length) {
      await trigger(name);
    }
  };

  const handleDeselectAll = async () => {
    const valuesToDeselect = filteredOptions.map(
      (option) => option[valueField]
    );
    const updatedValues = getValues(name).filter(
      (value) => !valuesToDeselect.includes(value)
    );
    setValue(name, updatedValues, { shouldDirty: true });

    if (!!Object.keys(errors).length) {
      await trigger(name);
    }
  };

  function handleChipClick(field, optionValue) {
    const newValue = field.value.includes(optionValue)
      ? field.value.filter((v) => v !== optionValue)
      : [...field.value, optionValue];
    field.onChange(newValue);
  }

  const isDisabled = options.length === 0 || isLoading;
  if (isLoading) return <Loader />;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth error={Boolean(errors[name])}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <SearchTextField
              fullWidth
              variant="outlined"
              label={`Search ${label}`}
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={isDisabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end" style={{ marginRight: "5px" }}>
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchTerm("")}
                      edge="end"
                    >
                      <Cancel />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <SearchButtonGroup
              variant="outlined"
              color="primary"
              disabled={isDisabled}
            >
              <SearchButton variant="contained" onClick={handleSelectAll}>
                + All
              </SearchButton>
              <SearchButton onClick={handleDeselectAll}>- None</SearchButton>
            </SearchButtonGroup>
          </Box>
          <ChipsContainer error={Boolean(errors[name])}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
              style={{ width: "100%" }}
            >
              <Button
                onClick={toggleSelectedView}
                variant="contained"
                color={showOnlySelected ? "primary" : "default"}
              >
                {showOnlySelected ? "Show All" : "Show Selected"}
              </Button>
              <Pagination
                style={{ margin: "0 10px" }}
                count={
                  itemsPerPage === "All"
                    ? 1
                    : Math.ceil(filteredOptions.length / itemsPerPage)
                }
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
              <FormControl variant="outlined" size="small">
                <Select
                  value={dropdownValue}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setDropdownValue(selectedValue);
                    setItemsPerPage(
                      selectedValue === "All"
                        ? filteredOptions.length
                        : parseInt(selectedValue, 10)
                    );
                    setCurrentPage(1);
                  }}
                  displayEmpty
                >
                  {PAGINATION_OPTIONS.map((number) => (
                    <MenuItem key={number} value={number.toString()}>
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {paginatedOptions.length ? (
              paginatedOptions.map((option) => (
                <Tooltip
                  key={option[valueField]}
                  title={option[tooltipField] || ""}
                >
                  <Chip
                    label={option[displayField]}
                    onClick={() => handleChipClick(field, option[valueField])}
                    color={
                      field.value.includes(option[valueField])
                        ? "primary"
                        : "default"
                    }
                    clickable
                  />
                </Tooltip>
              ))
            ) : (
              <Typography
                align="center"
                variant="subtitle1"
                style={{ width: "100%" }}
              >
                No options {showOnlySelected ? "selected" : "available"}
              </Typography>
            )}
          </ChipsContainer>
          {!!errors[name] && (
            <FormHelperText style={{ marginLeft: "16px" }}>
              {errors[name]?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
