import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { twentyFourHoursInMs } from "../../../../utils";
import { useParams } from "react-router-dom";

// Fetch a list of layers to add to the map
const useLayers = () => {
  const [layers, setLayers] = useState([]);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { datacategoryPath } = useParams();

  const { data, isError, isLoading } = useQuery(
    ["Layers", isAuthenticated],
    async () => {
      let headers = {};
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        headers = { Authorization: `Bearer ${token}` };
      }
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/layers`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: twentyFourHoursInMs,
      cacheTime: twentyFourHoursInMs,
    }
  );

  useEffect(() => {
    if (data) {
      const updatedLayers = data.map((item) => {
        // Determine the group to check against based on the presence of datacategoryPath
        const checkGroup = datacategoryPath || "default";

        // Check if the item has visibleOnLoadForGroups defined and if it should be visible
        const shouldBeVisible =
          item?.lreProperties?.visibleOnLoadForGroups?.includes(checkGroup) ??
          false;

        if (shouldBeVisible) {
          return {
            ...item,
            layout: {
              ...item.layout,
              visibility: "visible",
            },
          };
        }
        return item;
      });
      setLayers(updatedLayers);
    }
  }, [data, datacategoryPath]);

  return {
    isLoading,
    isError,
    layers,
    setLayers,
  };
};

export default useLayers;
