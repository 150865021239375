import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Grid, Box, Chip } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../hooks/useFetchData";
import useFormSubmitStatus from "../../../hooks/useFormSubmitStatus";
import { Flex } from "../../../components/Flex";

import FormSnackbar from "../../../components/FormSnackbar";

import SearchableList from "../../../components/SearchableList";

import ContactsListForGroupsManagementTable from "./ContactsListForGroupsManagementTable";

import styled from "styled-components/macro";
import AssociationControls from "./AssociationControls";

const Root = styled.div`
  margin-top: 6px;
  width: 100%;
`;

const GroupsToContactsAssoc = () => {
  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { setWaitingState, snackbarOpen, snackbarError, handleSnackbarClose } =
    useFormSubmitStatus();

  const [activeGroup, setActiveGroup] = useState({});
  const [associatedContacts, setAssociatedContacts] = useState([]);

  const [Groups] = useFetchData("list-groups", [refreshSwitch]);
  const [Contacts] = useFetchData(`list-contacts`, []);
  const [Associations] = useFetchData(`assoc-contacts-groups`, [refreshSwitch]);

  useEffect(() => {
    const activeAssociations = Associations.filter(
      (d) => activeGroup.group_ndx === d.group_ndx
    ).map((d) => d.contact_ndx);
    if (activeAssociations.length > 0 && activeAssociations[0] !== null) {
      setAssociatedContacts(activeAssociations);
    } else {
      setAssociatedContacts([]);
    }
  }, [Associations, activeGroup, refreshSwitch]);

  const handleRefresh = () => {
    setRefreshSwitch((state) => !state);
  };

  const handleGroupsSelect = (group) => {
    setAssociatedContacts([]);
    setActiveGroup(group);
  };

  const handleContactsSelect = (rowData, row) => {
    const value = row.contact_ndx;
    const checked = row.tableData.checked;
    setAssociatedContacts((prevState) => {
      let newValues = [...prevState];
      if (checked) {
        newValues.push(+value);
      } else {
        const index = newValues.indexOf(+value);
        newValues.splice(index, 1);
      }
      return newValues;
    });
  };

  const handleSelectNone = () => setAssociatedContacts([]);

  const handleSelectAll = () =>
    setAssociatedContacts(Contacts.map((d) => d.contact_ndx));

  const prepareValues = () => {
    return associatedContacts.map((associatedContact) => {
      return {
        group_ndx: activeGroup.group_ndx,
        contact_ndx: associatedContact,
      };
    });
  };

  const handleSubmit = () => {
    // Set up a cancellation source
    let didCancel = false;
    setWaitingState("in progress");
    async function writeData() {
      try {
        const token = await getAccessTokenSilently();

        const headers = { Authorization: `Bearer ${token}` };

        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/assoc-contacts-groups/${activeGroup.group_ndx}`,
          prepareValues(),
          { headers }
        );
        if (!didCancel) {
          console.log("success");
          setWaitingState("complete", "no error");
          handleRefresh();
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
          setWaitingState("complete", "error");
        }
        didCancel = true;
      }
    }
    writeData();
  };

  return (
    <Root>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <SearchableList
            title="Groups"
            data={Groups}
            valueField="group_ndx"
            primaryDisplayField="group_desc"
            active={activeGroup}
            onClick={handleGroupsSelect}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box marginTop={2} width="100%">
            <Flex>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Manage Contacts Associations:
              </Typography>

              {activeGroup.group_desc && (
                <Box marginTop={2} marginBottom={2} marginLeft={2}>
                  <Chip label={activeGroup.group_desc} />
                </Box>
              )}
            </Flex>

            {activeGroup.group_desc && (
              <AssociationControls
                handleSave={handleSubmit}
                handleSelectAll={handleSelectAll}
                handleSelectNone={handleSelectNone}
              />
            )}

            {activeGroup.group_desc ? (
              <ContactsListForGroupsManagementTable
                selections={associatedContacts}
                onCheck={handleContactsSelect}
                refreshSwitch={refreshSwitch}
              />
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  Select a group from the left to associate them with contacts.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <FormSnackbar
        open={snackbarOpen}
        error={snackbarError}
        handleClose={handleSnackbarClose}
        successMessage="Associations successfully saved."
        errorMessage="Associations could not be saved."
      />
    </Root>
  );
};

export default GroupsToContactsAssoc;
