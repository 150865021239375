import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { MultiSelect, Select } from "@lrewater/lre-react";
import { groupoObjectByValue, lineColors } from "../../../utils";
import Button from "@material-ui/core/Button";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  lighten,
  Typography as MuiTypography,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import { customSecondary } from "../../../theme/variants";
import { Alert } from "@material-ui/lab";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import WaterYearComparisonMap from "./WaterYearComparisonMap";
import WaterYearLineChart from "./WaterYearLineChart";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const SidebarSection = styled(MuiTypography)`
  ${spacing};
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const MapContainer = styled.div`
  height: 312px;
  width: 100%;
`;

const WaterYearComparison = () => {
  const saveRef = useRef(null);

  const [filterValues, setFilterValues] = useState({
    parameter: "",
    location: "",
    series: [
      "Minimum",
      "Median",
      "Maximum",
      (new Date().getFullYear() + 1).toString(),
      new Date().getFullYear().toString(),
    ],
  });

  const { data: Parameters } = useQuery(
    ["wy-analysis-dropdown-parameters"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-parameters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Locations } = useQuery(
    ["wy-analysis-dropdown-locations-assoc-param"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-locations-assoc-param`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Series } = useQuery(
    ["wy-analysis-dropdown-series"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-dropdown-series`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const filterLocationsByParameter = (locations, parameter) => {
    return locations?.filter((location) =>
      location?.parameter_ndx_array?.includes(parameter)
    );
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterValues((prevState) => {
      let newValues = { ...prevState };

      if (name === "parameter") {
        if (
          filterValues.location &&
          !Locations.find(
            (location) => location.location_ndx === filterValues?.location
          ).parameter_ndx_array?.includes(value)
        ) {
          newValues["location"] = "";
        }
      }

      newValues[name] = value;

      return newValues;
    });
  };

  const { data, error, isFetching, refetch } = useQuery(
    [
      `api/wy-analysis-comparison/${filterValues.parameter}/${filterValues.location}/${filterValues.series}`,
    ],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-comparison/${filterValues.parameter}/${filterValues.location}/${filterValues.series}`
        );

        const groupedData = groupoObjectByValue(data, "series_desc");

        return groupedData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const tableColumns = [
    { title: "Parameter", field: "parameter_name" },
    { title: "Location", field: "location_name" },
    { title: "Series", field: "series_desc" },
    { title: "Date", field: "day_desc" },
    { title: "Day", field: "day_order" },

    {
      title: "Result",
      field: "rvalue",
    },
    { title: "Units", field: "unit_name" },
  ];

  const seriesColor = {
    Maximum: "#e6194b",
    Median: "#000",
    Minimum: "#4363d8",
  };

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      const units = Parameters.find(
        (parameter) => parameter.parameter_ndx === filterValues.parameter
      ).units_name;
      let count = data.length;
      const graphData = {
        yLLabel: data?.length
          ? `${data[0][0]?.parameter_name} (${units})`
          : null,
        labels: data[0]?.map((item) => item.day_desc),
        datasets: [
          ...data.map((location) => {
            count--;
            return {
              data: location.map((item) => item.rvalue),
              yAxisID: "yL",
              units: units,
              pointStyle: "circle",
              fill: false,
              borderWidth: ["Maximum", "Median", "Minimum"].includes(
                location[count]?.series_desc
              )
                ? 3
                : 2,
              pointRadius: 0,
              pointHoverRadius: 4,
              label: location[0].series_desc,
              borderColor:
                seriesColor[location[count]?.series_desc] ??
                Object.values(lineColors)[count],
              backgroundColor: seriesColor[location[count]?.series_desc]
                ? lighten(seriesColor[location[count]?.series_desc], 0.5)
                : lighten(Object.values(lineColors)[count], 0.5),
            };
          }),
        ],
      };
      setGraphData(graphData);
    }
  }, [data]); //eslint-disable-line

  return (
    <>
      <Helmet title="Water Year Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Year Comparisons
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={5}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <WaterYearComparisonMap
                  locations={
                    filterValues.parameter
                      ? filterLocationsByParameter(
                          Locations,
                          filterValues.parameter
                        )?.map((location) => location.location_ndx)
                      : Locations?.map((location) => location.location_ndx)
                  }
                  selectedLocation={[filterValues.location]}
                />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <>
          <Grid item xs={12} md={12} lg={12} xl={7}>
            <Accordion defaultExpanded style={{ height: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container pb={6} mt={2}>
                  <Grid item xs={12}>
                    <SidebarSection>Series Lines</SidebarSection>
                    <MultiSelect
                      name="series"
                      label="Series"
                      variant="outlined"
                      valueField="series_desc"
                      displayField="series_desc"
                      outlineColor="primary"
                      labelColor="primary"
                      margin="normal"
                      data={Series || []}
                      value={filterValues.series}
                      onChange={handleFilter}
                      style={{ width: "calc(100% - 8px)" }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SidebarSection>Filters</SidebarSection>
                    <Select
                      name="parameter"
                      label="Parameters"
                      variant="outlined"
                      valueField="parameter_ndx"
                      displayField="parameter_name"
                      outlineColor="primary"
                      labelColor="primary"
                      margin="normal"
                      data={Parameters || []}
                      value={filterValues.parameter}
                      onChange={handleFilter}
                      style={{ width: "calc(50% - 8px)" }}
                    />
                    <Select
                      name="location"
                      label="Locations"
                      variant="outlined"
                      valueField="location_ndx"
                      displayField="location_name"
                      outlineColor="primary"
                      labelColor="primary"
                      margin="normal"
                      data={
                        filterLocationsByParameter(
                          Locations,
                          filterValues.parameter
                        ) || []
                      }
                      value={filterValues.location}
                      onChange={handleFilter}
                      style={{ width: "calc(50% - 8px)" }}
                    />
                  </Grid>

                  <SubmitGrid item container>
                    <Grid item style={{ width: "calc(100% - 162px)" }}>
                      {!data && (
                        <Alert severity="info">
                          After selecting your inputs, click the red 'Submit'
                          button to load an interactive time series plot for
                          comparison across different water years.
                        </Alert>
                      )}
                    </Grid>
                    <Grid item>
                      <SaveGraphButton
                        disabled={!data?.length}
                        ref={saveRef}
                        title="Water Year Comparison Graph"
                      />
                      <Button
                        onClick={() => refetch()}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        size="large"
                        style={{ marginLeft: "10px" }}
                        disabled={
                          !filterValues.series.length > 0 ||
                          !filterValues.location ||
                          isFetching
                        }
                      >
                        Submit
                      </Button>
                    </Grid>
                  </SubmitGrid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      </Grid>

      {data && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Graph
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <TimeseriesContainer>
                    <TableWrapper>
                      <WaterYearLineChart
                        // yLReverse={filterValues.parameterLeft === 7}
                        // yRReverse={filterValues.parameterRight === 7}
                        title={
                          data?.length &&
                          `${data[0][0]?.location_name} ${
                            data[0][0]?.parameter_name
                          } (${
                            Parameters.find(
                              (parameter) =>
                                parameter.parameter_ndx ===
                                filterValues.parameter
                            ).units_name
                          })`
                        }
                        subtitle="*Statistics are calculated for the NRCS 30-Year Hydroclimatic Normals period of 1991 to 2020"
                        data={graphData}
                        error={error}
                        isLoading={isFetching}
                        locationsOptions={Locations}
                        yLLabel={graphData?.yLLabel}
                        ref={saveRef}
                      />
                    </TableWrapper>
                  </TimeseriesContainer>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}

      {data && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  Table
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <TableWrapper>
                    <Table
                      isLoading={isFetching}
                      label="Water Years Comparison Table"
                      columns={tableColumns}
                      data={[...[].concat.apply([], data)]?.filter(
                        (d) => d.rvalue
                      )}
                      height="590px"
                    />
                  </TableWrapper>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WaterYearComparison;
