import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Modal, Paper } from "@material-ui/core";
import "./styles.css";

import Map from "./map";
import ZoomInfo from "./controls/zoomInfo";
import { useMap } from "./hooks/useMap";
import useFilters from "./hooks/useFilters";

import { INIT_MAP_CONFIG } from "./constants";

import MeasurementsPopup from "../../components/map/components/MeasurementsPopup";
import MainControl from "./controls/mainControl/";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import MeasurementsControl from "./controls/MeasurementsControl";
import GraphModeToggle from "./controls/graphModeToggle";

import FiltersBar from "./components/FiltersBar";
import FiltersBarGraphMode from "./components/FiltersBarGraphMode";
import GraphModeControl from "./controls/graphModeControl";
import Search from "./filters/search";
import useGraphMode from "./hooks/useGraphMode";
import DataVizGraphMode from "./components/DataVizGraphMode";
import DataVizGraphModeControl from "./controls/dataVizGraphModeControl";
import Legend from "./components/Legend";
import LegendControl from "./controls/LegendControl";
import GraphModeLayersToggle from "./controls/GraphModeLayersToggle";
import GraphModePopupToggle from "./controls/GraphModePopupToggle";
import Loader from "../../components/Loader";
import DataVizControl from "./controls/dataVizControl";
import DataViz from "./components/DataViz";
import useImagesCache from "./hooks/useImagesCache/";
import { useParams } from "react-router-dom";
import { isValidMapFlyToLocation } from "./hooks/useMap/mapUtils";
import MapSourceReferences from "../../components/map/components/MapSourceReferences";

const FiltersBarRoot = styled(Paper)`
  height: 83px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(6)}px;
  padding: 12px 16px 12px 32px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex-grow: 100;
`;

const PublicMap = () => {
  const { longitude, latitude, zoomLev } = useParams();

  const initMapConf = { ...INIT_MAP_CONFIG };
  if (isValidMapFlyToLocation(longitude, latitude, zoomLev)) {
    initMapConf.flyTo = [parseFloat(longitude), parseFloat(latitude)];
    initMapConf.flyToZoom = zoomLev;
  }
  const [mapConfig] = useState(initMapConf);
  const mapContainer = useRef(null);

  const imageCache = useImagesCache();

  const {
    activeBasemap,
    basemaps,
    layers,
    sources,
    map,
    zoomLevel,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateBasemap,
    measurementsVisible,
    handleClearMeasurements,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    eventsRegistered,
    lastLocationIdClicked,
    setLastLocationIdClicked,
    dataVizGraphModeVisible,
    setDataVizGraphModeVisible,
    graphModeVisible,
    setGraphModeVisible,
    dataVizVisible,
    setDataVizVisible,
    dataVizWellNumber,
    dataVizGraphType,
    isMapLoaded,
  } = useMap(mapContainer, mapConfig, imageCache);
  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
    setFilterValues,
  } = useFilters({ onFilterChange: updateLayerFilters, isMapLoaded });

  const {
    filterValuesGraphMode,
    periodOfRecords,
    analysisTypes,
    parameterGroups,
    threats,
    parameters,
    handleFilterValuesGraphMode,
    onSelectAllParameters,
    onSelectNoneParameters,
    handleGraphModeClick,
    hasGraphDataLoaded,
    analyticsResults,
    timeSeriesResults,
    isTimeSeriesResultsLoading,
    getHexColorForScore,
    isAnalyticsTableDataLoading,
    legendVisible,
    setLegendVisible,
    graphModeBenchmarkColors,
    handleGraphModeLayersToggleClick,
    graphModeLayersVisible,
    graphModePopupVisible,
    setGraphModePopupVisible,
    inputValue,
    setInputValue,
    handleExportClick,
    isExportLoading,
  } = useGraphMode({
    map,
    updateLayerFilters,
    layers,
    setDataVizGraphModeVisible,
    lastLocationIdClicked,
    setLastLocationIdClicked,
    graphModeVisible,
    setGraphModeVisible,
    setFilterValues,
    setDataVizVisible,
  });

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = [
    "Print PDF",
    "Save PNG",
    "Export Time Series",
    "Export Stats & Benchmarks",
  ];
  const handleSplitButtonClick = (index) => {
    if ([2, 3].includes(index)) {
      handleExportClick(index);
      return;
    }

    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const handleSearchSelect = (result) => {
    map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Modal open={!sources.length}>
        <div style={{ height: "100%" }}>
          <Loader height="120px" width="120px" />
        </div>
      </Modal>
      <FiltersBarRoot>
        <FiltersContainer>
          <FiltersSectionRow>
            <Search onSelect={handleSearchSelect} />
          </FiltersSectionRow>
        </FiltersContainer>
        {graphModeVisible && (
          <FiltersBarGraphMode
            filterValues={filterValuesGraphMode}
            periodOfRecords={periodOfRecords}
            analysisTypes={analysisTypes}
            handleFilterValues={handleFilterValuesGraphMode}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
        <FiltersBar
          filterValues={filterValues}
          handleFilterValues={handleFilterValues}
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          updateLayerStyles={updateLayerStyles}
          graphModeVisible={graphModeVisible}
          isMapLoaded={isMapLoaded}
        />

        <FiltersSection>
          <FiltersContainer>
            <>
              {isExportLoading ? (
                <div style={{ width: "262px" }}>
                  <Loader />
                </div>
              ) : (
                <SplitButton
                  options={splitButtonOptions}
                  handleExportClick={handleSplitButtonClick}
                  graphModeVisible={graphModeVisible}
                />
              )}

              <PrintReportDialog
                downloadCallback={handlePrintMapClick}
                setPrintReportDialogOpen={setPrintReportDialogOpen}
                printReportDialogOpen={printReportDialogOpen}
                title={title}
                setTitle={setTitle}
              />
            </>
          </FiltersContainer>
        </FiltersSection>
      </FiltersBarRoot>
      <Map ref={mapContainer}>
        {process.env.NODE_ENV === "development" && (
          <ZoomInfo zoomLevel={zoomLevel} />
        )}

        <MeasurementsPopup
          measurementsContainerRef={measurementsContainerRef}
          radiusRef={radiusRef}
          polygonRef={polygonRef}
          pointRef={pointRef}
          lineRef={lineRef}
          onHide={() => setMeasurementsVisible(false)}
          onClear={handleClearMeasurements}
        />
        {eventsRegistered && graphModeVisible ? (
          <GraphModeControl
            filterValues={filterValuesGraphMode}
            handleFilterValues={handleFilterValuesGraphMode}
            parameterGroups={parameterGroups}
            parameters={parameters}
            threats={threats}
            onSelectAllParameters={onSelectAllParameters}
            onSelectNoneParameters={onSelectNoneParameters}
          />
        ) : (
          <MainControl
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            layers={layers}
            onBasemapChange={updateBasemap}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            imageCache={imageCache}
          />
        )}
        {!graphModeVisible && (
          <>
            <DataVizControl
              open={dataVizVisible}
              onClose={() => setDataVizVisible(!dataVizVisible)}
            />

            <DataViz
              open={dataVizVisible}
              dataVizWellNumber={dataVizWellNumber}
              dataVizGraphType={dataVizGraphType}
              onClose={() => setDataVizVisible(false)}
            />
          </>
        )}

        {graphModeVisible && (
          <DataVizGraphMode
            open={dataVizGraphModeVisible}
            onClose={() => setDataVizGraphModeVisible(false)}
            analyticsResults={analyticsResults}
            timeSeriesResults={timeSeriesResults}
            filterValues={filterValuesGraphMode}
            isTimeSeriesResultsLoading={isTimeSeriesResultsLoading}
            getHexColorForScore={getHexColorForScore}
            isAnalyticsTableDataLoading={isAnalyticsTableDataLoading}
            lastLocationIdClicked={lastLocationIdClicked}
          />
        )}

        {eventsRegistered && hasGraphDataLoaded && filterValues && (
          <GraphModeToggle
            open={graphModeVisible}
            handleClick={handleGraphModeClick}
          />
        )}

        <MapSourceReferences />

        {graphModeVisible && (
          <>
            <DataVizGraphModeControl
              open={dataVizGraphModeVisible}
              handleClick={() =>
                setDataVizGraphModeVisible(!dataVizGraphModeVisible)
              }
            />
            <LegendControl
              open={legendVisible}
              onToggle={() => setLegendVisible(!legendVisible)}
            />
            {legendVisible && (
              <Legend legendColors={graphModeBenchmarkColors} />
            )}
            <GraphModeLayersToggle
              open={graphModeLayersVisible}
              onToggle={handleGraphModeLayersToggleClick}
            />
            <GraphModePopupToggle
              open={graphModePopupVisible}
              onToggle={() => setGraphModePopupVisible(!graphModePopupVisible)}
            />
            {!graphModePopupVisible && (
              <div
                dangerouslySetInnerHTML={{
                  __html: "<style>.mapboxgl-popup { display: none; }</style>",
                }}
              />
            )}
          </>
        )}

        {!measurementsVisible && (
          <MeasurementsControl
            open={measurementsVisible}
            onToggle={() => setMeasurementsVisible(!measurementsVisible)}
            right={49}
            bottom={30}
          />
        )}
      </Map>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </div>
  );
};

export default PublicMap;
