import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// For routes that can only be accessed by admin users
const DeveloperVisibilityFilter = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();

  // Immediately return Unauthorized if not authenticated to avoid unnecessary checks
  if (!isAuthenticated || !user) return <React.Fragment />;

  // Extract roles directly with a default to an empty array for safe chaining
  const roles = user[`${process.env.REACT_APP_AUDIENCE}/roles`] || [];

  // Check for authorized roles
  const isAuthorizedUser = roles.some((role) => ["Developer"].includes(role));

  // Render Unauthorized component if the user does not have the right roles
  if (!isAuthorizedUser) {
    return <React.Fragment />;
  }

  // User is authenticated and authorized, render the children components
  return children;
};

export default DeveloperVisibilityFilter;
