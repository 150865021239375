import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Radio,
  List,
  ListItem,
  ListItemText,
  Typography as MuiTypography,
  Button,
} from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";
import { Launch } from "@material-ui/icons";

const Typography = styled(MuiTypography)(spacing);

const getLegendOptions = (layer) => {
  const colorProperty = `${layer?.type}-color`;

  let color;
  if (colorProperty === "raster-color") {
    color = layer.lreProperties.paint["legend-color"];
  } else {
    color = layer?.paint?.[colorProperty];
  }

  if (!Array.isArray(color)) {
    return [{ color, text: layer.name }];
  }

  const colorsExpression = [...color];
  colorsExpression.splice(0, 2);

  const legendOptions = colorsExpression
    .map((value, index) => {
      if (index < colorsExpression.length - 1 && index % 2 === 0) {
        return {
          color: colorsExpression[index + 1],
          text: Array.isArray(value) ? value?.join(", ") : value,
        };
      }
      return null;
    })
    .filter((d) => d !== null);

  return legendOptions;
};

const LegendSymbol = ({ color }) => (
  <Box
    bgcolor={color}
    borderRadius="50%"
    height={12}
    width={12}
    style={{ minWidth: "12px" }}
  />
);

const getSymbol = (layer, imageCache, color) => {
  if (layer?.lreProperties?.customLegendSymbolUrl) {
    return <img src={layer.lreProperties.customLegendSymbolUrl} alt="" />;
  } else if (
    layer.id === "rivercalls" &&
    imageCache &&
    imageCache["star-legend"]
  ) {
    return <img src={imageCache["star-legend"].src} alt="" />;
  } else {
    return <LegendSymbol color={color} />;
  }
};

const LayerLegend = ({ item, imageCache }) => {
  const legendItems = getLegendOptions(item);
  return (
    <Box display="flex" flexDirection="column" gridRowGap={4} mb={2} mx={11}>
      {legendItems.map(({ color, text }) => (
        <Box key={text} display="flex" alignItems="center" gridColumnGap={8}>
          {getSymbol(item, imageCache, color)}
          <Typography color="textSecondary" variant="body2">
            {text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const TemperatureAndFlowsControl = ({ items, onLayerChange, imageCache }) => {
  const [selectedLayer, setSelectedLayer] = useState(null);

  const hasRun = useRef(false);

  useEffect(() => {
    if (items.length > 0 && !hasRun.current) {
      const selectedItem = items.find(
        (item) => item.layout.visibility === "visible"
      );

      if (selectedItem) {
        setSelectedLayer(selectedItem.id);
      }
      hasRun.current = true;
    }
  }, [items]);

  const uniqueItems = useMemo(() => {
    const uniqueItemIds = [
      ...new Set(
        items
          ?.sort((a, b) => {
            return (a.legendOrder ?? 0) > (b.legendOrder ?? 0) ? -1 : 1;
          })
          .map((item) => {
            return item?.lreProperties?.layerGroup || item.id;
          })
      ),
    ];

    return uniqueItemIds
      .reduce((acc, curr) => {
        const match = items.find((item) => {
          const id = item?.lreProperties?.layerGroup || item.id;
          return id === curr;
        });
        acc.push(match);
        return acc;
      }, [])
      ?.sort((a, b) => ((a.legendOrder || 0) > (b.legendOrder || 0) ? -1 : 1));
  }, [items]);

  const handleVisibilityChange = (item) => {
    const itemId = item?.lreProperties?.layerGroup || item.id;

    if (itemId === selectedLayer) {
      setSelectedLayer(null);
      onLayerChange({
        id: itemId,
        visible: false,
      });
    } else {
      setSelectedLayer(itemId);
      onLayerChange({
        id: itemId,
        visible: true,
        radioGroupToggle: true,
      });
    }
  };

  // const handleMultiLayerVisibility = (multiLayerGroupId, visibility) => {
  // onLayerChange({
  // id: multiLayerGroupId,
  // visible: visibility,
  // multiLayer: true,
  // });
  // };

  return (
    <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
      <List dense>
        {uniqueItems?.length === 0 && (
          <Box textAlign="center">
            <Typography variant="body1">No layers found</Typography>
          </Box>
        )}
        {uniqueItems.map((item) => {
          return (
            <Box key={item?.name} id="layerCheck">
              <ListItem
                onClick={() => {
                  handleVisibilityChange(item);
                }}
              >
                <Radio
                  edge="start"
                  checked={
                    selectedLayer ===
                    (item?.lreProperties?.layerGroup || item.id)
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": "test" }}
                />
                <ListItemText
                  primary={item?.name}
                  primaryTypographyProps={{
                    color:
                      selectedLayer ===
                      (item?.lreProperties?.layerGroup || item.id)
                        ? "textPrimary"
                        : "textSecondary",
                  }}
                />
              </ListItem>

              {!item?.lreProperties?.excludeLayerCollapse && (
                <LayerLegend
                  item={item}
                  handleVisibilityChange={handleVisibilityChange}
                  items={items}
                  imageCache={imageCache}
                />
              )}
            </Box>
          );
        })}
      </List>
      <Button
        component={Link}
        to="/tools/climate-and-flows-explorer"
        size="small"
        variant="contained"
        color="secondary"
        mt={4}
        style={{ textAlign: "center" }}
        target="_blank"
        rel="noreferrer noopener"
        startIcon={<Launch />}
      >
        Open Climate & Flows Explorer
      </Button>
      {/*<Box display="flex" style={{ gap: "10px" }}>*/}
      {/*  <Button*/}
      {/*    fullWidth*/}
      {/*    variant="outlined"*/}
      {/*    startIcon={*/}
      {/*      <PowerSettingsNewIcon*/}
      {/*        variant="contained"*/}
      {/*        style={{ color: "green" }}*/}
      {/*      />*/}
      {/*    }*/}
      {/*    onClick={() => {*/}
      {/*      handleMultiLayerVisibility("temperature-and-flow-map", true);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Show All*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    fullWidth*/}
      {/*    startIcon={<PowerSettingsNewIcon style={{ color: "red" }} />}*/}
      {/*    onClick={() => {*/}
      {/*      handleMultiLayerVisibility("temperature-and-flow-map", false);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Hide All**/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default memo(TemperatureAndFlowsControl);
