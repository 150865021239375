export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-108.147, 40.443];
export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 8.2,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "yampa-river-locations-circle";
export const WELLS_LABELS_LAYER_ID = "yampa-river-locations-symbol";
export const INIT_FILTER_VALUES = {
  locationTypes: {
    label: "Location Types",
    name: "locationTypes",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "locationtype",
    options: [],
    type: "multi-select",
    value: [],
  },
  organizations: {
    name: "organizations",
    label: "Organizations",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "organizations",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  parameterGroups: {
    name: "parameterGroups",
    label: "Parameters",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "parameter_groups",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  graphMode: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "ndx",
    type: "graphMode",
    value: [""],
  },
  hasContinuousDailyFlow: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_flow_graph",
    type: "boolean",
    value: false,
  },
  hasHistoricalComparisonFlow: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_flow_trends",
    type: "boolean",
    value: false,
  },
  hasContinuousDailyTemperature: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_temperature_graph",
    type: "boolean",
    value: false,
  },
  hasHistoricalComparisonTemperature: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "has_temperature_trends",
    type: "boolean",
    value: false,
  },
  hasWaterQualityData: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "is_wq_loc",
    type: "boolean",
    value: false,
  },
};

const layerId = "yampa-river-locations-circle";
export const INIT_STYLE_VALUES = {
  locationTypes: {
    id: "locationTypes",
    layerId,
    layerFieldName: "locationtype",
    name: "Location Types",
    options: [],
    type: "multi-select",
    value: [],
  },
};

export const UNIQUE_LEGEND_GROUPS = [
  "Monitoring Data",
  "Basemap",
  "Hydrology",
  "River Administration",
  "Water Quality",
  "Listed Streams by Parameter",
  "Land Cover/Land Use",
  "Historical Land Cover/Land Use",
];
