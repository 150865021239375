import { useEffect, useMemo, useRef, useState } from "react";
import { useQueries } from "react-query";
import axios from "axios";
import {
  BASE_URL,
  DEFAULT_INPUT_STATE,
  GROUP_TYPES,
  REQUIRED_NUMBER_OF_GRAPH_SELECTIONS,
  USE_QUERY_OPTIONS,
} from "./constants";
import { useLocation, useParams } from "react-router-dom";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import { normalizeDateToUTC, useCustomQuery } from "./utils";
import {
  endOfYear,
  format,
  startOfYear,
  subMonths,
  subWeeks,
  parse,
} from "date-fns";

const validateGraphIds = (graphIds, graphOptions, maxSelections) => {
  return graphIds
    .map((id) =>
      graphOptions.find((graph) => graph.tssum_group_ndx === parseInt(id))
    )
    .filter(Boolean)
    .slice(0, maxSelections);
};

const validateOption = (value, options, key) =>
  options.some((option) => option[key] === value);

const validateGroupType = (groupType) => {
  return GROUP_TYPES.includes(groupType);
};

const validateDateRange = (startDate, endDate) => {
  return (
    startDate instanceof Date &&
    !isNaN(startDate) &&
    endDate instanceof Date &&
    !isNaN(endDate) &&
    startDate <= endDate
  );
};

const useInitializeWithQueryParams = ({
  graphOptionsQuery,
  regionalGroupsQuery,
  yearsQuery,
  currentCategory,
  setInputState,
}) => {
  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (
      isInitialMount.current &&
      graphOptionsQuery.isSuccess &&
      regionalGroupsQuery.isSuccess &&
      yearsQuery.isSuccess &&
      !currentCategory
    ) {
      const params = new URLSearchParams(location.search);
      const newInputState = { ...DEFAULT_INPUT_STATE };

      if (params.has("graphs")) {
        const graphIds = params.get("graphs")?.split(",").filter(Boolean) || [];
        newInputState.graphs = validateGraphIds(
          graphIds,
          graphOptionsQuery.data,
          REQUIRED_NUMBER_OF_GRAPH_SELECTIONS
        );
      } else {
        newInputState.graphs = graphOptionsQuery.data.filter((d) =>
          d.datacategory_ndx_defaults.includes(0)
        );
      }

      if (params.has("regionalGroup")) {
        const regGroup = parseInt(params.get("regionalGroup"));
        if (
          validateOption(regGroup, regionalGroupsQuery.data, "reg_group_ndx")
        ) {
          newInputState.regionalGroup = regGroup;
        }
      }

      if (params.has("startDate") && params.has("endDate")) {
        const startDate = new Date(params.get("startDate"));
        const endDate = new Date(params.get("endDate"));

        if (validateDateRange(startDate, endDate)) {
          newInputState.startDate = startDate;
          newInputState.endDate = endDate;
        }
      }

      if (params.has("groupType")) {
        const groupType = params.get("groupType");
        if (validateGroupType(groupType)) {
          newInputState.groupType = groupType;
        }
      }

      setInputState(newInputState);
      isInitialMount.current = false;
    }
  }, [
    graphOptionsQuery,
    regionalGroupsQuery,
    yearsQuery,
    currentCategory,
    location.search,
    setInputState,
  ]);

  return { isInitialMount };
};

const useInitializeWithDataCategory = () => {
  const { datacategoryPath } = useParams();

  const currentCategory = useMemo(
    () =>
      Object.values(STAKEHOLDERS_CONFIG).find(
        (config) => datacategoryPath === config.datacategoryPath
      ),
    [datacategoryPath]
  );

  return currentCategory;
};

const useGraphQueries = (inputState, BASE_URL) => {
  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }

    if (
      inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS &&
      inputState.startDate &&
      inputState.endDate &&
      inputState.regionalGroup
    ) {
      cancelTokenSourceRef.current = axios.CancelToken.source();
    }
  }, [
    inputState.graphs,
    inputState.startDate,
    inputState.endDate,
    inputState.regionalGroup,
  ]);

  const graphQueries = useQueries(
    inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS &&
      inputState.startDate &&
      inputState.endDate
      ? inputState.graphs.map((graph) => ({
          queryKey: [
            graph.tssum_graph_route_name,
            inputState.regionalGroup,
            inputState.startDate,
            inputState.endDate,
          ],
          queryFn: async () => {
            const { startDate, endDate, groupType } = inputState;

            const isInvalidRange =
              (groupType === "Week" && subWeeks(endDate, 12) > startDate) ||
              (groupType === "Month" && subMonths(endDate, 12) > startDate);

            if (isInvalidRange) {
              return [];
            }
            try {
              const { data } = await axios.get(
                `${BASE_URL}/data/${graph.tssum_graph_route_name}`,
                {
                  params: {
                    regionalGroup: inputState.regionalGroup,
                    startDate: normalizeDateToUTC(inputState.startDate),
                    endDate: normalizeDateToUTC(inputState.endDate),
                  },
                  cancelToken: cancelTokenSourceRef.current.token,
                }
              );
              return data || [];
            } catch (error) {
              if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
                throw new Error("Query was canceled");
              } else {
                throw error;
              }
            }
          },
          onError: (error) => {
            if (error.message === "Query was canceled") {
              console.log("Canceled query will not cache data.");
            }
          },
          ...USE_QUERY_OPTIONS,
          staleTime: Infinity,
          cacheTime: Infinity,
          enabled: true,
        }))
      : []
  );

  return graphQueries;
};

export const useTimeSeriesSummaries = () => {
  const [inputState, setInputState] = useState(DEFAULT_INPUT_STATE);

  const currentCategory = useInitializeWithDataCategory();

  const regionalGroupsQuery = useCustomQuery(
    ["regional-groups"],
    "time-series-summaries/inputs/regional-groups"
  );

  const yearsQuery = useCustomQuery(
    ["years"],
    "time-series-summaries/inputs/list-explore-years"
  );

  const graphOptionsQuery = useCustomQuery(
    ["tssum-graph-options"],
    "time-series-summaries/inputs/tssum-graph-options"
  );

  // Filter graphOptions based on selected data category
  useEffect(() => {
    if (graphOptionsQuery.data && currentCategory) {
      const defaultGraphOptions = graphOptionsQuery.data.filter((d) =>
        d.datacategory_ndx_defaults.includes(currentCategory.ndx)
      );
      setInputState((prev) => ({ ...prev, graphs: defaultGraphOptions }));
    }
  }, [graphOptionsQuery.data, currentCategory]);

  // Filter graphOptions based on selected regionalGroup
  const filteredGraphOptions = useMemo(() => {
    if (!graphOptionsQuery.data) return [];
    return graphOptionsQuery.data.filter((option) =>
      option.reg_group_ndx_display.includes(inputState.regionalGroup)
    );
  }, [graphOptionsQuery.data, inputState.regionalGroup]);

  // Clean inputState.graphs when inputState.regionalGroup changes
  useEffect(() => {
    const validGraphs = inputState.graphs.filter((graph) =>
      filteredGraphOptions.some(
        (option) => option.caf_group_ndx === graph.caf_group_ndx
      )
    );

    if (validGraphs.length !== inputState.graphs.length) {
      setInputState((prev) => ({ ...prev, graphs: validGraphs }));
    }
  }, [filteredGraphOptions, inputState.graphs, inputState.regionalGroup]);

  const graphQueries = useGraphQueries(inputState, BASE_URL);

  const { isInitialMount } = useInitializeWithQueryParams({
    graphOptionsQuery,
    regionalGroupsQuery,
    yearsQuery,
    currentCategory,
    setInputState,
  });

  const isValidDateRange = useMemo(() => {
    if (inputState.groupType === "Week") {
      return subWeeks(inputState.endDate, 12) <= inputState.startDate;
    } else if (inputState.groupType === "Month") {
      return subMonths(inputState.endDate, 12) <= inputState.startDate;
    }
    return true;
  }, [inputState.groupType, inputState.startDate, inputState.endDate]);

  // Update URL when inputState changes, but avoid running on the initial mount
  useEffect(() => {
    if (!isInitialMount.current) {
      const params = new URLSearchParams();
      Object.entries(inputState).forEach(([key, value]) => {
        if (key === "graphs" && value.length > 0) {
          const graphIds = value
            .map((graph) => graph.tssum_group_ndx)
            .join(",");
          params.set(key, graphIds);
        } else if (value !== undefined) {
          params.set(key, value);
        }
      });
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      if (window.location.search !== `?${params.toString()}`) {
        window.history.replaceState(
          { ...window.history.state, url: newUrl },
          "",
          newUrl
        );
      }
    }
  }, [inputState, isInitialMount]);

  const chartData = useMemo(() => {
    if (graphQueries.some((query) => query.isLoading || query.isError)) {
      return [];
    }

    const data = graphQueries.map((query, index) => {
      const graph = inputState.graphs[index];

      if (!isValidDateRange || !query.data) {
        return { data: [], ui: { legendItems: [], ui: null, graph: {} } };
      }

      const dateFormat =
        inputState.groupType === "Year"
          ? "yyyy"
          : inputState.groupType === "Month"
          ? "MMM-yyyy"
          : "wo 'of' yy'''";

      const formattedData = query.data.map((d) => {
        // Parse the date string without converting to local time
        const date = parse(d.collect_date, "yyyy-MM-dd", new Date());
        const formattedDate = format(date, dateFormat);

        return {
          group: formattedDate,
          subGroup: d.location_id,
          value: d.daily_val,
          units: d.units,
          parameter: graph.y_axis_units,
          tooltip: d.location_display_label,
        };
      });

      const legendItems = formattedData.reduce((acc, current) => {
        const existingItem = acc.find(
          (item) =>
            item.label === current.subGroup && item.tooltip === current.tooltip
        );
        if (!existingItem) {
          acc.push({ label: current.subGroup, tooltip: current.tooltip });
        }
        return acc;
      }, []);

      return {
        data: formattedData,
        ui: {
          legendItems,
          graph,
          groupType: inputState.groupType,
        },
      };
    });
    return data;
  }, [graphQueries, inputState.graphs, inputState.groupType, isValidDateRange]);

  const minDate = useMemo(() => {
    if (yearsQuery?.data?.length) {
      const minYear = Math.min(...yearsQuery.data.map((d) => d.explore_year));
      return startOfYear(new Date(minYear, 0));
    }
    return startOfYear(new Date(new Date().getFullYear(), 0)); // First day of the current year if no data
  }, [yearsQuery]);

  const maxDate = useMemo(() => {
    return endOfYear(new Date());
  }, []);

  return {
    inputState,
    setInputState,
    chartData,
    inputs: {
      regionalGroups: regionalGroupsQuery,
      minDate,
      maxDate,
      graphOptions: { ...graphOptionsQuery, data: filteredGraphOptions },
    },
    isValidDateRange,
  };
};

export default useTimeSeriesSummaries;
