import { useState, useEffect } from "react";
import { scaleLinear } from "d3-scale";

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT;

const COLD_COLOR = "#0E83C6";
const WARM_COLOR = "#ff3800";

const DROP_SHAPE = "drop";
const LEGEND_SIZE = 20;
const DROP_COUNT = 10;
const DROP_SIZE = 100;

const THERMOMETER_SHAPE = "thermometer";
const THERMOMETER_COUNT = 10;
const THERMOMETER_SIZE = 100;

const STAR_SHAPE = "star";
const STAR_SIZE = 35;
const STAR_LEGEND_SIZE = 25;
const STAR_COLOR = "RoyalBlue"; // RoyalBlue
const STAR_STROKE_COLOR = "Gold"; // Gold
const STAR_STROKE_WIDTH = 8;

const TRIANGLE_SHAPE = "triangle";
const TRIANGLE_SIZE = 15;
const TRIANGLE_LEGEND_SIZE = 25;
const TRIANGLE_COLOR = "Gold";
const TRIANGLE_STROKE_COLOR = "Black";
const TRIANGLE_STROKE_WIDTH = 2;

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    img.src = src;
  });
};

const loadCustomSymbols = async () => {
  const colorScale = scaleLinear()
    .domain([0, DROP_COUNT - 1])
    .range([COLD_COLOR, WARM_COLOR]);

  const dropImages = await Promise.all(
    Array.from({ length: DROP_COUNT }, async (_, index) => {
      const color = encodeURIComponent(colorScale(index));
      const src = `${API_ENDPOINT}/api/icon/icon?shape=${DROP_SHAPE}&width=${DROP_SIZE}&height=${DROP_SIZE}&color=${color}`;
      const image = await loadImage(src);
      return { key: `drop-${index}`, image };
    })
  );
  const dropLegendImage = await loadImage(
    `${API_ENDPOINT}/api/icon/icon?shape=${DROP_SHAPE}&width=${LEGEND_SIZE}&height=${LEGEND_SIZE}`
  );
  const dropDodgerBlueImage = await loadImage(
    `${API_ENDPOINT}/api/icon/icon?shape=${DROP_SHAPE}&width=${DROP_SIZE}&height=${DROP_SIZE}&color=${encodeURIComponent(
      "DodgerBlue"
    )}`
  );
  const thermometerImages = await Promise.all(
    Array.from({ length: THERMOMETER_COUNT }, async (_, index) => {
      const color = encodeURIComponent(colorScale(index));
      const src = `${API_ENDPOINT}/api/icon/icon?shape=${THERMOMETER_SHAPE}&width=${THERMOMETER_SIZE}&height=${THERMOMETER_SIZE}&color=${color}`;
      const image = await loadImage(src);
      return { key: `thermometer-${index}`, image };
    })
  );
  const starImage = await loadImage(
    `${API_ENDPOINT}/api/icon/icon?shape=${STAR_SHAPE}&width=${STAR_SIZE}&height=${STAR_SIZE}&color=${encodeURIComponent(
      STAR_COLOR
    )}&strokeColor=${encodeURIComponent(
      STAR_STROKE_COLOR
    )}&strokeWidth=${STAR_STROKE_WIDTH}`
  );
  const starLegendImage = await loadImage(
    `${API_ENDPOINT}/api/icon/icon?shape=${STAR_SHAPE}&width=${STAR_LEGEND_SIZE}&height=${STAR_LEGEND_SIZE}&color=${encodeURIComponent(
      STAR_COLOR
    )}&strokeColor=${encodeURIComponent(
      STAR_STROKE_COLOR
    )}&strokeWidth=${STAR_STROKE_WIDTH}`
  );

  const triangleImage = await loadImage(
    `${API_ENDPOINT}/api/icon/icon?shape=${TRIANGLE_SHAPE}&width=${TRIANGLE_SIZE}&height=${TRIANGLE_SIZE}&color=${encodeURIComponent(
      TRIANGLE_COLOR
    )}&strokeColor=${encodeURIComponent(
      TRIANGLE_STROKE_COLOR
    )}&strokeWidth=${TRIANGLE_STROKE_WIDTH}`
  );
  const triangleLegendImage = await loadImage(
    `${API_ENDPOINT}/api/icon/icon?shape=${TRIANGLE_SHAPE}&width=${TRIANGLE_LEGEND_SIZE}&height=${TRIANGLE_LEGEND_SIZE}&color=${encodeURIComponent(
      TRIANGLE_COLOR
    )}&strokeColor=${encodeURIComponent(
      TRIANGLE_STROKE_COLOR
    )}&strokeWidth=${TRIANGLE_STROKE_WIDTH}`
  );
  return {
    ...dropImages.reduce(
      (acc, { key, image }) => ({ ...acc, [key]: image }),
      {}
    ),
    ...thermometerImages.reduce(
      (acc, { key, image }) => ({ ...acc, [key]: image }),
      {}
    ),
    star: starImage,
    "star-legend": starLegendImage,
    triangle: triangleImage,
    "triangle-legend": triangleLegendImage,
    "drop-legend": dropLegendImage,
    "drop-dodger-blue": dropDodgerBlueImage,
  };
};

const useImagesCache = () => {
  const [imagesCache, setImagesCache] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const cache = await loadCustomSymbols();
        setImagesCache(cache);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    fetchImages();
  }, []);

  return imagesCache;
};

export default useImagesCache;
