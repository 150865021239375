import { dateFormatter } from "../../../utils";
import { differenceInDays, format, parseISO, setYear } from "date-fns";
import { ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT } from "./constants";

export const formatStartDate = (subtractionMethod, amount) =>
  format(subtractionMethod(new Date(), amount), ISO_DATE_TIME_FORMAT);

export const getCurrentYearPeriod = () => {
  const now = new Date();
  const year = now.getFullYear();
  let defaultStartDate, defaultEndDate;

  // Define the start and end points of the period for the current year
  const periodStart = parseISO(`${year}-04-01`);
  const periodEnd = parseISO(`${year}-11-15`);

  if (now < periodStart) {
    // If the current date is before April 1st, use last year's dates
    defaultStartDate = format(
      setYear(periodStart, year - 1),
      ISO_DATE_TIME_FORMAT
    );
    defaultEndDate = format(setYear(periodEnd, year - 1), ISO_DATE_TIME_FORMAT);
  } else {
    // If it's April 1st or after, use this year's dates
    defaultStartDate = format(periodStart, ISO_DATE_TIME_FORMAT);
    defaultEndDate = format(periodEnd, ISO_DATE_TIME_FORMAT);
  }

  return { defaultStartDate, defaultEndDate };
};

export const transformDataForHeatMap = (data, filters, options) => {
  const groupedByDepth = data.reduce((acc, item) => {
    if (!acc[item.collect_depth]) {
      acc[item.collect_depth] = [];
    }
    acc[item.collect_depth].push({
      x: dateFormatter(
        item.collect_timestamp,
        filters.period === "profile-temperature-hourly"
          ? ISO_DATE_TIME_FORMAT
          : ISO_DATE_FORMAT
      ),
      y: item[filters.statistic],
      unit: item.unit_display_name,
      statistic: options.find((s) => s.value === filters.statistic).label,
    });
    return acc;
  }, {});

  // Convert the grouped data into an array and sort by depth in descending order
  return Object.entries(groupedByDepth)
    .map(([depth, data]) => ({
      id: depth,
      data: data,
    }))
    .sort((a, b) => b.id - a.id); // Assuming depth values are numeric
};

export const getSpacedTickValues = (data, interval) => {
  const allTimestamps = data.flatMap((d) => d.data.map((item) => item.x));
  const uniqueTimestamps = Array.from(new Set(allTimestamps));
  return uniqueTimestamps.filter((_, index) => index % interval === 0);
};

export const getDaysDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return differenceInDays(end, start);
};

export function getContrastYIQ(rgbColor) {
  const rgbValues = rgbColor.match(/\d+/g).map(Number); // Extract RGB values
  const [r, g, b] = rgbValues;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}
