import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider } from "styled-components/macro";
import { useQuery } from "react-query";
import debounce from "lodash.debounce";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import { useSelector } from "react-redux";
import axios from "axios";
import { lineColors } from "../../../utils";
import ResetZoomControl from "../../../components/map/ResetZoomControl";
import ToggleBasemapControl from "../../../components/map/ToggleBasemapControl";
import createTheme from "../../../theme";
import Popup from "../../publicMap/popup";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WaterYearComparisonMap = ({ locations, selectedLocation }) => {
  const theme = useSelector((state) => state.themeReducer);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const mapContainer = useRef(null); // create a reference to the map container

  const DUMMY_BASEMAP_LAYERS = [
    { url: "streets-v11", icon: "commute" },
    { url: "outdoors-v11", icon: "park" },
    { url: "satellite-streets-v11", icon: "satellite_alt" },
  ];

  const locationsLayer = {
    id: "locations",
    type: "circle",
    source: "locations",
    paint: {
      "circle-stroke-width": [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocation]],
        5,
        2,
      ],
      "circle-stroke-color": [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocation]],
        lineColors.yellow,
        "white",
      ],
      "circle-radius": 7,
      "circle-color": [
        "case",
        ["==", ["get", "huc10"], "Deception Creek-Yampa River"],
        "#1F77B4",
        ["==", ["get", "huc10"], "Dry Creek-Yampa River"],
        "#AEC7E8",
        ["==", ["get", "huc10"], "East Fork Williams Fork"],
        "#FF7F0E",
        ["==", ["get", "huc10"], "Elkhead Creek"],
        "#FFBB78",
        ["==", ["get", "huc10"], "Grizzly Creek"],
        "#2CA02C",
        ["==", ["get", "huc10"], "Headwaters Elk River"],
        "#98DF8A",
        ["==", ["get", "huc10"], "Headwaters Yampa River"],
        "#D62728",
        ["==", ["get", "huc10"], "Hells Canyon-Yampa River"],
        "#FF9896",
        ["==", ["get", "huc10"], "North Fork North Platte River"],
        "#9467BD",
        ["==", ["get", "huc10"], "North Fork White River"],
        "#C5B0D5",
        ["==", ["get", "huc10"], "Oak Creek-Yampa River"],
        "#8C564B",
        ["==", ["get", "huc10"], "Outlet Elk River"],
        "#C49C94",
        ["==", ["get", "huc10"], "Rock Creek"],
        "#E377C2",
        ["==", ["get", "huc10"], "Slater Creek"],
        "#F7B6D2",
        ["==", ["get", "huc10"], "Williams Fork"],
        "#7F7F7F",
        "black",
      ],
    },
    lreProperties: {
      popup: {
        titleField: "description",
        excludeFields: ["index", "description"],
      },
    },
  };

  const huc10Fill = {
    id: "huc-10-boundaries-fill",
    name: "HUC 10 Boundaries",
    type: "fill",
    source: "huc-10-boundaries",
    "source-layer": "Yampa_HUC10-c6nelo",
    paint: {
      "fill-color": [
        "case",
        ["==", ["get", "Name"], "Deception Creek-Yampa River"],
        "#1F77B4",
        ["==", ["get", "Name"], "Dry Creek-Yampa River"],
        "#AEC7E8",
        ["==", ["get", "Name"], "East Fork Williams Fork"],
        "#FF7F0E",
        ["==", ["get", "Name"], "Elkhead Creek"],
        "#FFBB78",
        ["==", ["get", "Name"], "Grizzly Creek"],
        "#2CA02C",
        ["==", ["get", "Name"], "Headwaters Elk River"],
        "#98DF8A",
        ["==", ["get", "Name"], "Headwaters Yampa River"],
        "#D62728",
        ["==", ["get", "Name"], "Hells Canyon-Yampa River"],
        "#FF9896",
        ["==", ["get", "Name"], "North Fork North Platte River"],
        "#9467BD",
        ["==", ["get", "Name"], "North Fork White River"],
        "#C5B0D5",
        ["==", ["get", "Name"], "Oak Creek-Yampa River"],
        "#8C564B",
        ["==", ["get", "Name"], "Outlet Elk River"],
        "#C49C94",
        ["==", ["get", "Name"], "Rock Creek"],
        "#E377C2",
        ["==", ["get", "Name"], "Slater Creek"],
        "#F7B6D2",
        ["==", ["get", "Name"], "Williams Fork"],
        "#7F7F7F",
        "#34327C",
      ],
      "fill-opacity": 0.3,
    },
  };

  const huc10Line = {
    id: "huc-10-boundaries-line",
    name: "HUC 10 Boundaries",
    type: "line",
    source: "huc-10-boundaries",
    "source-layer": "Yampa_HUC10-c6nelo",
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "Name"], "Deception Creek-Yampa River"],
        "#1F77B4",
        ["==", ["get", "Name"], "Dry Creek-Yampa River"],
        "#AEC7E8",
        ["==", ["get", "Name"], "East Fork Williams Fork"],
        "#FF7F0E",
        ["==", ["get", "Name"], "Elkhead Creek"],
        "#FFBB78",
        ["==", ["get", "Name"], "Grizzly Creek"],
        "#2CA02C",
        ["==", ["get", "Name"], "Headwaters Elk River"],
        "#98DF8A",
        ["==", ["get", "Name"], "Headwaters Yampa River"],
        "#D62728",
        ["==", ["get", "Name"], "Hells Canyon-Yampa River"],
        "#FF9896",
        ["==", ["get", "Name"], "North Fork North Platte River"],
        "#9467BD",
        ["==", ["get", "Name"], "North Fork White River"],
        "#C5B0D5",
        ["==", ["get", "Name"], "Oak Creek-Yampa River"],
        "#8C564B",
        ["==", ["get", "Name"], "Outlet Elk River"],
        "#C49C94",
        ["==", ["get", "Name"], "Rock Creek"],
        "#E377C2",
        ["==", ["get", "Name"], "Slater Creek"],
        "#F7B6D2",
        ["==", ["get", "Name"], "Williams Fork"],
        "#7F7F7F",
        "#34327C",
      ],
      "line-width": 2,
    },
  };

  const { data, isLoading, error } = useQuery(
    ["wy-analysis-locations-for-map"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/wy-analysis-locations-for-map`
        );

        return data.filter((location) => location.loc_geometry);
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: [-107.398955, 40.517937],
      zoom: 7,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new ResetZoomControl(), "top-left");

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
    });

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      if (!map.getSource("locations")) {
        map.addSource("huc-10-boundaries", {
          type: "vector",
          url: "mapbox://yampariver.4r6uwl8q",
        });

        map.addLayer(huc10Fill);
        map.addLayer(huc10Line);

        map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                id: location.location_ndx,
                type: "Feature",
                properties: {
                  description: location.location_name,
                  index: location.location_ndx,
                  locType: location.location_type_desc,
                  parameters: location.assoc_parameters.join(", "),
                  huc10: location.huc10_name,
                },
                geometry: {
                  type: location.loc_geometry.type,
                  coordinates: location.loc_geometry.coordinates,
                },
              };
            }),
          },
        });
        map.addLayer(locationsLayer);

        map.on("click", "huc-10-boundaries-fill", (e) => {
          const feature = map
            .queryRenderedFeatures(e.point)
            .filter((feature) => feature?.properties?.Name)[0];

          const description = feature.properties.Name;

          const huc10Popup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(map);

          map.on("closeAllPopups", () => {
            huc10Popup.remove();
          });
        });

        map.on("click", "locations", (e) => {
          map.fire("closeAllPopups");

          const features = map.queryRenderedFeatures(e.point);
          const myFeatures = features.filter(
            (feature) => feature.source === "locations"
          );
          const coordinates = [e.lngLat.lng, e.lngLat.lat];

          const popupNode = document.createElement("div");
          ReactDOM.render(
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[locationsLayer]}
                    features={myFeatures}
                    maxWidth="252px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        map.on("mouseenter", "locations", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        map.on("mouseleave", "locations", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [isLoading, mapIsLoaded, map, data]); //eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("locations")) {
      map.setFilter("locations", [
        "in",
        ["get", "index"],
        ["literal", locations],
      ]);
    }
  }, [locations]); // eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("locations")) {
      map.setPaintProperty("locations", "circle-stroke-width", [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocation]],
        5,
        2,
      ]);
      map.setPaintProperty("locations", "circle-stroke-color", [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocation]],
        lineColors.yellow,
        "white",
      ]);
    }
  }, [selectedLocation]); //eslint-disable-line

  if (error) return "An error has occurred: " + error.message;

  return (
    <Root>
      <MapContainer ref={mapContainer} />
    </Root>
  );
};

export default WaterYearComparisonMap;
