import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { USE_QUERY_OPTIONS } from "../queryAndDownload/constants";
import axios from "axios";

export const generateAuthHeaders = async (
  getAccessTokenSilently,
  isAuthenticated
) => {
  let headers = {};
  if (isAuthenticated) {
    try {
      const token = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  }
  return headers;
};

const getData = async (url, headers = {}) => {
  try {
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};

export const useCustomQuery = (key, endpoint, options = {}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const fetchConditionallyWithToken = async () => {
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    return getData(
      `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
      headers
    );
  };

  return useQuery(key, fetchConditionallyWithToken, {
    ...USE_QUERY_OPTIONS,
    ...options,
  });
};

export const normalizeDateToUTC = (date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

// Utility functions to determine the start and end of the water year
export const startOfWaterYear = (year) => new Date(year - 1, 9, 1); // October 1st of the previous year
export const endOfWaterYear = (year) => new Date(year, 8, 30); // September 30th of the current year

export const currentWaterYearStartDate = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  // If today's date is on or after October 1, we are in the water year that started in this calendar year.
  if (today >= new Date(currentYear, 9, 1)) {
    return new Date(currentYear, 9, 1); // October 1st of the current year
  }

  // If today's date is before October 1, we are still in the water year that started last calendar year.
  return new Date(currentYear - 1, 9, 1); // October 1st of the previous year
};

export const currentWaterYearEndDate = () => {
  const today = new Date();
  const currentYear = today.getFullYear();

  // If today's date is on or after October 1, we are in the water year that ends next calendar year.
  if (today >= new Date(currentYear, 9, 1)) {
    return new Date(currentYear + 1, 8, 30); // September 30th of the next calendar year
  }

  // If today's date is before October 1, we are still in the water year that ends this calendar year.
  return new Date(currentYear, 8, 30); // September 30th of the current year
};
