import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import Loader from "../../../components/Loader";

const StyledCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  text-align: center;
  height: 100px;
`;

const StyledCardContent = styled(CardContent)`
  max-height: ${({ height }) => height};
  flex: 1 1 ${({ height }) => height};
  display: flex;
  justify-content: center;
`;

const WidgetTitle = styled(Typography)`
  color: #045681;
  font-weight: 700;
  text-align: center;
`;

function WidgetCard({
  title,
  height,
  lastReport,
  subtitle,
  cardContentStyle,
  isLoading,
  children,
}) {
  return (
    <StyledCard>
      <StyledCardHeader
        titleTypographyProps={{ variant: "h3" }}
        title={title}
      />
      <StyledCardContent height={height} style={cardContentStyle}>
        {isLoading ? <Loader /> : children}
      </StyledCardContent>
      {lastReport && (
        <StyledCardContent>
          <WidgetTitle
            mt={2}
            variant="h5"
            style={{ height: subtitle ? "0" : "42.5px" }}
          >
            {lastReport === "Unavailable"
              ? "Unavailable"
              : `last report: ${lastReport}`}
          </WidgetTitle>
        </StyledCardContent>
      )}
      {subtitle && <CardContent>{subtitle}</CardContent>}
    </StyledCard>
  );
}

export default WidgetCard;
