import React from "react";
import styled from "styled-components/macro";
import { Box, Tooltip, Typography } from "@material-ui/core";
import {
  IgrLinearGauge,
  IgrLinearGraphRange,
  IgrLinearGaugeModule,
} from "igniteui-react-gauges";

IgrLinearGaugeModule.register();

const WidgetContainer = styled(Box)`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

const GaugeContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WidgetTitle = styled(Typography)`
  color: #045681;
  font-weight: 700;
  text-align: center;
`;

const gaugeConfig = {
  height: "300px",
  width: "80%",
  orientation: "Vertical",
  minimumValue: 0,
  maximumValue: 15,
  interval: 5,
  isNeedleDraggingEnabled: false,
  needleShape: "Custom",
  needleBrush: "DodgerBlue",
  needleOutline: "white",
  needleStrokeThickness: 1,
  needleBreadth: 5,
  needleInnerExtent: 0.35,
  needleOuterExtent: 0.65,
  needleOuterPointExtent: 0.8,
  needleInnerPointExtent: 0.325,
  needleInnerPointWidth: 0,
  needleOuterPointWidth: 0.3,
  needleInnerBaseWidth: 0,
  needleOuterBaseWidth: 0.07,
};

const DissolvedOxygenGauge = ({
  title,
  value,
  units,
  range1Start,
  range1End,
  range2Start,
  range2End,
}) => {
  return (
    <WidgetContainer>
      <WidgetTitle variant="h5">{title}</WidgetTitle>
      <Tooltip
        title={<Typography variant="h6">{`${value} ${units}`}</Typography>}
        placement="bottom"
        PopperProps={{ style: { marginTop: "-20px" } }}
      >
        <GaugeContainer>
          <IgrLinearGauge value={value ?? null} {...gaugeConfig}>
            <IgrLinearGraphRange
              name="1"
              startValue={range1Start}
              endValue={range1End}
              brush="#D9822B"
            />
            <IgrLinearGraphRange
              name="2"
              startValue={range2Start}
              endValue={range2End}
              brush="#3D9970"
            />
          </IgrLinearGauge>
        </GaugeContainer>
      </Tooltip>
    </WidgetContainer>
  );
};

function DissolvedOxygenMinimums({ dissolvedOxygen }) {
  return (
    <>
      <DissolvedOxygenGauge
        title="24-Hour Min"
        value={dissolvedOxygen?.min_24hr}
        units={dissolvedOxygen?.units}
        range1Start={0}
        range1End={6}
        range2Start={6}
        range2End={15}
      />
      <DissolvedOxygenGauge
        title="7-Day Min"
        value={dissolvedOxygen?.min_7day}
        units={dissolvedOxygen?.units}
        range1Start={0}
        range1End={6}
        range2Start={6}
        range2End={15}
      />
    </>
  );
}

export default DissolvedOxygenMinimums;
