export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

export const REQUIRED_NUMBER_OF_GRAPH_SELECTIONS = 2;

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api/year-over-year-comparisons-tool`;

export const DEFAULT_INPUT_STATE = {
  regionalGroup: 4, //Steamboat Springs
  years: [new Date().getFullYear(), new Date().getFullYear() - 1],
  yearType: "collect_year",
  graphs: [],
};
