import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// Fetch a list of sources to add to the map
const useSources = () => {
  const [sources, setSources] = useState([]);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { data, isError, isLoading } = useQuery(
    ["Sources", isAuthenticated],
    async () => {
      let headers = {};

      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        headers = { Authorization: `Bearer ${token}` };
      }
      try {
        return await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/sources`,
          { headers }
        );
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setSources(data?.data || []);
  }, [data]);

  return { isError, isLoading, sources, setSources };
};

export default useSources;
