import React from "react";
import TemperatureWidget from "./TemperatureWidget";

function TemperatureMinimums({ temp, showWarning = false }) {
  return (
    <>
      <TemperatureWidget
        title="24-Hour Min"
        value={temp?.min_24hr ?? null}
        unit={temp?.last_report === "Unavailable" ? "N/A" : "°F"}
        steps={temp?.last_report === "Unavailable" ? 0 : 7}
        showWarning={showWarning}
      />
      <TemperatureWidget
        title="7-Day Min"
        value={temp?.min_7day ?? null}
        unit={temp?.last_report === "Unavailable" ? "N/A" : "°F"}
        steps={temp?.last_report === "Unavailable" ? 0 : 7}
        showWarning={showWarning}
      />
    </>
  );
}

export default TemperatureMinimums;
