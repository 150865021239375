import React from "react";
import { useQuery } from "react-query";

import { Divider as MuiDivider, Typography } from "@material-ui/core";

import styled from "styled-components/macro";
import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import { dateFormatter, getLookupForDataSortedByField } from "../../../utils";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);

const handlePointInteractions = (pointFeatures) => {
  const flyToZoomLevel = 14;
  window.location.href = `/tools/map-explorer/all/${pointFeatures.latitude}/${pointFeatures.longitude}/${flyToZoomLevel}`;
};

const SitesSummaryTable = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data, isFetching, error } = useQuery(
    ["/api/site-param-summary-table"],
    async () => {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/site-param-summary-table`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Location Name",
      field: "location_name",
    },
    {
      title: "Location Type",
      field: "location_type",
      lookup: getLookupForDataSortedByField(data, "location_type"),
    },
    {
      title: "Parameter",
      field: "parameter",
    },
    {
      title: "Data Provider",
      field: "data_provider",
      lookup: getLookupForDataSortedByField(data, "data_provider"),
    },
    {
      title: "HUC8",
      field: "huc8",
    },
    // {
    //   title: "Irrigation Type",
    //   field: "irgg_type",
    // },
    {
      title: "Starting Period of Record",
      field: "start_por",
      render: (rowData) => {
        return dateFormatter(rowData.start_por, "MM/dd/yyyy");
      },
    },
    {
      title: "Ending Period of Record",
      field: "end_por",
      render: (rowData) => {
        return dateFormatter(rowData.end_por, "MM/dd/yyyy hh:mm a");
      },
    },
    {
      title: "Years",
      field: "years",
      hidden: true,
      hiddenByColumnsButton: true,
    },
    {
      title: "Latitude",
      field: "latitude",
      filtering: false,
    },
    {
      title: "Longitude",
      field: "longitude",
      filtering: false,
    },
    {
      title: "External Data Link",
      field: "loc_url",
      render: (rowData) => {
        return rowData.location_urls
          ? rowData.location_urls.split(",").map((item) => {
              return (
                <div key={item}>
                  <a target="_blank" href={item.split(";")[1]} rel="noreferrer">
                    <>{item.split(";")[0]}</>
                  </a>
                </div>
              );
            })
          : "N/A";
      },
      filtering: false,
    },
  ];

  return (
    <>
      <Helmet title="Site Summary Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Site Summary Table
      </Typography>

      <Divider my={6} />
      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Site Summary Table"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              pageSize={60}
              options={{ filtering: true }}
              actions={[
                () => ({
                  icon: "near_me",
                  tooltip: "Fly to on Map",
                  onClick: (event, rowData) => {
                    handlePointInteractions(rowData);
                  },
                }),
              ]}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SitesSummaryTable;
