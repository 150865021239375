import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";

import App from "./App";
import "./mocks";
import store from "./redux/store/index";

const version = require("./vendor/version") || "1.0.0";
process.env.REACT_APP_VERSION = version;

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_ANALYTICS_TRACKING_ID
) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID, {
    gtagOptions: {
      anonymize_ip: true, // Ensures GDPR compliance
      site_speed_sample_rate: 100, // Captures site speed metrics
    },
  });
}

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
