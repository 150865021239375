import React, { useState, useMemo } from "react";
import styled from "styled-components/macro";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FormatQuote } from "@material-ui/icons";
import { useQuery } from "react-query";
import axios from "axios";
import Loader from "../../Loader";

const Container = styled("pre")`
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  position: absolute;
  bottom: 70px;
  right: 49px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  z-index: 2;
`;

const StyledTableCell = styled(TableCell)`
  word-break: break-word;
  font-size: 0.8rem;
  color: white;
  font-weight: ${({ $isHeader }) => ($isHeader ? 700 : 400)};
  cursor: ${({ $isClickable }) => ($isClickable ? "pointer" : "default")};
`;

const StyledTableContainer = styled(TableContainer)`
  background-color: transparent;
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  text-wrap: wrap;
`;

const StickyTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.85
  ); /* Adjust background color to match the table */
  z-index: 1;
`;

const StyledBox = styled(Box)`
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 70px;
  right: 49px;
  display: flex;
  flex-direction: column;
  width: 175px;
  justify-content: end;
`;

const HideButtonWrapper = styled(Box)`
  display: flex;
  justify-content: end;
  margin-left: 10px;
`;

const fetchMapSourceReferences = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/public-map/map-source-references`
  );
  return data;
};

const sortData = (data, sortConfig) => {
  if (!data) return [];
  return [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });
};

const MapSourceReferences = () => {
  const [showSources, setShowSources] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const { data, isLoading, error } = useQuery(
    "mapSourceReferences",
    fetchMapSourceReferences
  );

  const handleToggle = () => {
    setShowSources((prev) => !prev);
  };

  const sortedData = useMemo(
    () => sortData(data, sortConfig),
    [data, sortConfig]
  );

  const requestSort = (key) => {
    setSortConfig((prevState) => ({
      key,
      direction:
        prevState.key === key && prevState.direction === "asc" ? "desc" : "asc",
    }));
  };

  if (error) return <div>Error loading data</div>;

  return (
    <>
      {!showSources && (
        <StyledBox>
          <Button
            onClick={handleToggle}
            color="secondary"
            variant="contained"
            size="small"
            startIcon={!isLoading && <FormatQuote />}
            disabled={isLoading}
          >
            {isLoading ? <Loader color="primary" /> : "Data Sources"}
          </Button>
        </StyledBox>
      )}

      {showSources && (
        <Container>
          <StyledTableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <StickyTableHead>
                <TableRow>
                  <StyledTableCell
                    $isHeader
                    $isClickable
                    onClick={() => requestSort("data_source")}
                  >
                    Data Source
                  </StyledTableCell>
                  <StyledTableCell
                    $isHeader
                    $isClickable
                    onClick={() => requestSort("data_types")}
                  >
                    Data Type
                  </StyledTableCell>
                </TableRow>
              </StickyTableHead>
              <TableBody>
                <>
                  {sortedData.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <a
                          rel="noreferrer noopener"
                          target="_blank"
                          href={row.data_source_url}
                          style={{ color: "inherit" }}
                        >
                          {row.data_source}
                        </a>
                      </StyledTableCell>
                      <StyledTableCell>{row.data_types}</StyledTableCell>
                    </TableRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </StyledTableContainer>
          <HideButtonWrapper>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={handleToggle}
            >
              Hide
            </Button>
          </HideButtonWrapper>
        </Container>
      )}
    </>
  );
};

export default MapSourceReferences;
