import { addDays } from "date-fns";

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api`;

export const ENDPOINT = "colors-of-water-stagecoach";

export const RESERVOIR_NDX = 197;

export const DEFAULT_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const TIME_INTERVAL_OPTIONS = [
  {
    label: "Daily Data",
    value: "daily",
  },
];

export const DEFAULT_TIME_INTERVAL = TIME_INTERVAL_OPTIONS[0].value;

const START_DAYS_AGO = -90;

export const DEFAULT_START_DATE = addDays(new Date(), START_DAYS_AGO);

export const DEFAULT_END_DATE = new Date();
