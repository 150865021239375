import { useQueries, useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  optionsEndpoints,
  seriesEndpoints,
  timeseriesEndpoints,
} from "./YamcoloConstants";
import { useEffect, useState } from "react";
import { colorNameToHex, groupByValue } from "../../../utils";
import { lighten } from "@material-ui/core";

export const useYamcoloTimeSeries = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [filterValues, setFilterValues] = useState(null);
  const [graphData, setGraphData] = useState([]);

  // Handler function to update the radio button state
  const handleFilterChange = (name, value) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const baseUrl = `${process.env.REACT_APP_ENDPOINT}/api`;
  const getAuthorizationHeaders = async () => {
    const token = await getAccessTokenSilently();
    return {
      Authorization: `Bearer ${token}`,
    };
  };

  const fetchData = async (endpoint, filters = {}) => {
    if (!filters) return {};
    try {
      const headers = await getAuthorizationHeaders();
      const { data } = await axios.post(
        `${baseUrl}/${endpoint}`,
        { filters },
        { headers }
      );
      if (timeseriesEndpoints.includes(endpoint) && !data.length)
        setGraphData([]);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const optionsQueries = optionsEndpoints.map((endpoint) => ({
    queryKey: [endpoint],
    queryFn: () => fetchData(endpoint),
    refetchOnWindowFocus: false,
  }));

  const optionsResults = useQueries(optionsQueries);

  const optionsData = {};
  optionsEndpoints.forEach((endpoint, index) => {
    optionsData[endpoint] = optionsResults[index].data;
  });

  const optionsIsLoading = optionsResults.some((query) => query.isLoading);
  const optionsError = optionsResults.find((query) => query.error);

  //--------------------

  useEffect(() => {
    if (!optionsIsLoading) {
      setFilterValues(() => ({
        route:
          optionsData["resops-ts-yamcolo-timestep-options"][0].data_table_name,
        leftAxis: optionsData["resops-ts-yamcolo-left-axis-options"][0].axis,
        rightAxis: optionsData["resops-ts-yamcolo-right-axis-options"][0].axis,
        periodEnding: new Date(),
      }));
    }
  }, [optionsIsLoading]); //eslint-disable-line

  //--------------------

  //---------------------

  const seriesQueries = [
    {
      queryKey: ["resops-ts-yamcolo-left-axis-series", filterValues?.leftAxis],
      queryFn: () => {
        return fetchData(
          "resops-ts-yamcolo-left-axis-series",
          !filterValues
            ? null
            : {
                axis: filterValues.leftAxis,
              }
        );
      },
      refetchOnWindowFocus: false,
    },
    {
      queryKey: [
        "resops-ts-yamcolo-right-axis-series",
        filterValues?.rightAxis,
      ],
      queryFn: () => {
        return fetchData(
          "resops-ts-yamcolo-right-axis-series",
          !filterValues
            ? null
            : {
                axis: filterValues.rightAxis,
              }
        );
      },
      refetchOnWindowFocus: false,
    },
  ];

  const seriesResults = useQueries(seriesQueries);

  const seriesData = {};
  seriesEndpoints.forEach((endpoint, index) => {
    seriesData[endpoint] = seriesResults[index].data;
  });

  const seriesIsLoading = seriesResults.some((query) => query.isLoading);
  const seriesError = seriesResults.find((query) => query.error);

  //---------------------

  const timeseriesQuery = {
    queryKey: [filterValues],
    queryFn: () => {
      return fetchData(
        filterValues?.route,
        !filterValues
          ? null
          : {
              timestep: optionsData["resops-ts-yamcolo-timestep-options"].find(
                (item) => item.data_table_name === filterValues.route
              ).date_range_days,
              leftAxis: filterValues.leftAxis,
              rightAxis: filterValues.rightAxis,
              periodEnding: filterValues.periodEnding,
            }
      );
    },
    refetchOnWindowFocus: false,
  };

  const {
    data: timeseriesData,
    isLoading: timeseriesIsLoading,
    error: timeseriesError,
    refetch: timeseriesRefetch,
  } = useQuery(timeseriesQuery);

  useEffect(() => {
    // data?.leftAxis?.length && data?.rightAxis?.length
    if (
      timeseriesData &&
      Object.keys(timeseriesData).length > 0 &&
      !timeseriesIsLoading &&
      !optionsIsLoading &&
      !seriesIsLoading
    ) {
      const groupedData = groupByValue(timeseriesData, "axis");

      const leftAxis = groupedData?.[filterValues.leftAxis]
        ? groupByValue(groupedData?.[filterValues.leftAxis], "series_ndx")
        : [];

      const rightAxis = groupedData?.[filterValues.rightAxis]
        ? groupByValue(groupedData?.[filterValues.rightAxis], "series_ndx")
        : [];

      const graphData = {
        yLLabel: `${
          optionsData["resops-ts-yamcolo-left-axis-options"].find(
            (item) => item.axis === filterValues.leftAxis
          ).parameter_display_abbrev
        } (${
          optionsData["resops-ts-yamcolo-left-axis-options"].find(
            (item) => item.axis === filterValues.leftAxis
          ).unit_display_name
        })`,
        yRLabel: `${
          optionsData["resops-ts-yamcolo-right-axis-options"].find(
            (item) => item.axis === filterValues.rightAxis
          ).parameter_display_abbrev
        } (${
          optionsData["resops-ts-yamcolo-right-axis-options"].find(
            (item) => item.axis === filterValues.rightAxis
          ).unit_display_name
        })`,
        datasets: [
          ...(groupedData?.[filterValues.leftAxis]?.length > 0
            ? seriesResults[0].data
                .filter((item) => item.axis === filterValues.leftAxis)
                .map((location) => {
                  if (!leftAxis[location.series_ndx]) return null;
                  return {
                    data: leftAxis[location.series_ndx].map((item) => {
                      return {
                        x: item.collect_timestamp,
                        y: item.result_value,
                      };
                    }),
                    yAxisID: "yL",
                    units: optionsData[
                      "resops-ts-yamcolo-left-axis-options"
                    ].find((item) => item.axis === filterValues.leftAxis)
                      .unit_display_name,
                    pointStyle: "circle",
                    borderWidth: +location.line_weight,
                    borderCapStyle: "round",
                    ...(location.line_type === "dashed" && {
                      borderDash: [8, 10],
                    }),
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    label: location.series_display_label,
                    borderColor: location.legend_color,
                    backgroundColor: lighten(
                      colorNameToHex(location.legend_color),
                      0.5
                    ),
                    tension: 0.5,
                  };
                })
            : []
          ).filter(Boolean),
          ...(groupedData?.[filterValues.rightAxis]?.length > 0
            ? seriesResults[1].data
                .filter((item) => item.axis === filterValues.rightAxis)
                .map((location) => {
                  if (!rightAxis[location.series_ndx]) return null;
                  return {
                    data: rightAxis[location.series_ndx].map((item) => {
                      return {
                        x: item.collect_timestamp,
                        y: item.result_value,
                      };
                    }),
                    yAxisID: "yR",
                    units: optionsData[
                      "resops-ts-yamcolo-right-axis-options"
                    ].find((item) => item.axis === filterValues.rightAxis)
                      .unit_display_name,
                    pointStyle: "circle",
                    borderWidth: +location.line_weight,
                    borderCapStyle: "round",
                    borderDash:
                      location.line_type === "dashed"
                        ? [10, 8]
                        : location.line_type === "dotted"
                        ? [3, 3]
                        : [],
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    label: location.series_display_label,
                    borderColor: location.legend_color,
                    backgroundColor: lighten(
                      colorNameToHex(location.legend_color),
                      0.5
                    ),
                    tension: 0.5,
                  };
                })
            : []
          ).filter(Boolean),
        ],
      };
      setGraphData(graphData);
    }
  }, [timeseriesData]); //eslint-disable-line

  return {
    data: {
      options: {
        data: optionsData,
        error: optionsError,
        isLoading: optionsIsLoading,
      },
      timeseries: {
        data: timeseriesData,
        error: timeseriesError,
        isLoading: timeseriesIsLoading,
        refetch: timeseriesRefetch,
      },
      series: {
        data: seriesData,
        error: seriesError,
        isLoading: seriesIsLoading,
      },
      graph: graphData,
    },
    userState: {
      handleFilterChange: handleFilterChange,
      filterValues: filterValues,
      setFilterValues: setFilterValues,
    },
  };
};
