import React, { useCallback, useState } from "react";
import styled from "styled-components/macro";
import {
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography,
  Paper as MuiPaper,
  Box,
  Button,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import useTimeSeriesSummaries from "./useTimeSeriesSummaries";
import SelectControl from "../../../components/inputs/SelectControl";
import { REQUIRED_NUMBER_OF_GRAPH_SELECTIONS } from "./constants";
import { Alert } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import DragAndDropSelector from "./DragAndDropSelector";
import DateControl from "./DateControl";
import BoxPlot from "./BoxPlot";
import GroupTypeToggleSwitch from "./GroupTypeToggleSwitch";
import QuickSetDateRangeButtonGroup from "./QuickSetDateRangeButtonGroup";
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { Link as RouterLink } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);

const StyledAlert = styled(Alert)`
  & .MuiAlert-message {
    width: 100%;
  }
`;

const OptionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonContainer = styled(Box)`
  text-align: center;
  margin-top: 16px;
`;

const ToggleButton = styled(Button)`
  width: 150px;
`;

export const OptionsHeader = styled(Typography).attrs({
  color: "secondary",
  variant: "subtitle1",
})``;

const adjustDateByGroupType = (date, groupType, type) => {
  if (groupType === "Year") {
    return type === "startDate" ? startOfYear(date) : endOfYear(date);
  }

  if (groupType === "Month") {
    return type === "startDate" ? startOfMonth(date) : endOfMonth(date);
  }

  if (groupType === "Week") {
    return type === "startDate" ? startOfDay(date) : endOfDay(date);
  }

  return date;
};

const DateRangeValidationAlert = ({ isValidDateRange, groupType }) =>
  !isValidDateRange && (
    <Alert severity="error">
      Invalid date range: Limited to{" "}
      {groupType === "Week" ? "12 weeks" : "12 months"} of history for{" "}
      {groupType}
    </Alert>
  );

const TimeSeriesSummaries = () => {
  const { inputs, inputState, setInputState, chartData, isValidDateRange } =
    useTimeSeriesSummaries();
  const [gridSize, setGridSize] = useState(6);

  const toggleGridSize = () =>
    setGridSize((prevSize) => (prevSize === 6 ? 12 : 6));

  const handleSelectionChange = useCallback(
    (newSelections) =>
      setInputState((prev) => ({ ...prev, graphs: newSelections })),
    [setInputState]
  );

  const handleDateChange = useCallback(
    (date, type) => {
      if (date) {
        setInputState((prev) => ({
          ...prev,
          [type]: adjustDateByGroupType(date, prev.groupType, type),
        }));
      }
    },
    [setInputState]
  );

  const handleGroupTypeChange = useCallback(
    (groupType) => setInputState((prev) => ({ ...prev, groupType })),
    [setInputState]
  );

  const handleDateRangeChange = useCallback(
    (startDate, endDate, groupType) =>
      setInputState((prevState) => ({
        ...prevState,
        startDate,
        endDate,
        groupType,
      })),
    [setInputState]
  );

  const handleRegionChange = useCallback(
    (_, regGroup) =>
      setInputState((prev) => ({ ...prev, regionalGroup: regGroup })),
    [setInputState]
  );
  return (
    <>
      <Helmet title="Time Series Summaries" />
      <Typography variant="h3" gutterBottom display="inline">
        Time Series Summaries
      </Typography>

      <Divider my={6} />

      <OptionsContainer>
        <Paper p={4}>
          <Box pl="10px" pr="10px">
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={`/tools/monitoring-locations-and-parameters-by-basin-region/${
                  inputState?.regionalGroup || ""
                }`}
              >
                Review Monitoring Locations in this Region
              </Button>
            </Box>
            <OptionsHeader>Select a Basin Region</OptionsHeader>
            <SelectControl
              label="Regions"
              id="regionalGroup"
              valueField="reg_group_ndx"
              displayField="reg_group_name"
              value={inputState.regionalGroup ?? ""}
              onChange={handleRegionChange}
              options={inputs.regionalGroups.data}
              isLoading={inputs.regionalGroups.isLoading}
            />
          </Box>
        </Paper>

        {inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS &&
          inputState.startDate &&
          inputState.endDate && (
            <Paper p={4}>
              {chartData?.length ? (
                <>
                  <Grid container spacing={gridSize === 6 ? 2 : 0}>
                    {chartData.map((data, index) => (
                      <Grid item xs={gridSize} key={index}>
                        <BoxPlot data={data.data} ui={data.ui} error={false} />
                      </Grid>
                    ))}
                  </Grid>
                  <ButtonContainer>
                    <ToggleButton
                      variant="contained"
                      color="primary"
                      onClick={toggleGridSize}
                    >
                      {gridSize === 6 ? "Full Width" : "Split View"}
                    </ToggleButton>
                  </ButtonContainer>
                </>
              ) : (
                <StyledAlert icon={false} severity="info">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Loader />
                    <Typography style={{ flex: 1, textAlign: "center" }}>
                      <strong>Loading!</strong> Building and Customizing Your
                      Graphs
                    </Typography>
                    <Loader />
                  </Box>
                </StyledAlert>
              )}
            </Paper>
          )}

        <Paper p={4}>
          <Grid container>
            <Grid item xs={12} md={6} display="flex" pl="10px" pr="10px">
              <OptionsHeader>Select Date Range to Explore</OptionsHeader>
              <Box display="flex" style={{ gap: "10px" }}>
                <Grid item xs={12} sm={6}>
                  <DateControl
                    id="start-date"
                    label="Start Date"
                    value={inputState.startDate}
                    onChange={(date) => handleDateChange(date, "startDate")}
                    groupType={inputState.groupType}
                    minDate={inputs.minDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateControl
                    id="end-date"
                    label="End Date"
                    value={inputState.endDate}
                    onChange={(date) => handleDateChange(date, "endDate")}
                    groupType={inputState.groupType}
                    minDate={inputs.minDate}
                  />
                </Grid>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              style={{ flexDirection: "column" }}
              pl="10px"
              pr="10px"
            >
              <OptionsHeader>Group Dates By</OptionsHeader>
              <GroupTypeToggleSwitch
                value={inputState.groupType}
                onChange={handleGroupTypeChange}
              />
            </Grid>

            <Grid item xs={12} pl="10px" pr="10px">
              <DateRangeValidationAlert
                isValidDateRange={isValidDateRange}
                groupType={inputState.groupType}
              />
            </Grid>

            <Grid item xs={12} display="flex" pl="10px" pr="10px" mt="16px">
              <OptionsHeader>Quick Date Range Selection</OptionsHeader>
              <QuickSetDateRangeButtonGroup
                onChange={handleDateRangeChange}
                currentGroupType={inputState.groupType}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              style={{ flexDirection: "column" }}
              pl="10px"
              pr="10px"
            >
              <Grid item xs={12} mb="16px">
                <OptionsHeader>
                  Drag and Drop Two Graphs to Compare
                </OptionsHeader>
              </Grid>
              <DragAndDropSelector
                options={inputs.graphOptions.data || []}
                maxSelections={REQUIRED_NUMBER_OF_GRAPH_SELECTIONS}
                onSelectionChange={handleSelectionChange}
                selections={inputState.graphs}
                idField="tssum_group_ndx"
                titleField="tssum_graph_title"
              />
            </Grid>
          </Grid>
        </Paper>
      </OptionsContainer>
    </>
  );
};

export default TimeSeriesSummaries;
