export const timeseriesEndpoints = [
  "resops-ts-yamcolo15-min",
  "resops-ts-yamcolo-hourly",
  "resops-ts-yamcolo-daily",
];

export const optionsEndpoints = [
  "resops-ts-yamcolo-left-axis-options",
  "resops-ts-yamcolo-right-axis-options",
  "resops-ts-yamcolo-timestep-options",
];

export const seriesEndpoints = [
  "resops-ts-yamcolo-left-axis-series",
  "resops-ts-yamcolo-right-axis-series",
];
