import React, { useState } from "react";
import {
  Box,
  Drawer,
  Fab as MuiFab,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import LinkIcon from "@material-ui/icons/Link";
import { NavLink } from "react-router-dom";
import { darken, rgba } from "polished";
import styled, { keyframes } from "styled-components";
import { useQuery } from "react-query";
import axios from "axios";

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: 16px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const slideIn = keyframes`
  from {
    right: -300px;
  }
  to {
    right: -80px;
  }
`;

const Fab = styled(MuiFab)`
  position: fixed;
  top: 94px;
  padding-right: 97px;
  right: -80px;
  z-index: 9999;
  animation: ${slideIn} 1s ease-out forwards;
`;

const InfoIcon = styled(Info)`
  margin-right: 10px;
`;

const StyledLinkIcon = styled(LinkIcon)`
  margin-right: 10px;
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  justify-content: start;
  cursor: pointer;
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  font-family: ${(props) => props.theme.typography.fontFamily};

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${({ backgroundcolor }) => backgroundcolor};
  }
`;

const Link = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const GroupIcon = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  "& > *": {
    fontSize: 40,
  },
  marginRight: "16px",
}));

export function RightDrawerInfo({ stakeholder }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const backgroundColor = stakeholder?.color ?? theme.header.background;

  const { data: links } = useQuery(
    [`data-other-resources-links/${stakeholder.ndx}`],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-other-resources-links/${stakeholder.ndx}`
        );
        return data.map(({ resource_url, resource_text }) => ({
          url: resource_url,
          label: resource_text,
        }));
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!stakeholder.ndx,
    }
  );

  const toggleDrawer = () => {
    setOpen((state) => !state);
  };

  const SidebarLink = ({ url, text }) => {
    return (
      <Link
        button
        dense
        rel="noreferrer noopener"
        component="a"
        target="_blank"
        href={url}
      >
        <StyledLinkIcon />
        <LinkText>{text}</LinkText>
      </Link>
    );
  };

  const SidebarLinks = ({ links }) => {
    return (
      <>
        {links.map(({ label, url }) => {
          return <SidebarLink key={label} url={url} text={label} />;
        })}
      </>
    );
  };

  return (
    <>
      {!open && (
        <Fab color="secondary" variant="extended" onClick={toggleDrawer}>
          <InfoIcon />
          Additional Resources
        </Fab>
      )}
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <Brand
          backgroundcolor={backgroundColor}
          component={NavLink}
          to="/"
          button
          style={{
            pointerEvents: "all",
          }}
        >
          <GroupIcon>
            {React.cloneElement(stakeholder?.icon, {
              style: { color: "white" },
            })}
          </GroupIcon>
          Additional Resources
        </Brand>
        <List disablePadding>
          <Items>
            <SidebarLinks links={links} />
          </Items>
        </List>
      </Drawer>
    </>
  );
}
