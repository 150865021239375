import React, { useCallback } from "react";
import styled from "styled-components/macro";
import {
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography,
  Paper as MuiPaper,
  Button as MuiButton,
  Box,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import MultiChart from "./MultiChart";
import useYearOverYearComparisonsTool from "./useYearOverYearComparisonsTool";
import SelectControl from "../../../components/inputs/SelectControl";
import YearToggleSwitch from "./YearToggleSwitch";
import { REQUIRED_NUMBER_OF_GRAPH_SELECTIONS } from "./constants";
import { Alert } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import DragAndDropSelector from "./DragAndDropSelector";
import { Link as RouterLink } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Button = styled(MuiButton)(spacing);

const StyledAlert = styled(Alert)`
  & .MuiAlert-message {
    width: 100%;
  }
`;

const OptionsHeader = ({
  text,
  color = "secondary",
  variant = "subtitle1",
  ...rest
}) => (
  <Typography color={color} variant={variant} {...rest}>
    {text}
  </Typography>
);

const YearOverYearComparisonsTool = () => {
  const { inputs, inputState, handleInputState, chartData } =
    useYearOverYearComparisonsTool();
  const handleSelectionChange = useCallback(
    (newSelections) => {
      handleInputState("graphs", newSelections);
    },
    [handleInputState]
  );
  return (
    <>
      <Helmet title="Year Over Year Comparisons Tool" />
      <Typography variant="h3" gutterBottom display="inline">
        Year Over Year Comparisons Tool
      </Typography>

      <Divider my={6} />
      <Box display="flex" flexDirection="column" style={{ gap: "16px" }}>
        <Paper p={4}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={`/tools/monitoring-locations-and-parameters-by-basin-region/${
                inputState?.regionalGroup || ""
              }`}
            >
              Review Monitoring Locations in this Region
            </Button>
          </Box>
          <Box pl="10px" pr="10px">
            <OptionsHeader text="Select a Basin Region" />
            <SelectControl
              label="Regions"
              id="regionalGroup"
              valueField="reg_group_ndx"
              displayField="reg_group_name"
              value={inputState.regionalGroup ?? ""}
              onChange={handleInputState}
              options={inputs.regionalGroups.data}
              isLoading={inputs.regionalGroups.isLoading}
            />
          </Box>
        </Paper>

        {inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS &&
          inputState.years.length > 0 && (
            <Paper p={4}>
              {chartData.length ? (
                <MultiChart data={chartData} />
              ) : (
                <StyledAlert icon={false} severity="info">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Loader />
                    <Typography style={{ flex: 1, textAlign: "center" }}>
                      <strong>Loading!</strong> Building and Customizing Your
                      Graphs
                    </Typography>
                    <Loader />
                  </Box>
                </StyledAlert>
              )}
            </Paper>
          )}

        <Paper p={4}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              style={{ flexDirection: "column" }}
              pl="10px"
              pr="10px"
            >
              <OptionsHeader text="Select Years to Compare" />
              <SelectControl
                label="Years"
                id="years"
                multiple
                valueField="explore_year"
                displayField="explore_year"
                value={inputState.years ?? []}
                onChange={handleInputState}
                options={inputs.years.data}
                isLoading={inputs.years.isLoading}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              style={{ flexDirection: "column" }}
              pl="10px"
              pr="10px"
            >
              <OptionsHeader text="Year Type Selector" />
              <YearToggleSwitch
                value={inputState.yearType}
                onChange={handleInputState}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              style={{ flexDirection: "column" }}
              pl="10px"
              pr="10px"
            >
              <Grid item xs={12} mb="16px">
                <OptionsHeader text="Drag and Drop Two Graphs to Compare" />
              </Grid>
              <DragAndDropSelector
                options={inputs.graphOptions.data || []}
                maxSelections={REQUIRED_NUMBER_OF_GRAPH_SELECTIONS}
                onSelectionChange={handleSelectionChange}
                selections={inputState.graphs}
                idField="yoy_group_ndx"
                titleField="yoy_graph_title"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default YearOverYearComparisonsTool;
