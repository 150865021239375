import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const links = {
  "bel Bear Lake":
    "https://dwr.state.co.us/Tools/StationsLite/BERBBLCO?params=DISCHRG",
  "at CR 7 nr Yampa":
    "https://dwr.state.co.us/Tools/StationsLite/BERCR7CO?params=DISCHRG",
  "at Box K Ranch":
    "https://dwr.state.co.us/Tools/StationsLite/BERBXKCO?params=DISCHRG",
  "Above Reservoir":
    "https://waterdata.usgs.gov/monitoring-location/09237450/#parameterCode=00060&period=P30D&compare=true&showMedian=true",
  "Below Reservoir":
    "https://waterdata.usgs.gov/monitoring-location/09237500/#parameterCode=00060&period=P30D&compare=true&showMedian=true",
};

function TableCard({ tableData }) {
  return (
    <TableContainer>
      <Table
        size="small"
        style={{
          borderSpacing: "5px",
          borderCollapse: "separate",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ border: "none" }} />
            {tableData.headers.map((header, columnIndex) => (
              <TableCell
                style={{
                  fontWeight: "bold",
                  border: "none",
                  textAlign: "center",
                  padding: "5px 12px 5px 8px",
                }}
                key={columnIndex}
              >
                {links[header] ? (
                  <a
                    href={links[header]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {header}
                  </a>
                ) : (
                  header
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell
                style={{
                  fontWeight: ["Last Reported Value", "Last Reports"].includes(
                    row.title
                  )
                    ? "bold"
                    : "normal",
                  border: "none",
                  padding: "5px 12px 5px 8px",
                }}
              >
                {row.title}
              </TableCell>
              {row.values.map((col, colIndex) => (
                <TableCell
                  style={{
                    border: "2px solid blue",
                    textAlign: "center",
                    padding: "5px 12px 5px 8px",
                  }}
                  key={colIndex}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableCard;
