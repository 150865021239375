import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueries } from "react-query";
import axios from "axios";
import {
  BASE_URL,
  DEFAULT_INPUT_STATE,
  REQUIRED_NUMBER_OF_GRAPH_SELECTIONS,
  USE_QUERY_OPTIONS,
} from "./constants";
import { useLocation, useParams } from "react-router-dom";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import { useCustomQuery, hexToRGBA, getColorForYear } from "./utils";
import { titleize } from "inflected";
import { eachDayOfInterval, format } from "date-fns";

const validateGraphIds = (graphIds, graphOptions, maxSelections) => {
  return graphIds
    .map((id) =>
      graphOptions.find((graph) => graph.yoy_group_ndx === parseInt(id))
    )
    .filter(Boolean)
    .slice(0, maxSelections);
};

const validateOption = (value, options, key) =>
  options.some((option) => option[key] === value);

const validateArrayOption = (values, options, key) => {
  return values.filter((value) =>
    options.find((option) => option[key] === +value)
  );
};

const validateYearType = (yearType) => {
  const validYearTypes = ["collect_year", "collect_water_year"];
  return validYearTypes.includes(yearType);
};

const useInitializeWithQueryParams = ({
  graphOptionsQuery,
  regionalGroupsQuery,
  yearsQuery,
  currentCategory,
  setInputState,
}) => {
  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (
      isInitialMount.current &&
      graphOptionsQuery.isSuccess &&
      regionalGroupsQuery.isSuccess &&
      yearsQuery.isSuccess &&
      !currentCategory
    ) {
      const params = new URLSearchParams(location.search);
      const newInputState = { ...DEFAULT_INPUT_STATE };

      if (params.has("graphs")) {
        const graphIds = params.get("graphs")?.split(",").filter(Boolean) || [];
        newInputState.graphs = validateGraphIds(
          graphIds,
          graphOptionsQuery.data,
          REQUIRED_NUMBER_OF_GRAPH_SELECTIONS
        );
      } else {
        newInputState.graphs = graphOptionsQuery.data.filter((d) =>
          d.datacategory_ndx_defaults.includes(0)
        );
      }

      if (params.has("regionalGroup")) {
        const regGroup = parseInt(params.get("regionalGroup"));
        if (
          validateOption(regGroup, regionalGroupsQuery.data, "reg_group_ndx")
        ) {
          newInputState.regionalGroup = regGroup;
        }
      }

      if (params.has("years")) {
        const years =
          params.get("years")?.split(",").filter(Boolean).map(Number) || [];
        newInputState.years = validateArrayOption(
          years,
          yearsQuery.data,
          "explore_year"
        );
      }

      if (params.has("yearType")) {
        const yearType = params.get("yearType");
        if (validateYearType(yearType)) {
          newInputState.yearType = yearType;
        }
      }

      setInputState(newInputState);
      isInitialMount.current = false;
    }
  }, [
    graphOptionsQuery,
    regionalGroupsQuery,
    yearsQuery,
    currentCategory,
    location.search,
    setInputState,
  ]);

  return { isInitialMount };
};

const useInitializeWithDataCategory = () => {
  const { datacategoryPath } = useParams();

  const currentCategory = useMemo(
    () =>
      Object.values(STAKEHOLDERS_CONFIG).find(
        (config) => datacategoryPath === config.datacategoryPath
      ),
    [datacategoryPath]
  );

  return currentCategory;
};

const useGraphQueries = (inputState, BASE_URL) => {
  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request."
      );
    }

    if (
      inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS &&
      inputState.years.length > 0
    ) {
      cancelTokenSourceRef.current = axios.CancelToken.source();
    }
  }, [inputState.graphs, inputState.years, inputState.regionalGroup]);

  const graphQueries = useQueries(
    inputState.graphs.length === REQUIRED_NUMBER_OF_GRAPH_SELECTIONS &&
      inputState.years.length > 0
      ? inputState.graphs.map((graph) => ({
          queryKey: [
            graph.yoy_graph_route_name,
            inputState.regionalGroup,
            inputState.years,
          ],
          queryFn: async () => {
            try {
              const response = await axios.get(
                `${BASE_URL}/data/${graph.yoy_graph_route_name}`,
                {
                  params: {
                    group: inputState.regionalGroup,
                    years: inputState.years.join(","),
                  },
                  cancelToken: cancelTokenSourceRef.current.token,
                }
              );
              return response.data;
            } catch (error) {
              if (axios.isCancel(error)) {
                console.log("Request canceled:", error.message);
                throw new Error("Query was canceled");
              } else {
                throw error;
              }
            }
          },
          onError: (error) => {
            if (error.message === "Query was canceled") {
              console.log("Canceled query will not cache data.");
            }
          },
          ...USE_QUERY_OPTIONS,
          staleTime: Infinity,
          cacheTime: Infinity,
          enabled: true,
        }))
      : []
  );

  return graphQueries;
};

const isLeapYear = (year) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

const generateDayOfYearLabels = (years, yearType) => {
  const year = years.some(isLeapYear) ? 2024 : 2023; // arbitrary leap year/non-leap year
  let startDate, endDate;

  if (yearType === "collect_year") {
    startDate = new Date(year, 0, 1);
    endDate = new Date(year, 11, 31);
  } else if (yearType === "collect_water_year") {
    startDate = new Date(year - 1, 9, 1); // October 1st of the previous year
    endDate = new Date(year, 8, 30); // September 30th of the current year
  } else {
    throw new Error(
      'Invalid yearType. Must be "collect_year" or "collect_water_year".'
    );
  }

  // Generate an array of days in the date range
  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  // Formate date (e.g., 'Jan-4')
  return days.map((day) => format(day, "MMM-d"));
};

// Determine the appropriate axis IDs based on the graph and yearType
const determineLeftAxisID = (graph, yearType) => {
  const suffixMap = {
    collect_year: "calyr",
    collect_water_year: "wtryr",
  };

  if (graph.yoy_group_ndx === 6 && suffixMap[yearType]) {
    const suffix = suffixMap[yearType];
    return [
      `data_daily_${suffix}`,
      `hist_min_${suffix}`,
      `hist_avg_${suffix}`,
      `hist_max_${suffix}`,
    ];
  }

  return ["data_daily", "hist_min", "hist_avg", "hist_max"];
};

// Categorize data into historical and daily datasets
const categorizeData = (data, yearType, leftAxisIDs) => {
  const categorized = {
    hist_min: {},
    hist_avg: {},
    hist_max: {},
    data_daily: {},
  };

  const [histMinKey, histAvgKey, histMaxKey, dataDailyKey] = [
    "hist_min",
    "hist_avg",
    "hist_max",
    "data_daily",
  ].map((prefix) => leftAxisIDs.find((id) => id.startsWith(prefix)));

  data.forEach((record) => {
    const dayOfYear =
      yearType === "collect_year"
        ? record.collect_day
        : record.collect_water_day;
    const year = record[yearType];

    if (histMinKey && record[histMinKey] !== undefined)
      categorized.hist_min[dayOfYear] = record[histMinKey];
    if (histAvgKey && record[histAvgKey] !== undefined)
      categorized.hist_avg[dayOfYear] = record[histAvgKey];
    if (histMaxKey && record[histMaxKey] !== undefined)
      categorized.hist_max[dayOfYear] = record[histMaxKey];

    if (!categorized.data_daily[year]) categorized.data_daily[year] = {};
    if (dataDailyKey && record[dataDailyKey] !== undefined)
      categorized.data_daily[year][dayOfYear] = record[dataDailyKey];
  });

  return categorized;
};

const createDataset = (
  labels,
  data,
  axisUnits,
  chartType,
  axisID,
  year = null
) => {
  // Determine the type based on whether the axisID includes "hist"
  const type = axisID.includes("hist")
    ? "line"
    : ["line", "area"].includes(chartType)
    ? "line"
    : "bar";

  // Determine the color based on the content of the axisID
  const borderColor = axisID.includes("min")
    ? "#00bFFF"
    : axisID.includes("avg")
    ? "#778899"
    : axisID.includes("max")
    ? "#FF8C00"
    : year
    ? getColorForYear(year)
    : "#000";

  // Determine the line style and point behavior for historical data
  const borderDash = axisID.includes("hist") ? [5, 5] : [];
  const pointRadius = axisID.includes("hist") ? 0 : 2;
  const pointHoverRadius = 5;
  const pointStyle = axisID.includes("hist")
    ? "circle"
    : chartType === "bar"
    ? "rectRounded"
    : chartType === "area"
    ? "rect"
    : "circle";

  return {
    label: year ? `${year} ${titleize(axisID)}` : titleize(axisID),
    type: type,
    data: labels.map((label, i) => {
      // Handle non-leap years: insert null for Feb-29, and shift data after Feb-28
      if (!isLeapYear(year) && label === "Feb-29") return null;

      // Calculate the correct index for data points after Feb-28 for non-leap years
      const adjustedIndex =
        !isLeapYear(year) && i >= labels.indexOf("Mar-1")
          ? i - 1 // Shift index for non-leap years after Feb-28
          : i;

      return data[adjustedIndex + 1] ?? null;
    }),
    borderColor: borderColor,
    backgroundColor: hexToRGBA(borderColor, 0.7),
    borderWidth: 2,
    borderDash: borderDash,
    yAxisID: "yL",
    fill: chartType === "area" && !axisID.includes("hist") ? "start" : false,
    pointRadius: pointRadius,
    pointHoverRadius: pointHoverRadius,
    borderRadius: 5,
    pointStyle: pointStyle,
  };
};

const transformGraphDataForChart = (
  data,
  graph,
  index,
  totalCharts,
  years,
  yearType
) => {
  const isLastChart = index === totalCharts - 1;
  const labels = generateDayOfYearLabels(years, yearType);
  const leftAxisIDs = determineLeftAxisID(graph, yearType);
  const categorizedData = categorizeData(
    data.filter((item) => years.includes(item[yearType])),
    yearType,
    leftAxisIDs
  );

  const datasets = [
    ...["hist_min", "hist_avg", "hist_max"]
      .map((prefix) => {
        const axisID = leftAxisIDs.find((id) => id.startsWith(prefix));
        return axisID && categorizedData[prefix]
          ? createDataset(
              labels,
              categorizedData[prefix],
              graph.y_axis_units,
              graph.data_daily_chart_type,
              prefix
            )
          : null;
      })
      .filter(Boolean),
    ...Object.keys(categorizedData.data_daily)
      .map((yearKey) => {
        const axisID = leftAxisIDs.find((id) => id.startsWith("data_daily"));
        return axisID
          ? createDataset(
              labels,
              categorizedData.data_daily[yearKey],
              graph.y_axis_units,
              graph.data_daily_chart_type,
              axisID,
              yearKey
            )
          : null;
      })
      .filter(Boolean),
  ];

  return {
    labels,
    datasets,
    chartTitle: graph.yoy_graph_title,
    yLLabel: graph.y_axis_units,
    xMin: labels[0],
    xMax: labels[labels.length - 1],
    isLastChart,
  };
};

export const useYearOverYearComparisonsTool = () => {
  const [inputState, setInputState] = useState(DEFAULT_INPUT_STATE);

  const currentCategory = useInitializeWithDataCategory();

  const handleInputState = useCallback((key, value) => {
    setInputState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const regionalGroupsQuery = useCustomQuery(
    ["regional-groups"],
    "year-over-year-comparisons-tool/inputs/regional-groups"
  );

  const yearsQuery = useCustomQuery(
    ["years"],
    "year-over-year-comparisons-tool/inputs/list-explore-years"
  );

  const graphOptionsQuery = useCustomQuery(
    ["yoy-graph-options"],
    "year-over-year-comparisons-tool/inputs/yoy-graph-options"
  );

  // Filter graphOptions based on selected data category
  useEffect(() => {
    if (graphOptionsQuery.data && currentCategory) {
      const defaultGraphOptions = graphOptionsQuery.data.filter((d) =>
        d.datacategory_ndx_defaults.includes(currentCategory.ndx)
      );
      handleInputState("graphs", defaultGraphOptions);
    }
  }, [graphOptionsQuery.data, currentCategory, handleInputState]);

  // Filter graphOptions based on selected regionalGroup
  const filteredGraphOptions = useMemo(() => {
    if (!graphOptionsQuery.data) return [];
    return graphOptionsQuery.data.filter((option) =>
      option.reg_group_ndx_display.includes(inputState.regionalGroup)
    );
  }, [graphOptionsQuery.data, inputState.regionalGroup]);

  // Clean inputState.graphs when inputState.regionalGroup changes
  useEffect(() => {
    const validGraphs = inputState.graphs.filter((graph) =>
      filteredGraphOptions.some(
        (option) => option.caf_group_ndx === graph.caf_group_ndx
      )
    );

    if (validGraphs.length !== inputState.graphs.length) {
      setInputState((prev) => ({ ...prev, graphs: validGraphs }));
    }
  }, [filteredGraphOptions, inputState.graphs, inputState.regionalGroup]);

  const graphQueries = useGraphQueries(inputState, BASE_URL);

  const { isInitialMount } = useInitializeWithQueryParams({
    graphOptionsQuery,
    regionalGroupsQuery,
    yearsQuery,
    currentCategory,
    setInputState,
  });

  // Update URL when inputState changes, but avoid running on the initial mount
  useEffect(() => {
    if (!isInitialMount.current) {
      const params = new URLSearchParams();

      Object.keys(inputState).forEach((key) => {
        if (key === "graphs" && inputState.graphs.length > 0) {
          const graphIds = inputState.graphs
            .map((graph) => graph.yoy_group_ndx)
            .join(",");
          params.set(key, graphIds);
        } else if (key === "years" && inputState.years.length > 0) {
          const years = inputState.years.join(",");
          params.set(key, years);
        } else if (inputState[key] !== undefined) {
          params.set(key, inputState[key]);
        }
      });

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      if (window.location.search !== `?${params.toString()}`) {
        window.history.replaceState(
          { ...window.history.state, url: newUrl },
          "",
          newUrl
        );
      }
    }
  }, [inputState, isInitialMount]);

  // Memoize chartData to process it once all queries have resolved
  const chartData = useMemo(() => {
    if (graphQueries.some((query) => query.isLoading || query.isError)) {
      return [];
    }

    const data = graphQueries.map((query, index) => {
      const graph = inputState.graphs[index];
      if (!query.data) {
        return { labels: [], datasets: [] };
      }
      return transformGraphDataForChart(
        query.data,
        graph,
        index,
        graphQueries.length,
        inputState.years,
        inputState.yearType
      );
    });

    return data;
  }, [graphQueries, inputState.graphs, inputState.years, inputState.yearType]);

  return {
    inputState,
    handleInputState,
    chartData,
    inputs: {
      regionalGroups: regionalGroupsQuery,
      years: yearsQuery,
      graphOptions: { ...graphOptionsQuery, data: filteredGraphOptions },
    },
  };
};

export default useYearOverYearComparisonsTool;
