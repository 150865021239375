import { useCallback, useEffect, useState } from "react";
import {
  BASE_URL,
  DEFAULT_END_DATE,
  DEFAULT_QUERY_OPTIONS,
  DEFAULT_START_DATE,
  DEFAULT_TIME_INTERVAL,
  ENDPOINT,
} from "./constants";
import axios from "axios";
import { useQuery } from "react-query";
import { lighten } from "@material-ui/core";

const useFetch = ({ endpoint, queryKey = null, params = "", options = {} }) => {
  const queryFn = async () => {
    const { data } = await axios.get(`${BASE_URL}/${endpoint}/${params}`);
    return data;
  };

  const queryArray = queryKey === null ? [endpoint] : [queryKey];
  return useQuery(queryArray, queryFn, {
    onError: (error) => console.error(error),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
};

const prepareGraphData = (data) => {
  if (!data?.length) return null;

  const dataArrays = {
    labels: [],
    measuredFlow: [],
    coWaterTrust: [],
    cwcb: [],
    triState: [],
    crwcd: [],
    yampaParticipants: [],
    naturalFlow: [],
  };

  data.forEach((item) => {
    dataArrays.labels.push(new Date(item.collect_date));
    dataArrays.measuredFlow.push(item.measured_flow);
    dataArrays.coWaterTrust.push(item.r_co_water_trust);
    dataArrays.cwcb.push(item.r_cwcb);
    dataArrays.triState.push(item.r_tri_state);
    dataArrays.crwcd.push(item.r_drwcd);
    dataArrays.yampaParticipants.push(item.r_yampa_participants);
    dataArrays.naturalFlow.push(item.r_natural_flow);
  });

  return {
    labels: dataArrays.labels,
    datasets: [
      {
        label: "Measured Flow",
        type: "line",
        yAxisID: "yL",
        backgroundColor: lighten("#000000", 0.5),
        borderColor: "#000000",
        data: dataArrays.measuredFlow,
        pointBorderWidth: 2,
        pointBackgroundColor: "#0F9ED5",
        pointBorderColor: "#000000",
        pointRadius: 4,
        pointHoverRadius: 6,
        borderWidth: 5,
        spanGaps: false,
        stack: false,
        legendAndTooltipOrder: 7,
      },
      {
        label: "Natural Flow",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#E0EAF6",
        borderColor: "#E0EAF6",
        data: dataArrays.naturalFlow,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 0,
      },
      {
        label: "Yampa Participants Releases",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#8A441E",
        borderColor: "#8A441E",
        data: dataArrays.yampaParticipants,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 1,
      },
      {
        label: "CRWCD Releases",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#E3AC89",
        borderColor: "#E3AC89",
        data: dataArrays.crwcd,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 2,
      },
      {
        label: "Tri State Releases",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#A2D67E",
        borderColor: "#A2D67E",
        data: dataArrays.triState,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 3,
      },
      {
        label: "CWCB Releases",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#90368F",
        borderColor: "#90368F",
        data: dataArrays.cwcb,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 4,
      },
      {
        label: "CO Water Trust Releases",
        type: "line",
        yAxisID: "yL",
        backgroundColor: "#559DD0",
        borderColor: "#0F9ED5",
        data: dataArrays.coWaterTrust,
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        spanGaps: false,
        stack: "test",
        pointStyle: "rect",
        legendAndTooltipOrder: 6,
      },
    ],
  };
};

export const useColorsOfWaterElkhead = () => {
  const [timeInterval, setTimeInterval] = useState(DEFAULT_TIME_INTERVAL);

  const handleTimeInterval = useCallback(({ target: { value } }) => {
    setTimeInterval(value);
  }, []);

  const [dateRange, setDateRange] = useState({
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });

  const handleStartDate = useCallback((date) => {
    setDateRange((prev) => ({ ...prev, startDate: date }));
  }, []);

  const handleEndDate = useCallback((date) => {
    setDateRange((prev) => ({ ...prev, endDate: date }));
  }, []);

  const {
    data,
    error,
    isFetching: isLoading,
    refetch,
  } = useFetch({
    endpoint: `${ENDPOINT}/graph-${timeInterval}`,
    queryKey: ["line", dateRange.startDate, dateRange.endDate],
    params: `${dateRange.startDate.toISOString()}/${dateRange.endDate.toISOString()}`,
    options: {
      onError: (error) => console.error(error),
    },
  });

  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    setGraphData(prepareGraphData(data));
  }, [data]);

  return {
    timeInterval,
    setTimeInterval,
    handleTimeInterval,
    dateRange,
    setDateRange,
    handleStartDate,
    handleEndDate,
    rawData: data,
    data: graphData,
    error,
    isLoading,
    refetch,
  };
};
