import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  memo,
} from "react";
import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { Box, lighten, Tooltip, Typography } from "@material-ui/core";
import { SCHEME_10_CATEGORY_COLORS } from "../../../utils";
import debounce from "lodash.debounce";
import styled from "styled-components/macro";

const Root = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  justify-content: space-between;
  padding: 0 10px;
`;

const ChartTitle = styled(Typography)`
  color: #666;
  font-size: 24px;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.2;
  padding: 10px 0;
  text-align: center;
`;

const LegendItem = styled(Box)`
  cursor: pointer;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0.5)};
`;

const ChartContainer = styled(Box)`
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getTickValues = (groups, maxTicks) => {
  if (groups.length <= maxTicks) return groups;
  const step = Math.ceil(groups.length / maxTicks);
  return groups.filter((_, index) => index % step === 0);
};

const averageTickLabelWidth = 50;

const BoxPlot = ({ data, error, ui: { legendItems, groupType, graph } }) => {
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
      }
    }, 100);

    const observer = new ResizeObserver(handleResize);
    if (chartContainerRef.current) {
      observer.observe(chartContainerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const initialVisibility = useMemo(
    () =>
      legendItems.reduce((acc, item) => {
        acc[item.label] = true;
        return acc;
      }, {}),
    [legendItems]
  );

  const [visibleSeries, setVisibleSeries] = useState(initialVisibility);

  useEffect(() => {
    setVisibleSeries(initialVisibility);
  }, [initialVisibility]);

  const handleLegendClick = useCallback((label) => {
    setVisibleSeries((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  }, []);

  const filteredData = useMemo(
    () => (data ? data.filter((d) => visibleSeries[d.subGroup]) : []),
    [data, visibleSeries]
  );

  const colorMap = useMemo(
    () =>
      legendItems.reduce((acc, { label }, index) => {
        acc[label] = SCHEME_10_CATEGORY_COLORS[index % 10];
        return acc;
      }, {}),
    [legendItems]
  );

  if (error) return "An error has occurred: " + error.message;

  if (!data) return <div>Loading...</div>;

  const maxTicks = Math.floor((chartWidth - 135) / averageTickLabelWidth);
  const uniqueGroups = [...new Set(filteredData.map((d) => d.group))];

  return (
    <Root>
      <div>
        <ChartTitle>{graph.tssum_graph_title}</ChartTitle>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="start"
          flexWrap="wrap"
        >
          {legendItems?.map(({ label, tooltip }, index) => {
            const isVisible = visibleSeries[label];
            const color = colorMap[label];

            return (
              <Tooltip title={tooltip} key={index}>
                <LegendItem
                  display="flex"
                  alignItems="center"
                  mr={4}
                  mb={2}
                  $isVisible={isVisible}
                  onClick={() => handleLegendClick(label)}
                >
                  <Box
                    height="12px"
                    width="24px"
                    border={`2px solid ${color}`}
                    borderColor={color}
                    bgcolor={lighten(color, 0.5)}
                    mr={1}
                  />
                  <Typography variant="body2">{label}</Typography>
                </LegendItem>
              </Tooltip>
            );
          })}
        </Box>
      </div>
      <ChartContainer ref={chartContainerRef}>
        {!data?.length ? (
          <Typography>No data available</Typography>
        ) : (
          <ResponsiveBoxPlot
            data={filteredData}
            margin={{ top: 40, right: 20, bottom: 60, left: 65 }}
            subGroupBy="subGroup"
            padding={0.12}
            enableGridX={true}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendOffset: 36,
              tickValues: getTickValues(uniqueGroups, maxTicks),
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: groupType,
              legendPosition: "middle",
              legendOffset: 32,
              tickValues: getTickValues(uniqueGroups, maxTicks),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: data?.[0]?.parameter || "Result Value",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            colors={(bar) => {
              return bar?.subGroup ? colorMap[bar.subGroup] : "#ccc";
            }}
            borderRadius={2}
            borderWidth={2}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.8]],
            }}
            medianWidth={2}
            medianColor={{
              from: "color",
              modifiers: [["darker", 0.8]],
            }}
            whiskerEndSize={0.6}
            whiskerColor={{
              from: "color",
              modifiers: [["darker", 0.8]],
            }}
            motionConfig="slow"
            quantiles={[0, 0.2, 0.5, 0.8, 1]}
          />
        )}
      </ChartContainer>
    </Root>
  );
};

export default memo(BoxPlot);
