import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  Hidden,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { STAKEHOLDERS_CONFIG } from "../../../constants";
import { ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";

const Root = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: #f4f1eb;
`;

export const SectionContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 50vh;
  margin: 96px 0;
  padding: 32px;
`;

const SectionHeaderBox = styled(Box)`
  display: flex;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: block;
  }
`;

const SectionTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 6px;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    text-align: center;
  }
`;

export const HorizontalLine = styled("div")`
  height: 3px;
  flex-grow: 1;
  background-color: #8ed3f1;
  margin-left: 32px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin: 24px auto 32px 25%;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    margin-left: auto;
  }
`;

const StakeholderContainer = styled(Box)`
  position: relative;
  padding: 30px 200px 30px 0;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: end;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: 30px 0;
    width: 100%;
    height: 100%;
  }
`;

const StakeholderImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
`;

const StyledCard = styled(Card)`
  position: absolute;
  width: 270px;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 100%;
    position: relative;
    right: 0;
    height: 100%;
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 32px;
  border-bottom: 1px solid lightGray;
`;

const CardIcon = styled(Box)`
  width: 64px;
  height: 64px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CardTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 4px;
`;

const CardDescription = styled(Typography)`
  color: black;
  font-weight: 600;
`;

const Stakeholder = ({ field }) => {
  return (
    <StakeholderContainer>
      <Hidden xsDown>
        <StakeholderImage
          src={STAKEHOLDERS_CONFIG[field].image}
          alt={STAKEHOLDERS_CONFIG[field].landingTitle}
        />
      </Hidden>

      <StyledCard>
        <CardActionArea component={Link} to={STAKEHOLDERS_CONFIG[field].path}>
          <StyledCardContent>
            <CardIcon>
              <img
                src={STAKEHOLDERS_CONFIG[field].svgIcon}
                alt={`${STAKEHOLDERS_CONFIG[field].splashTitle} icon`}
              />
            </CardIcon>
            <CardTitle
              color="secondary"
              gutterBottom
              variant="h5"
              component="h2"
            >
              {STAKEHOLDERS_CONFIG[field].splashTitle}
            </CardTitle>
            <CardDescription variant="body2" component="p">
              {STAKEHOLDERS_CONFIG[field].description}
            </CardDescription>
          </StyledCardContent>
        </CardActionArea>
        <CardActions style={{ margin: "16px" }}>
          <Button
            endIcon={<ChevronRight />}
            variant="contained"
            size="medium"
            color="secondary"
            component={Link}
            to={STAKEHOLDERS_CONFIG[field].path}
          >
            Explore
          </Button>
        </CardActions>
      </StyledCard>
    </StakeholderContainer>
  );
};

const Explore = () => {
  return (
    <Root>
      <SectionContainer>
        <SectionHeaderBox alignItems="center" width="100%">
          <SectionTitle color="primary" variant="h4" width={350}>
            Explore by usage
          </SectionTitle>
          <HorizontalLine />
        </SectionHeaderBox>
        <Grid container>
          {Object.keys(STAKEHOLDERS_CONFIG).map((key) => (
            <Grid item xs={12} md={6} key={key}>
              <Stakeholder field={key} />
            </Grid>
          ))}
        </Grid>
      </SectionContainer>
    </Root>
  );
};

export default Explore;
