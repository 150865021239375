import React from "react";

import { Helmet } from "react-helmet-async";
import { useStagecoachOperations } from "./useStagecoachOperations";
import { Grid } from "@material-ui/core";
import PercentFull from "./PercentFull";
import TemperatureMaximums from "./TemperatureMaximums";
import WidgetCard from "./WidgetCard";
import TableCard from "./TableCard";
import DissolvedOxygenMinimums from "./DissolvedOxygenMinimums";
import {
  Divider,
  StyledList,
  StyledListItem,
  Typography,
} from "./styledComponents";

function StagecoachOperations() {
  const { data } = useStagecoachOperations();
  const waterElevation = data?.waterElevation?.data?.[0] ?? null;
  const temp = data?.temp?.data?.[0] ?? null;
  const dissolvedOxygen = data?.dissolvedOxygen?.data?.[0] ?? null;
  const stats = data?.stats?.data ?? [];

  return (
    <React.Fragment>
      <Helmet title="Stagecoach Reservoir Dashboard" />
      <Typography variant="h3" gutterBottom display="inline">
        Stagecoach Reservoir Dashboard
      </Typography>
      <Divider my={6} />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={4}>
          <WidgetCard
            title="Reservoir % Full"
            height="400px"
            lastReport={waterElevation?.last_report}
            isLoading={data?.waterElevation?.isLoading}
          >
            <PercentFull waterElevation={waterElevation} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <WidgetCard
            title="Outlet Temperature Maximums"
            height="400px"
            lastReport={temp?.last_report ?? null}
            subtitle={data?.temp?.subtitle ?? null}
            isLoading={data?.temp?.isLoading}
          >
            <TemperatureMaximums temp={temp} showWarning />
          </WidgetCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <WidgetCard
            title="Dissolved Oxygen Minimums"
            height="400px"
            lastReport={dissolvedOxygen?.last_report ?? null}
            isLoading={data?.dissolvedOxygen?.isLoading}
          >
            <DissolvedOxygenMinimums dissolvedOxygen={dissolvedOxygen} />
          </WidgetCard>
        </Grid>

        <Grid item xs={12}>
          <WidgetCard title="Notes" height="100%">
            <StyledList>
              <StyledListItem>
                <Typography variant="body1">
                  24-hour statistics are calculated from 5am to 5am
                </Typography>
              </StyledListItem>
            </StyledList>
          </WidgetCard>
        </Grid>

        {stats?.length > 0 &&
          stats.map((stat, i) => {
            return (
              <Grid item xs={12} lg={6} key={i}>
                <WidgetCard
                  title={stat.title}
                  height="100%"
                  isLoading={data?.stat?.isLoading}
                >
                  <div>
                    <TableCard tableData={stat} />
                    {data.stats.tableSubtitles[stat?.title] &&
                      data.stats.tableSubtitles[stat?.title]}
                  </div>
                </WidgetCard>
              </Grid>
            );
          })}
      </Grid>
    </React.Fragment>
  );
}

export default StagecoachOperations;
