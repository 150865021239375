import React from "react";
import {
  Box,
  Container,
  Typography as MuiTypography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import WavesIcon from "@material-ui/icons/Waves";

const Typography = styled(MuiTypography)(spacing);

const HeroContainer = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-image: url(/static/img/splash/hero.jpg);
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 40vh;
  }
`;

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;

const ContentContainer = styled(Container)`
  padding: 32px 0;
  z-index: 2;
  position: relative;
`;

const CenteredIcon = styled("img")`
  width: 160px;
  margin-right: 32px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100px;
    margin-right: 16px;
  }
`;

const Title = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 6px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 5px;
  }
`;

export const HorizontalLine = styled("div")`
  width: 75%;
  height: 3px;
  background-color: white;
  margin: 48px auto 64px auto;
`;

const Headline = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 7px;
`;

// const Tagline = styled(Typography)`
//   font-size: 1.2rem;
//   ${({ theme }) => theme.breakpoints.down("sm")} {
//     font-size: 1rem;
//   }
// `;

export const StyledWavesIcon = styled(WavesIcon)`
  font-size: 4.5rem;
  color: #8ed3f1;
  margin-top: 48px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 3rem;
    margin-top: 24px;
  }
`;

const Hero = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <HeroContainer>
      <Overlay />
      <ContentContainer>
        <Box
          display={isSmallScreen ? "block" : "flex"}
          alignItems="center"
          justifyContent="center"
        >
          <CenteredIcon
            src="/static/img/splash/logos/YourYampaRiverLogo_WHITE_TRANSPARENT_BACKGROUND.png"
            alt="Yampa River Dashboard Logo"
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Title variant={isSmallScreen ? "h4" : "h3"}>
              <strong>Yampa River Dashboard</strong>
            </Title>
          </Box>
        </Box>
        <HorizontalLine />
        <Headline variant="h5">A data hub for your Yampa River</Headline>
        {/*<Tagline variant="h6">Your tagline goes here</Tagline>*/}
        <StyledWavesIcon />
      </ContentContainer>
    </HeroContainer>
  );
};

export default Hero;
