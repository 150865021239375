import React from "react";
import styled from "styled-components/macro";
import { Box, Tooltip, Typography } from "@material-ui/core";
import Thermometer from "react-thermometer-component";

const WidgetTitle = styled(Typography)`
  color: #045681;
  font-weight: 700;
  text-align: center;
`;

const StyledBox = styled(Box)`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

function TemperatureWidget({ title, value, unit, steps, showWarning }) {
  const currentDate = new Date();
  const isDateInRange =
    currentDate.getMonth() >= 6 && currentDate.getMonth() <= 8;

  const isValueInRange = value >= 53.6 && value <= 60.8;

  let backgroundColor = "transparent";
  let borderColor = "transparent";
  let tooltipText = "";

  if (showWarning) {
    if (isDateInRange && !isValueInRange) {
      backgroundColor = "rgba(255, 0, 0, 0.3)";
      borderColor = "rgb(255, 0, 0)";
      tooltipText = "Temp Warning!";
    } else {
      backgroundColor = "rgba(0, 128, 0, 0.3)";
      borderColor = "rgb(0, 128, 0)";
      tooltipText = "Temp OK";
    }
  }

  return (
    <StyledBox
      style={{
        border: `3px solid ${borderColor}`,
        backgroundColor,
        margin: "5px",
      }}
    >
      <WidgetTitle variant="h5">{title}</WidgetTitle>
      <Tooltip
        title={<Typography variant="h6">{tooltipText}</Typography>}
        placement="bottom"
        PopperProps={{ style: { marginTop: "-20px" } }}
        open={!!tooltipText}
      >
        <StyledBox>
          <Thermometer
            reverseGradient={false}
            height={300}
            steps={steps}
            size="large"
            max={70}
            value={value}
            format={unit}
          />
        </StyledBox>
      </Tooltip>
    </StyledBox>
  );
}

export default TemperatureWidget;
