export const timeseriesEndpoints = [
  "resops-ts-stagecoach15-min",
  "resops-ts-stagecoach-hourly",
  "resops-ts-stagecoach-daily",
];

export const optionsEndpoints = [
  "resops-ts-stagecoach-left-axis-options",
  "resops-ts-stagecoach-right-axis-options",
  "resops-ts-stagecoach-timestep-options",
];

export const seriesEndpoints = [
  "resops-ts-stagecoach-left-axis-series",
  "resops-ts-stagecoach-right-axis-series",
];
