import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { groupByValueArray } from "../../../utils";
import { useRef } from "react";
import { Typography } from "@material-ui/core";

export const useYamcoloOperations = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: dataWaterElevation,
    isFetching: isFetchingWaterElevation,
    error: errorWaterElevation,
    refetch: refetchWaterElevation,
  } = useQuery(
    ["dashboard-yamcolo-widgets/water-elevation"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-yamcolo-widgets/water-elevation`,
          {
            location_ndx: [52],
            parameter_ndx: [1539],
          },
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataTemp,
    isFetching: isFetchingTemp,
    error: errorTemp,
    refetch: refetchTemp,
  } = useQuery(
    ["dashboard-yamcolo-widgets/temp"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-yamcolo-widgets/temp`,
          {
            location_ndx: [52],
            parameter_ndx: [169],
          },
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataBattery,
    isFetching: isFetchingBattery,
    error: errorBattery,
    refetch: refetchBattery,
  } = useQuery(
    ["dashboard-yamcolo-widgets/battery"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-yamcolo-widgets/battery`,
          {
            location_ndx: [52, 54, 55],
            parameter_ndx: [1538],
          },
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const rowTitles = [
    { title: "Last Reported Value", field: "value_last_report" },
    { title: "5am Today", field: "value_5am_today" },
    { title: "24 Hour Avg", field: "avg_24hour" },
    { title: "24 Hour High", field: "max_24hour" },
    { title: "24 Hour Low", field: "min_24hour" },
    { title: "Last Reports", field: "last_report" },
  ];

  const {
    data: dataStats,
    isFetching: isFetchingStats,
    error: errorStats,
    refetch: refetchStats,
  } = useQuery(
    ["dashboard-yamcolo-stats"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-yamcolo-stats`,
          { headers }
        );

        const groupedData = groupByValueArray(data, "stats_table_num");

        const tableData = groupedData?.map((group) => {
          return {
            title: group[0].stats_table_name,
            headers: group.map((item) => item.location_display_label),
            rows: rowTitles.map((rowTitle) => ({
              title: rowTitle.title,
              values: group.map((item) => item[rowTitle.field]),
            })),
          };
        });

        return tableData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const graphSaveRef = useRef(null);
  const {
    data: dataGraph,
    isFetching: isFetchingGraph,
    error: errorGraph,
    refetch: refetchGraph,
  } = useQuery(
    ["dashboard-yamcolo-7-daygraph"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/dashboard-yamcolo7daygraph`,
          { headers }
        );

        const graphData = {
          labels: data.map((item) => new Date(item.collect_timestamp)),
          datasets: [
            {
              label: "Reservoir",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#4285F4",
              backgroundColor: "#4285F4",
              data: data.map((item) => item.reservoir),
              fill: false,
              spanGaps: false,
              borderWidth: 5,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
            {
              label: "River",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#0000FF",
              backgroundColor: "#0000FF",
              data: data.map((item) => item.river),
              fill: false,
              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
              borderDash: [15, 15],
            },
            {
              label: "Ditch",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#45818E",
              backgroundColor: "#45818E",
              data: data.map((item) => item.ditch),
              fill: false,
              spanGaps: false,
              borderWidth: 2,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
              borderDash: [15, 15],
            },
            {
              label: "32deg",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#999999",
              backgroundColor: "#999999",
              data: data.map((item) => item.thr1_32deg),
              fill: true,

              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
            {
              label: "Heater 2 ON",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#FF6D01",
              backgroundColor: "#FF6D01",
              data: data.map((item) => item.thr2_heater2_on),
              fill: true,

              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
            {
              label: "Heater 1 ON",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#FFFF00",
              backgroundColor: "#FFFF00",
              data: data.map((item) => item.thr3_heater1_on),
              fill: true,

              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
            {
              label: "Heater 2 OFF",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#00FF00",
              backgroundColor: "rgba(0,255,0,.35)",
              data: data.map((item) => item.thr4_heater2_off),
              fill: true,
              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
            {
              label: "Heater 1 OFF",
              units: data[0].units,
              yAxisID: "yL",
              borderColor: "#93C47D",
              backgroundColor: "rgba(147,196,125,.35)",
              data: data.map((item) => item.thr5_heater1_off),
              fill: true,
              spanGaps: false,
              borderWidth: 3,
              pointStyle: "point",
              pointRadius: 0,
              pointHoverRadius: 4,
            },
          ],
        };

        return graphData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const tableSubtitles = {
    "In and Out Flows (cfs)": (
      <Typography>
        Minimum Flow at the River Meter is 8 cfs from Oct - April and 12 cfs May
        - Sept (or Calculated Inflow if it is less)
      </Typography>
    ),
  };

  return {
    data: {
      waterElevation: {
        data: dataWaterElevation,
        isLoading: isFetchingWaterElevation,
        error: errorWaterElevation,
        refetch: refetchWaterElevation,
      },
      temp: {
        data: dataTemp,
        isLoading: isFetchingTemp,
        error: errorTemp,
        refetch: refetchTemp,
      },
      battery: {
        data: dataBattery,
        isLoading: isFetchingBattery,
        error: errorBattery,
        refetch: refetchBattery,
      },
      stats: {
        data: dataStats,
        isLoading: isFetchingStats,
        error: errorStats,
        refetch: refetchStats,
        tableSubtitles,
      },
      graph: {
        data: dataGraph,
        isLoading: isFetchingGraph,
        error: errorGraph,
        refetch: refetchGraph,
        ref: graphSaveRef,
      },
    },
  };
};
