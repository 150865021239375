import { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import { INIT_FILTER_VALUES } from "../../constants";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook responsible for controlling the state related to the
 * Map filter controls
 * Accepts one argument, `onFilterChange` that is a callback that
 * can be run whenever a filter value changes
 */
const useFilters = ({ onFilterChange, isMapLoaded }) => {
  const [filterValues, setFilterValues] = useState(INIT_FILTER_VALUES);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { data } = useQuery(
    ["filterData"],
    async () => {
      let headers = {};

      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        headers = { Authorization: `Bearer ${token}` };
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`,
          { headers }
        );
        return response;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  /**
   * Set the options and initial value for each filter based on
   * what is returned from the DB
   */
  useEffect(() => {
    if (isMapLoaded && data?.data) {
      const newFilterValues = {
        ...filterValues,
        locationTypes: {
          ...filterValues.locationTypes,
          options: data?.data?.locationTypes || [],
          value: data?.data?.locationTypes.map(({ value }) => value) || [],
        },
        organizations: {
          ...filterValues.organizations,
          options: data?.data?.organizations || [],
          value: data?.data?.organizations.map(({ value }) => value) || [],
        },
        parameterGroups: {
          ...filterValues.parameterGroups,
          options: data?.data?.parameterGroups || [],
          value: data?.data?.parameterGroups.map(({ value }) => value) || [],
        },
        graphMode: {
          ...filterValues.graphMode,
          value: data?.data?.graphMode || [""],
        },
        graphModeReference: {
          ...filterValues.graphMode,
          value: data?.data?.graphMode || [""],
        },
      };
      onFilterChange(newFilterValues);
      setFilterValues(newFilterValues);
    }
  }, [data?.data, isMapLoaded]); //eslint-disable-line

  const handleSelectAll = (name) => {
    setFilterValues((prevState) => {
      const newState = {
        ...prevState,
        [name]: {
          ...prevState[name],
          value: data?.data?.[name]?.map(({ value }) => value),
        },
      };
      onFilterChange(newState);
      return newState;
    });
  };

  const handleSelectNone = (name) => {
    setFilterValues((prevState) => {
      const newState = {
        ...prevState,
        [name]: {
          ...prevState[name],
          value: [],
        },
      };
      onFilterChange(newState);
      return newState;
    });
  };

  const handleFilterValues = (event) => {
    const { checked, name, value } = event.target;

    const type = filterValues[name].type;

    if (type === "multi-select" || type === "multi-select-array") {
      setFilterValues((prevState) => {
        const newValue = [...prevState[name].value];
        const existingIndex = newValue.indexOf(value);
        if (existingIndex > -1) {
          newValue.splice(existingIndex, 1);
        } else {
          newValue.push(value);
        }
        const newState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: newValue,
          },
        };
        onFilterChange(newState);
        return newState;
      });
    } else if (type === "boolean") {
      setFilterValues((prevState) => {
        const newState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: checked,
          },
        };
        onFilterChange(newState);
        return newState;
      });
    } else if (type === "select") {
      setFilterValues((prevState) => {
        const newState = {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: value,
          },
        };
        // onFilterChange(newState);
        return newState;
      });
    }
  };

  return {
    filterValues,
    setFilterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
  };
};

export default useFilters;
