import React, { useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";
import StorageIcon from "@material-ui/icons/Storage";
import LayersIcon from "@material-ui/icons/Layers";
import MapIcon from "@material-ui/icons/Map";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components/macro";
import LayersControl from "../layersControl";
import BasemapsControl from "../basemapsControl";
import TemperatureAndFlowsControl from "../temperatureAndFlowsControl";
import { UNIQUE_LEGEND_GROUPS } from "../../constants";
import { useParams } from "react-router-dom";

const Container = styled(Paper)`
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  left: 49px;
  position: absolute;
  top: 10px;
  max-height: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: hidden;
  width: 300px;
  z-index: 3;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-height: 392px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainControl = ({
  activeBasemap,
  basemaps,
  layers,
  onBasemapChange,
  onLayerChange,
  onOpacityChange,
  imageCache,
}) => {
  const { datacategoryPath } = useParams();
  const [expandedItem, setExpandedItem] = useState(
    datacategoryPath === "temperature-and-flow"
      ? "temperature-and-flow-map"
      : "map-layers"
  );
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);
  };

  const temperatureAndFlowItems = useMemo(
    () =>
      layers.filter(
        (layer) => "Temperature & Flow" === layer.lreProperties.legendGroup
      ),
    [layers]
  );

  const mapLayerItems = useMemo(
    () =>
      layers.filter((layer) =>
        UNIQUE_LEGEND_GROUPS.includes(layer.lreProperties.legendGroup)
      ),
    [layers]
  );

  return (
    <Container>
      <Accordion
        expanded={expandedItem === "basemaps"}
        onChange={handleChange("basemaps")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <MapIcon />
            <Typography variant="subtitle1">Basemaps</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <BasemapsControl
            items={basemaps}
            value={activeBasemap}
            onBasemapChange={onBasemapChange}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "temperature-and-flow-map"}
        onChange={handleChange("temperature-and-flow-map")}
      >
        <AccordionSummary
          aria-controls="temperature-and-flow-map-content"
          id="temperature-and-flow-map-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <StorageIcon />
            <Typography variant="subtitle1">Temperature & Flow Map</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <TemperatureAndFlowsControl
            items={temperatureAndFlowItems}
            onLayerChange={onLayerChange}
            imageCache={imageCache}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "map-layers"}
        onChange={handleChange("map-layers")}
      >
        <AccordionSummary
          aria-controls="map-layers-content"
          id="map-layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <LayersIcon />
            <Typography variant="subtitle1">Map Layers</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <LayersControl
            items={mapLayerItems}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
            imageCache={imageCache}
            uniqueLegendGroups={UNIQUE_LEGEND_GROUPS}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default MainControl;
