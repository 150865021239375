import { subDays, subMonths, subYears } from "date-fns";
import { formatStartDate, getCurrentYearPeriod } from "./utils";

export const ISO_DATE_FORMAT = "MM/dd/yyyy";
export const ISO_DATE_TIME_FORMAT = "MM/dd/yyyy, h:mm a";

//TODO create a locations view (at conception there is only one location)
export const LOCATION_OPTIONS = [
  { label: "Stagecoach Res Temperature Profiles", value: 657 },
];

export const PERIOD_OPTIONS = [
  { label: "Daily", value: "profile-temperature-daily" },
  { label: "Hourly", value: "profile-temperature-hourly" },
];

export const STATISTIC_OPTIONS = [
  { label: "Mean", value: "avg_value" },
  { label: "Min", value: "min_value" },
  { label: "Max", value: "max_value" },
];

export const QUICKSET_OPTIONS = [
  {
    label: "Last 10 Days",
    calculateStartDate: () => formatStartDate(subDays, 10),
    disableFor: [],
  },
  {
    label: "Last 30 Days",
    calculateStartDate: () => formatStartDate(subDays, 30),
    disableFor: [],
  },
  {
    label: "Last 6 Months",
    calculateStartDate: () => formatStartDate(subMonths, 6),
    disableFor: ["profile-temperature-hourly"],
  },
  {
    label: "Last 1 Year",
    calculateStartDate: () => formatStartDate(subYears, 1),
    disableFor: ["profile-temperature-hourly"],
  },
];

const { defaultStartDate, defaultEndDate } = getCurrentYearPeriod();
export const DEFAULT_FILTERS = {
  period: "profile-temperature-daily",
  locationId: 657,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  statistic: "avg_value",
};
