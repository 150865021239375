import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
  DEFAULT_FILTERS,
  ISO_DATE_TIME_FORMAT,
  STATISTIC_OPTIONS,
} from "./constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format, subDays } from "date-fns";
import {
  getCurrentYearPeriod,
  getDaysDifference,
  getSpacedTickValues,
  transformDataForHeatMap,
} from "./utils";

export const useTemperatureProfiles = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [graphData, setGraphData] = useState([]);
  const [spacedTickValues, setSpacedTickValues] = useState([]);

  const { defaultStartDate, defaultEndDate } = useMemo(
    () => getCurrentYearPeriod(),
    []
  );

  const handleFilterChange = useCallback(
    (name, value) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [name]: value };

        if (name === "period") {
          const isDaily = value === "profile-temperature-daily";
          const isHourly = value === "profile-temperature-hourly";

          const startDate = isDaily
            ? defaultStartDate
            : isHourly
            ? format(subDays(new Date(), 10), ISO_DATE_TIME_FORMAT)
            : prevFilters.startDate;
          const endDate = isDaily
            ? defaultEndDate
            : isHourly
            ? format(new Date(), ISO_DATE_TIME_FORMAT)
            : prevFilters.endDate;

          return {
            ...newFilters,
            startDate,
            endDate,
            statistic: isHourly ? "avg_value" : prevFilters.statistic,
          };
        }

        return newFilters;
      });
    },
    [defaultStartDate, defaultEndDate]
  );

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const endpoint = `${process.env.REACT_APP_ENDPOINT}/api/temperature-profiles`;
      const formattedStartDate = format(
        new Date(filters.startDate),
        "yyyy-MM-dd"
      );
      const formattedEndDate = format(new Date(filters.endDate), "yyyy-MM-dd");
      const url = `${endpoint}/${filters.period}/${filters.locationId}/${formattedStartDate}/${formattedEndDate}`;
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(url, { headers });
      return response.data;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  const { data, error, isFetching, refetch } = useQuery(
    "temperature-profiles",
    fetchData,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  useEffect(() => {
    if (data) {
      const formattedData = transformDataForHeatMap(
        data,
        filters,
        STATISTIC_OPTIONS
      );
      setGraphData(formattedData);

      const daysDifference = getDaysDifference(
        filters.startDate,
        filters.endDate
      );
      const interval =
        filters.period === "profile-temperature-hourly"
          ? 23
          : daysDifference > 365
          ? 30
          : daysDifference > 30
          ? 7
          : 1;

      setSpacedTickValues(getSpacedTickValues(formattedData, interval));
    }
  }, [data]); //eslint-disable-line react-hooks/exhaustive-deps

  return {
    graphData,
    spacedTickValues,
    data,
    isLoading: isFetching,
    error,
    refetch,
    handleFilterChange,
    filters,
    setFilters,
  };
};
